//const GoogleMapsDeckGLOverlay = require("./google-maps-deckgl-overlay");
const GoogleMapsNativeOverlay = require("./google-maps-native-overlay");

class ProviderFactory {
	constructor(mapController, wfsClient, dataView) {
		this.mapController = mapController;
		this.wfsClient = wfsClient;
		this.dataView = dataView;
	}

	make(
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId
	) {
		switch (overlayConfiguration?.featureType) {
			/*case "deckgl":
				return new GoogleMapsDeckGLOverlay(
					this.mapController,
					reportId,
					reportConfigurationModel,
					clickDetection,
					overlayConfiguration,
					baseURL,
					layerId,
					domId,
					this.wfsClient,
					this.dataView
				);*/
			case "native":
			default:
				return new GoogleMapsNativeOverlay(
					this.mapController,
					reportId,
					reportConfigurationModel,
					clickDetection,
					overlayConfiguration,
					baseURL,
					layerId,
					domId,
					this.wfsClient,
					this.dataView
				);
		}
	}
}

module.exports = ProviderFactory;
