const utils = require("../utils/index.js");

function TransactionClient() {
	let origin = location.protocol + "//" + location.host;
	this.origin = location.origin || origin;
	this.token =
		utils.getAuthTokenFromQueryParam() ||
		utils.getCookie("token") ||
		utils.getCookie("key");

	$.ajaxSetup({
		xhrFields: { withCredentials: true },
		headers: {
			"x-auth-key": this.token,
			source: "website"
		}
	});
}

TransactionClient.prototype.getTransactionById = function (id, callback) {
	var origin = this.origin;
	var data = {
		format: "json"
	};

	$.ajax({
		type: "GET",
		url: origin + "/transaction/" + id,
		data: data,
		success: function (data) {
			callback(null, data);
		},
		error: function (err) {
			callback(err, null);
		}
	});
};

TransactionClient.prototype.updateTransaction = function (
	transactionId,
	reportData,
	callback
) {
	var origin = this.origin;

	$.ajax({
		type: "PUT",
		url: origin + "/transaction/" + transactionId,
		data: reportData,
		success: function (data) {
			callback(null, data);
		},
		error: function (err) {
			callback(err, null);
		}
	});
};

module.exports = TransactionClient;
