const ArchRefacLayerViewModel = require("./view-models/arch-refac-layer-view-model");
const CanopyHailLayerViewModel = require("./view-models/canopy-hail-layer-view-model");
const MapriskLayerViewModel = require("./view-models/maprisk-layer-view-model");

class LayerViewModelFactory {
	constructor(
		eventBus,
		mapController,
		clickDetection,
		reportConfigurationModel,
		report,
		tooltips,
		$parentContainer,
		wfsClient,
		overlayFactory,
		dataView
	) {
		this.eventBus = eventBus;
		this.mapController = mapController;
		this.clickDetection = clickDetection;
		this.reportConfigurationModel = reportConfigurationModel;
		this.report = report;
		this.reportId = this.report.reportId;
		this.tooltips = tooltips;
		this.$parentContainer = $parentContainer;
		this.wfsClient = wfsClient;
		this.overlayFactory = overlayFactory;
		this.dataView = dataView;
	}

	getLayerViewModel() {
		if (
			[
				"hailcanopystormfootprints",
				"hailcanopyverification",
				"hailstormfootprints",
				"hailverificationc"
			].includes(this.reportId.toLowerCase())
		) {
			return new CanopyHailLayerViewModel(
				this.eventBus,
				this.mapController,
				this.clickDetection,
				this.report,
				this.tooltips,
				this.$parentContainer,
				this.wfsClient,
				this.reportConfigurationModel,
				this.overlayFactory,
				this.dataView
			);
		} else if (
			[
				"Customer.ArchRefac.AggregatePortfolio",
				"Customer.ArchRefac.AggregateSummary",
				"Customer.ArchRefac.AggregateTesselationSummary"
			].includes(this.report.reportType)
		) {
			return new ArchRefacLayerViewModel(
				this.eventBus,
				this.mapController,
				this.clickDetection,
				this.report,
				this.tooltips,
				this.$parentContainer,
				this.wfsClient,
				this.reportConfigurationModel,
				this.overlayFactory,
				this.dataView
			);
		} else {
			return new MapriskLayerViewModel(
				this.eventBus,
				this.mapController,
				this.clickDetection,
				this.report,
				this.tooltips,
				this.$parentContainer,
				this.wfsClient,
				this.reportConfigurationModel,
				this.overlayFactory,
				this.dataView
			);
		}
	}
}

module.exports = LayerViewModelFactory;
