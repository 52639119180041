const ViewBase = require("../view-base");
const LayerOptionView = require("../controls/layer-option-view");
const LoadingOverlay = require("../../components/loading-overlay");
const utils = require("../../utils/");
const eventTracker = utils.eventTracker;

class LayerMultiselectView extends ViewBase {
	constructor(vm, reportId, tileServerPath, tooltips, eventBus) {
		super();

		this.vm = vm;
		this.reportId = reportId;
		this.layerOptions = [];
		this.tileServerPath = tileServerPath;
		this.tooltips = tooltips;
		this.eventBus = eventBus;

		this.unsubscribeLayerAdded = this.attachLayerAddedListener();
		this.unsubscribeLayerRemoved = this.attachLayerRemovedListener();
		this.unsubscribeLayerRemoved = this.attachLayerRemovedAllListener();
	}

	bind($el) {
		let self = this;
		let layers = this.getLayers();

		this.$parentContainer = $el ?? this.$parentContainer;

		this.$multiselectContainer = $(
			`<div class="report-layer-multiselect-container" data-report-id='${this.reportId}'></div>`
		);
		this.$multiselect = $('<div class="report-layer-multiselect"></div>');
		this.$multiselectPlaceholder = $(
			'<span class="report-layer-multiselect-placeholder">View Overlays</span>'
		);
		this.$multiselectIconWrapper = $(
			'<span class="report-layer-multiselect-icon-wrapper"></span>'
		);
		this.$multiselectIcon = $('<i class="icon-down-dir"></i>');
		this.$dropdown = $('<div class="report-layer-multiselect-dropdown"></div>');

		this.attachListener(this.$multiselect, "click", null, null, function (e) {
			e.preventDefault();
			self.toggleDropdown();
		});

		this.$multiselectIconWrapper.append(this.$multiselectIcon);
		this.$multiselect.append(this.$multiselectIconWrapper);
		this.$multiselect.append(this.$multiselectPlaceholder);
		this.$multiselectContainer.append(this.$multiselect).append(this.$dropdown);

		this.$parentContainer.append(this.$multiselectContainer);
		this.addLayerOptions(layers, "image");

		this.loadingOverlay = new LoadingOverlay({
			type: "spinner",
			parentContainer: `.report-layer-multiselect-container[data-report-id="${this.reportId}"]`,
			id: `report-layer-multiselect-loading-overlay-${this.reportId}`
		});
	}

	getLayers() {
		return this.vm.getLayers();
	}

	addLayerOptions(layers, type) {
		let self = this;
		type = type ?? "image";

		layers.forEach((layer) => {
			self.addLayerOption(layer, type);
		});
	}

	addLayerOption(layer, type) {
		let self = this;
		type = type ?? "image";

		let layerOptionView = new LayerOptionView(
			layer,
			type,
			this.tileServerPath,
			this.tooltips
		);

		layerOptionView.subscribe("layer:activated", (activated) => {
			self.publish("layer:activated", {
				layer,
				activated
			});

			if (activated === true) {
				let label = {
					_id: window.id,
					layer_name: layer.name,
					request_type: utils.getAuthenticationType()
				};

				eventTracker("user_action", "visual_layer", label);
			}
		});

		layerOptionView.subscribe("opacity:changed", (opacity) => {
			self.publish("opacity:changed", {
				layer,
				opacity
			});
		});

		self.layerOptions.push(layerOptionView);

		layerOptionView.bind(self.$dropdown);
	}

	removeAllLayerOptions() {
		let self = this;
		self.layerOptions.forEach((option) => {
			option.unbind();
		});
		delete self.layerOptions;
		this.layerOptions = [];
	}

	removeLayerOption(layer) {
		let self = this;
		let index = this.layerOptions.findIndex(
			(el) => el.layer.domId === layer.domId
		);

		if (index >= 0) {
			this.layerOptions[index].unbind();
			this.layerOptions.splice(index, 1);
		}

		if (this.layerOptions.length <= 0) {
			delete self.layerOptions;
			this.layerOptions = [];
		}
	}

	emptyDropdown() {
		this.$dropdown.empty();
	}

	unbind() {
		this.detachMultiselectClickListener();
		this.toggleDropdown();
		this.$multiselectContainer.remove();
	}

	attachMultiselectClickListener() {
		let self = this;
		this.$multiselect.on("click", function (e) {
			e.preventDefault();
			self.toggleDropdown();
		});
	}

	detachMultiselectClickListener() {
		this.$multiselect.off("click");
	}

	attachMultiselectBlurListener() {
		let self = this;

		$(document).on(`click.layer_multiselect_blur.${this.reportId}`, function (
			e
		) {
			if (
				$(e.target).parents(".report-layer-multiselect-container").length <=
					0 ||
				$(e.target)
					.parents(".report-layer-multiselect-container")
					.attr("data-report-id") !== self.reportId
			) {
				self.toggleDropdown();
			}
		});
	}

	detachMultiselectBlurListener() {
		$(document).off(`click.layer_multiselect_blur.${this.reportId}`);
	}

	toggleDropdown() {
		let self = this;
		let active;
		let layers = this.getLayers();

		if (this.$dropdown.hasClass("active")) {
			this.$dropdown.removeClass("active");
			this.$multiselectContainer.removeClass("dropdown-active");
			this.detachMultiselectBlurListener();
			active = false;

			layers.forEach(function (layer) {
				let domId = layer.domId.split(":").join("_");
				self.tooltips.closeById(`legend-${domId}`);
			});
		} else {
			this.$dropdown.addClass("active");
			this.$multiselectContainer.addClass("dropdown-active");
			this.attachMultiselectBlurListener();
			active = true;
		}

		this.publish("dropdown:activated", active);
	}

	activateLayerOptionControls(layer, activated) {
		let option = this.layerOptions.find((el) => {
			return el.layer.domId === layer.domId;
		});

		activated ? option.enableControls() : option.disableControls();
	}

	indicateLayerActive(active) {
		active
			? this.$multiselectContainer.addClass("layer-active")
			: this.$multiselectContainer.removeClass("layer-active");
	}

	attachLayerAddedListener() {
		let self = this;

		return this.subscribe("layers:state:added", function (layer) {
			self.addLayerOption(layer);
		});
	}

	attachLayerRemovedListener() {
		let self = this;

		return this.subscribe("layers:state:removed", function (layer) {
			self.removeLayerOption(layer);
		});
	}

	attachLayerRemovedAllListener() {
		let self = this;

		return this.subscribe("layers:state:removed_all", function (layer) {
			self.removeAllLayerOptions();
		});
	}

	addLoadingOverlay() {
		this.loadingOverlay.enable();
	}

	removeLoadingOverlay() {
		this.loadingOverlay.disable();
	}
}

module.exports = LayerMultiselectView;
