module.exports = function () {
	var url = window.location.search.substring(1);
	var urlVariables = url.split("&");
	var param;
	var i;

	for (i = 0; i < urlVariables.length; i++) {
		param = urlVariables[i].split("=");

		if (param[0] === "key" || param[0] === "token") {
			return param[1];
		}
	}

	return null;
};
