module.exports = function (coord, scale, tileSize) {
	var shift = Math.floor(tileSize / 2);

	function calculate(coord, tileSize, shift) {
		return (coord * tileSize + shift) / scale;
	}

	var x = calculate(coord.x, tileSize, shift);
	var y = calculate(coord.y, tileSize, shift);
	return new google.maps.Point(x, y);
};
