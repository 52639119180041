const utils = require("../utils/index.js");
const dialog = require("../dialog/index");
const CONSTANTS = require("../../../lib/index");
const eventTracker = utils.eventTracker;

var mrMapStreetViewControl = function () {
	var self = this;

	self.options = {
		containerBaseClass: "mr-map-controls-container",
		containerClassNames: "",
		containerDataType: "street-view",
		containerTitle: "View street view panorama",
		innerContainerBaseClass: "mr-map-controls-container-inner",
		innerContainerClassNames: "",
		triggerBaseClass: "mr-map-controls-trigger",
		triggerClassNames: "",
		triggerContentBaseClass: "mr-map-controls-trigger-content",
		triggerContentType: "text",
		triggerContentHTML: "Street View"
	};

	self.stateModel = {
		active: false,
		visible: true,
		enabled: false
	};

	self.state = JSON.parse(JSON.stringify(self.stateModel));

	self.$mapControlContainer = $("<div></div>");
	self.$mapControlInnerContainer = $("<div></div>");
	self.$mapControlTrigger = $("<div></div>");
	self.$mapControlTriggerContent = $("<span></span>");

	self.init = function (mapController, options) {
		if (!mapController) {
			console.log("mapStreetViewControl error; no mapController specified");

			return;
		}

		if (options) {
			self.options = utils.extend.deepmerge(self.options, options);
		}

		if (!self.options.parentContainer) {
			console.log(
				"mapStreetViewControl error: no parent container defined in options."
			);

			return;
		}

		if (!self.options.id) {
			console.log("mapStreetViewControl error: no id specified.");

			return;
		}

		if ($("#" + self.options.id).length <= 0) {
			self.mapController = mapController;
			self.create();
		} else {
			console.log(
				"mapStreetViewControl error: Cannot create duplicate mapStreetViewControl container."
			);

			return;
		}
	};

	self.create = function () {
		self.$mapControlContainer.attr("id", self.options.id);
		self.$mapControlContainer.addClass(
			self.options.containerBaseClass + " " + self.options.containerClassNames
		);
		self.$mapControlContainer.attr("data-type", self.options.containerDataType);
		self.$mapControlContainer.attr("title", self.options.containerTitle);

		if (self.options.enabled === false) {
			self.$mapControlContainer.addClass("disabled");
			self.state.enabled = false;
		} else {
			self.state.enabled = true;
		}

		self.$mapControlInnerContainer.addClass(
			self.options.innerContainerBaseClass +
				" " +
				self.options.innerContainerClassNames
		);

		self.$mapControlTrigger.addClass(
			self.options.triggerBaseClass + " " + self.options.triggerClassNames
		);

		self.$mapControlTriggerContent.addClass(
			self.options.triggerContentBaseClass
		);
		self.$mapControlTriggerContent.html(self.options.triggerContentHTML);

		self.$mapControlTrigger.append(self.$mapControlTriggerContent);

		self.$mapControlInnerContainer.append(self.$mapControlTrigger);
		self.$mapControlContainer.append(self.$mapControlInnerContainer);

		$(self.options.parentContainer).append(self.$mapControlContainer);

		self.attachTriggerClick();
	};

	self.destroy = function () {
		self.$mapControlContainer.remove();
	};

	self.enable = function () {
		self.state.enabled = true;
		self.$mapControlContainer.removeClass("disabled");
	};

	self.disable = function () {
		self.state.enabled = false;
		self.$mapControlContainer.addClass("disabled");
	};

	self.attachTriggerClick = function () {
		self.$mapControlTrigger.on("click", function (e) {
			e.preventDefault();

			if (self.state.enabled === true) {
				self.activateStreetViewAtPoi();
			}
		});
	};

	self.activateStreetViewAtPoi = function () {
		var point = new google.maps.LatLng(
			window.currentPoiInfo.poi.lat,
			window.currentPoiInfo.poi.lng
		);
		var streetViewService = new google.maps.StreetViewService();
		var panorama = self.mapController.map.getStreetView();

		//maximum streetview distance set to 100m
		streetViewService.getPanoramaByLocation(point, 100, function (
			streetViewPanoramaData,
			status
		) {
			if (status === google.maps.StreetViewStatus.OK) {
				self.enable();
				panorama.setPosition(point);
				panorama.setPov({
					heading: google.maps.geometry.spherical.computeHeading(
						streetViewPanoramaData.location.latLng,
						point
					),
					zoom: 1,
					pitch: 0
				});
				panorama.setVisible(true);

				let label = {
					_id: window.id,
					parent: window.parentID ? window.parentID : null,
					email: window.email,
					request_type: utils.getAuthenticationType()
				};

				eventTracker("user_action", "open_streetview", label);

				window.reportsMenu.close(true, true);
			} else {
				self.disable();
				dialog.openDialog(CONSTANTS.DIALOG.STREET_VIEW_ERROR);
			}
		});
	};
};

module.exports = mrMapStreetViewControl;
