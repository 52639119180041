const ProviderFactory = require("./providers");

class ServerRenderedOverlayBase {
	constructor(
		mapController,
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId
	) {
		this.mapController = mapController;
		this.reportId = reportId;
		this.reportConfigurationModel = reportConfigurationModel;
		this.clickDetection = clickDetection;
		this.overlayConfiguration = overlayConfiguration;
		this.baseURL = baseURL;
		this.layerId = layerId;
		this.domId = domId;

		this.providerFactory = new ProviderFactory(this.mapController);

		this.overlay = this.providerFactory.make(
			this.reportId,
			this.reportConfigurationModel,
			this.clickDetection,
			this.overlayConfiguration,
			this.baseURL,
			this.layerId,
			this.domId
		);
	}

	getOpacity() {
		return this.overlay.getOpacity();
	}

	setOpacity(opacity) {
		this.overlay.setOpacity(opacity);
	}

	isActive() {
		throw new Error("Not yet implemented");
	}

	activate(active) {
		this.overlay.activate(active);
	}
}

module.exports = ServerRenderedOverlayBase;
