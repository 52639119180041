const extend = {
	isMergeableObject: function (val) {
		var nonNullObject = val && typeof val === "object";

		return (
			nonNullObject &&
			Object.prototype.toString.call(val) !== "[object RegExp]" &&
			Object.prototype.toString.call(val) !== "[object Date]"
		);
	},

	emptyTarget: function (val) {
		return Array.isArray(val) ? [] : {};
	},

	cloneIfNecessary: function (value, optionsArgument) {
		var clone = optionsArgument && optionsArgument.clone === true;

		return clone && extend.isMergeableObject(value)
			? extend.deepmerge(extend.emptyTarget(value), value, optionsArgument)
			: value;
	},

	defaultArrayMerge: function (target, source, optionsArgument) {
		var destination = target.slice();

		source.forEach(function (e, i) {
			if (typeof destination[i] === "undefined") {
				destination[i] = extend.cloneIfNecessary(e, optionsArgument);
			} else if (extend.isMergeableObject(e)) {
				destination[i] = extend.deepmerge(target[i], e, optionsArgument);
			} else if (target.indexOf(e) === -1) {
				destination.push(extend.cloneIfNecessary(e, optionsArgument));
			}
		});

		return destination;
	},

	mergeObject: function (target, source, optionsArgument) {
		var destination = {};

		if (extend.isMergeableObject(target)) {
			Object.keys(target).forEach(function (key) {
				destination[key] = extend.cloneIfNecessary(
					target[key],
					optionsArgument
				);
			});
		}

		Object.keys(source).forEach(function (key) {
			if (!extend.isMergeableObject(source[key]) || !target[key]) {
				destination[key] = extend.cloneIfNecessary(
					source[key],
					optionsArgument
				);
			} else {
				destination[key] = extend.deepmerge(
					target[key],
					source[key],
					optionsArgument
				);
			}
		});

		return destination;
	},

	deepmerge: function (target, source, optionsArgument) {
		var array = Array.isArray(source);
		var options = optionsArgument || { arrayMerge: extend.defaultArrayMerge };
		var arrayMerge = options.arrayMerge || extend.defaultArrayMerge;

		if (array) {
			return Array.isArray(target)
				? arrayMerge(target, source, optionsArgument)
				: extend.cloneIfNecessary(source, optionsArgument);
		} else {
			return extend.mergeObject(target, source, optionsArgument);
		}
	},

	deepmergeAll: function (array, optionsArgument) {
		if (!Array.isArray(array) || array.length < 2) {
			throw new Error(
				"first argument should be an array with at least two elements"
			);
		}

		// we are sure there are at least 2 values, so it is safe to have no initial value
		return array.reduce(function (prev, next) {
			return extend.deepmerge(prev, next, optionsArgument);
		});
	}
};

module.exports = extend;
