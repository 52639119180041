const AccountToolsInterface = require("../index.js");

class ConfirmDeleteInterface extends AccountToolsInterface {
	constructor(accountClient, eventBus, token, label) {
		super(accountClient, eventBus);
		this.accountClient = accountClient;
		this.eventBus = eventBus;
		this.token = token;
		this.label = label;

		this.activeClass = "active";

		this.headingClassList = ["api-key-manager-confirm-delete-heading"];
		this.headingText = `Confirm Deletion of API Key ${label}`;

		this.containerId = "api-key-manager-confirm-delete-container";
		this.containerClassList = ["api-key-manager-confirm-delete-container"];

		this.closeButtonContainerClassList = [
			"api-key-manager-confirm-delete-close-button-container"
		];

		this.closeButtonClassList = ["api-key-manager-confirm-delete-close-button"];
		this.closeButtonIconClassList = ["icon-cancel"];

		this.bodyContainerClassList = [
			"api-key-manager-confirm-delete-body-container"
		];

		this.bodyHTML = `<p>Are you sure you want to delete the API Key <span class="key">${token.substring(
			0,
			4
		)} ... ${token.substring(
			token.length - 4,
			token.length
		)}?</p><p>This key will immediately stop working for all further Maprisk API calls.</p>`;

		this.cancelButtonClassList = [
			"api-key-manager-confirm-delete-cancel-button"
		];

		this.confirmButtonClassList = [
			"api-key-manager-confirm-delete-confirm-button"
		];

		this.backdropId = "api-key-manager-confirm-delete-backdrop";
		this.backdropClassList = ["api-key-manager-confirm-delete-backdrop"];

		this.footerClassList = ["api-key-manager-confirm-delete-footer"];

		this.messageClassList = ["api-key-manager-confirm-delete-message"];
		this.messageText = "";

		this.deleteIconClassList = ["icon-trash"];

		this.createInterface();
	}

	createInterface() {
		const self = this;

		if (this.isInterfaceCreated === true) {
			return;
		}

		this.backdrop = document.createElement("div");
		this.backdrop.id = this.backdropId;
		this.backdropClassList.forEach((className) => {
			this.backdrop.classList.add(className);
		});

		this.container = document.createElement("div");
		this.container.id = this.containerId;
		this.containerClassList.forEach((className) => {
			this.container.classList.add(className);
		});

		this.heading = document.createElement("h3");
		this.headingClassList.forEach((className) => {
			this.heading.classList.add(className);
		});
		this.heading.innerText = this.headingText;

		this.bodyContainer = document.createElement("div");
		this.bodyContainerClassList.forEach((className) => {
			this.bodyContainer.classList.add(className);
		});

		this.bodyContainer.innerHTML = this.bodyHTML;

		this.closeButtonContainer = document.createElement("div");
		this.closeButtonContainerClassList.forEach((className) => {
			this.closeButtonContainer.classList.add(className);
		});

		this.closeButton = document.createElement("a");
		this.closeButtonClassList.forEach((className) => {
			this.closeButton.classList.add(className);
		});

		this.closeButtonIcon = document.createElement("i");
		this.closeButtonIconClassList.forEach((className) => {
			this.closeButtonIcon.classList.add(className);
		});

		this.closeButton.append(this.closeButtonIcon);
		this.closeButtonContainer.append(this.closeButton);

		this.closeButton.append(this.closeButtonIcon);
		this.closeButtonContainer.append(this.closeButton);

		this.footer = document.createElement("div");
		this.footerClassList.forEach((className) => {
			this.footer.classList.add(className);
		});

		this.cancelButton = document.createElement("button");
		this.cancelButtonClassList.forEach((className) => {
			this.cancelButton.classList.add(className);
		});
		this.cancelButton.innerText = "Cancel";

		this.footerButtonWrapper = document.createElement("div");
		this.footerButtonWrapper.classList.add(
			"api-key-manager-confirm-delete-footer-button-wrapper"
		);
		this.footerButtonLeftColumnWrapper = document.createElement("div");

		this.footerButtonLeftColumnWrapper.append(this.cancelButton);

		this.footerButtonRightColumnWrapper = document.createElement("div");

		this.confirmButton = document.createElement("button");
		this.confirmButtonClassList.forEach((className) => {
			this.confirmButton.classList.add(className);
		});
		this.confirmButton.innerText = "Delete API Key";

		this.footerButtonRightColumnWrapper.append(this.confirmButton);

		this.message = document.createElement("div");
		this.message.classList.add(this.messageClassList);

		this.message.innerText = this.messageText;

		this.container.append(this.heading);
		this.container.append(this.closeButtonContainer);
		this.container.append(this.bodyContainer);
		this.container.append(this.message);

		this.container.append(this.footer);
		this.footer.append(this.footerButtonWrapper);
		this.footerButtonWrapper.append(this.footerButtonLeftColumnWrapper);
		this.footerButtonWrapper.append(this.footerButtonRightColumnWrapper);

		document.body.append(this.backdrop);
		document.body.append(this.container);

		this.attachCloseButtonClickHandler();
		this.attachBackdropClickHandler();
		this.attachConfirmButtonClickHandler();
		this.attachCancelButtonClickHandler();
	}

	attachCloseButtonClickHandler() {
		const self = this;

		this.closeButton.addEventListener("click", (e) => {
			e.preventDefault();

			self.hideMessage();
			self.closeInterface();
		});
	}

	attachCancelButtonClickHandler() {
		const self = this;

		this.cancelButton.addEventListener("click", (e) => {
			e.preventDefault();

			self.hideMessage();
			self.closeInterface();
		});
	}

	closeInterface() {
		this.backdrop.remove();
		this.container.remove();
	}

	async attachConfirmButtonClickHandler() {
		const self = this;

		this.confirmButton.addEventListener("click", async (e) => {
			e.preventDefault();

			this.hideMessage();

			let success = (res) => {
				this.eventBus.publish("api-key:revoked", res.data);
				this.closeInterface();
			};

			let error = (err) => {
				self.showMessage(err.error, "error");
			};

			let data = {
				keyToRevoke: this.token
			};

			await self.accountClient.revokeApiKey(data, error, success);
		});
	}
}

module.exports = ConfirmDeleteInterface;
