const OverlayFactory = require("../../../../overlays");

class ArchRefacLayerModel {
	constructor(
		mapController,
		reportConfigurationModel,
		clickDetection,
		report,
		layerConfiguration,
		domId,
		wfsClient,
		overlayFactory,
		dataView
	) {
		this.mapController = mapController;
		this.reportConfigurationModel = reportConfigurationModel;
		this.clickDetection = clickDetection;
		this.report = report;

		this.configuration = layerConfiguration;
		this.configuration.namespace = this.configuration.namespace ?? "MapRisk";
		this.domId =
			domId ??
			report.reportId +
				"_" +
				layerConfiguration.namespace +
				":" +
				layerConfiguration.name;
		this.wfsClient = wfsClient;
		this.overlayFactory = overlayFactory;
		this.dataView = dataView;

		this.namespace = this.configuration.namespace;
		this.name = this.configuration.name;

		this.source = "config";

		this.reportId = report.reportId;
		this.layerId = this.namespace + ":" + this.name;

		this.autoEnabled = this.configuration.autoEnabled ?? false;

		this.active = false;
		this.opacity = 0.75;

		this.overlay = this.overlayFactory.make(
			this.reportId,
			this.reportConfigurationModel,
			this.clickDetection,
			this.configuration,
			this.layerId,
			this.domId,
			this.dataView
		);
	}

	activateLayer() {
		let self = this;

		this.overlay.activate(true);
		this.active = true;
	}

	deactivateLayer() {
		let self = this;

		this.overlay.activate(false);

		if (window?.mrState?.dataLayers?.[this.reportId]) {
			for (let layerName in window.mrState.dataLayers[this.reportId]) {
				window.mrState.dataLayers[this.reportId][layerName].deactivate();
			}
		}

		window.mainDataView.hideView();

		this.active = false;
	}

	setActive(active) {
		if (!active) {
			this.deactivateLayer();
		} else {
			this.activateLayer();
		}
	}

	getOpacity(opacity) {
		return this.overlay.getOpacity();
	}

	setOpacity(position) {
		let opacity = position / 100;

		this.overlay.setOpacity(opacity);

		this.opacity = opacity;
	}

	isActive() {
		return this.active;
	}
}

module.exports = ArchRefacLayerModel;
