const moment = require("moment")

/**
 * Represents input submitted by the user for a given report
 * along with an optional set of validation rules to ensure the input is valid.
 */
class ModelInput {
	/**
	 * Creates an instance of ModelInput
	 * @param {Object} fields User's input for a given report
	 * @param {ValidationRules} validationRules Rules defined on a report's `modelInputValidation` parameter
	 */
	constructor(fields, validationRules, shouldValidate) {
		fields = fields ?? {};

		for (let key in fields) {
			this[key] = fields[key];
		}

		Object.defineProperty(this, "_validationRules", {
			value: validationRules
		});

		Object.defineProperty(this, "_shouldValidate", {
			value: () => {
				return shouldValidate ?? true;
			}
		});
	}

	/**
	 * Retrieves an input field by way of case insensitive string comparison
	 * @param {String} searchKey A case insensitive key
	 * @return {any} The input value
	 */
	getField(searchKey) {
		if (!searchKey) {
			return undefined;
		}

		let val = Object.keys(this).reduce((val, key) => {
			if (val) {
				return val;
			}

			if (key?.toLowerCase() === searchKey?.toLowerCase()) {
				return this?.[key];
			}
		}, undefined);

		return val;
	}

	/**
	 * Validates user input against a set of validation rules
	 * @return {ValidationResult} Validation information
	 */
	validate() {
		let validationModel = {};

		let validationResult = {
			pass: true,
			errors: null
		};

		if (!this._shouldValidate()) {
			return validationResult;
		}

		for (let key in this._validationRules) {
			let rule = this._validationRules[key];
			let field = this.getField(key);

			validationModel[key] = this.processRule(rule, field);
		}

		for (let key in this) {
			if (
				!this.getCaseInsensitiveFromObj(key, validationModel) &&
				!this.getCaseInsensitiveFromObj(key, this._validationRules)
			) {
				validationModel[key] = this.processRule({}, this[key]);
			}
		}

		for (let key in validationModel) {
			if (!validationModel[key].pass) {
				validationResult.pass = false;
				validationResult.errors = validationResult.errors ?? {};
				validationResult.errors[key] = {
					required: validationModel[key].required,
					message: validationModel[key].message
				};
			} else if (validationModel[key].pass && this?._validationRules?.[key]?._isValidDate) {
				this[key] = moment (this[key].toString());
			}
		}

		return validationResult;
	}

	processRule(rule, field) {
		let processedRule = {
			pass: true,
			message: "",
			required: false,
			value: field
		};

		if (!this.isNullOrEmpty(field) && rule._regex) {
			let regex = new RegExp(rule._regex, rule._flags ?? "");

			processedRule.pass = regex.test(field);
		}

		if (rule._required) {
			if (processedRule.pass) {
				processedRule.pass = !this.isNullOrEmpty(field);
			}

			processedRule.required = true;
		}

		if (rule._isValidDate) {
			let dateField = moment(field.toString());
			processedRule.pass = processedRule.pass && dateField.isValid();
		}

		processedRule.message = rule._message;

		return processedRule;
	}

	getCaseInsensitiveFromObj(searchKey, obj) {
		if (!obj) {
			obj = this;
		}

		if (!searchKey) {
			return undefined;
		}

		let val = Object.keys(obj).reduce((val, key) => {
			if (val) {
				return val;
			}

			if (key?.toLowerCase() === searchKey?.toLowerCase()) {
				return obj?.[key];
			}
		}, undefined);

		return val;
	}

	isNullOrEmpty(field) {
		return (
			field === null ||
			field === undefined ||
			field === "" ||
			(typeof field === "string" && field.trim() === "")
		);
	}
}

module.exports = ModelInput;
