const getReportParameterByName = require("./get-report-parameter-by-name");
const moment = require("moment");

function buildGeoserverParams(
	reportId,
	layerNamespace,
	layerName,
	reportConfigurationModel,
	mapController
) {
	let response = {
		wms: "",
		wfs: ""
	};

	if (!reportId) {
		return response;
	}

	if (reportConfigurationModel[reportId.toLowerCase()]) {
		let reportConfig = reportConfigurationModel[reportId.toLowerCase()];
		let requestParams = reportConfig.parameters;
		let layers = reportConfig.layers;
		let poiLatitude;
		let poiLongitude;

		let radiusUnits;
		let returnFeaturesInRadius = false;
		let filters = {};
		let radiiBands = [];
		let poiString = "";
		let filterString = "";
		let WMSRadiiString = "";
		let WFSRadiiString = "";
		let aggregateString = "";
		let cqlFilterString = "";

		for (let i = requestParams.length; i--; ) {
			if (
				requestParams[i].key === "returnFeaturesInRadius" &&
				requestParams[i].value == true
			) {
				returnFeaturesInRadius = true;
			}

			if (requestParams[i].key === "filter") {
				for (let filter in requestParams[i].value) {
					filters[filter] = requestParams[i].value[filter];
				}
			}

			if (requestParams[i].key === "units") {
				radiusUnits = requestParams[i].value;
			}

			if (requestParams[i].key === "wfsFilter") {
				cqlFilterString = requestParams[i].value;
			}

			if (requestParams[i].key === "radii") {
				if (requestParams[i].value.bands) {
					requestParams[i].value.bands.forEach(function (band) {
						if (band.max || band.min) {
							radiiBands.push(band);
						}
					});
				}
			}

			if (requestParams[i].key === "filter") {
				window.mainDataView.state.currentReports.forEach(function (report) {
					if (report.reportId && report.reportId === reportId) {
						let filters = window?.mrState?.mrMultiselect?.[reportId + "_index"]
							?.keys
							? window.mrState.mrMultiselect[reportId + "_index"].keys
							: {};
						let exclusions = {};
						let clauseMap = {};
						let clause = "";

						if (filters) {
							let filterKeys = Object.keys(filters);
							let filterMap = getReportParameterByName(
								reportConfigurationModel,
								reportId,
								"geoserverFilterMap"
							);
							let queryMap = getReportParameterByName(
								reportConfigurationModel,
								reportId,
								"geoserverQueryMap"
							);

							filterKeys.forEach(function (filter, index) {
								let values = Object.keys(filters[filter]);

								if (values.length > 0) {
									clauseMap[filter] = {};

									values.forEach(function (value) {
										if (
											queryMap &&
											queryMap[filter] &&
											queryMap[filter][value] &&
											queryMap[filter][value].expression
										) {
											if (!clauseMap[filter].expressions) {
												clauseMap[filter].expressions = [];
											}

											let expression = queryMap[filter][value].expression;
											let excludes = queryMap[filter][value].excludes;

											if (!exclusions[filter]) {
												exclusions[filter] = {};
											}

											if (!exclusions[filter][value]) {
												exclusions[filter][value] = [];
											}

											if (excludes && excludes.length > 0) {
												exclusions[filter][value] = exclusions[filter][
													value
												].concat(excludes);
											}

											if (queryMap[filter][value].fieldMap) {
												for (let field in queryMap[filter][value].fieldMap) {
													let output = eval(
														queryMap[filter][value].fieldMap[field]
													);
													expression = expression
														.split("{{" + field + "}}")
														.join(output);
													clauseMap[filter].expressions.push(expression);
												}
											}

											return;
										}

										if (
											filterMap &&
											filterMap[filter] &&
											filterMap[filter][value.toLowerCase()]
										) {
											if (!clauseMap[filter].values) {
												clauseMap[filter].values = [];
											}

											clauseMap[filter].values.push(
												filterMap[filter][value.toLowerCase()]
											);

											return;
										}

										if (!clauseMap[filter].values) {
											clauseMap[filter].values = [];
										}

										clauseMap[filter].values.push(value.toLowerCase());
									});
								}
							});
						}

						if (exclusions) {
							for (let filter in exclusions) {
								for (let values in exclusions[filter]) {
									exclusions[filter][values].forEach(function (value) {
										if (
											clauseMap &&
											clauseMap[filter] &&
											clauseMap[filter].values &&
											clauseMap[filter].values.indexOf(value) !== -1
										) {
											clauseMap[filter].values.splice(
												clauseMap[filter].values.indexOf(value),
												1
											);
										}
									});
								}
							}
						}

						if (Object.keys(clauseMap).length > 0) {
							Object.keys(clauseMap).forEach(function (key, index) {
								clause += "(";
								if (clauseMap[key].expressions) {
									clause += "(";
									clause += clauseMap[key].expressions.join(") OR (");
									clause += ")";

									if (
										clauseMap[key].values &&
										clauseMap[key].values.length > 0
									) {
										clause += " OR ";
									}
								}

								if (clauseMap[key].values) {
									clause += "(";
									clause += key;
									clause += " ILIKE ";
									clause += "'";
									clause += clauseMap[key].values.join(
										"') OR (" + key + " ILIKE " + "'"
									);
									clause += "'";
									clause += ")";
								}
								clause += ")";

								if (index < Object.keys(clauseMap).length - 1) {
									clause += " AND ";
								}
							});
						}

						clause = " AND " + clause;

						clause = clause.split(" ").join("%20");
						clause = clause.split("(").join("%28");
						clause = clause.split(")").join("%29");
						clause = clause.split("'").join("%27");
						clause = clause.split(":").join("%3A");
						clause = clause.split(",").join("%5C%2C");

						filterString = "filters:" + clause;
					}
				});
			}
		}

		if (mapController.currentPoi) {
			poiLatitude = mapController.currentPoi.lat;
			poiLongitude = mapController.currentPoi.lng;
			poiString = "lng:" + poiLongitude + ";lat:" + poiLatitude + ";";
		}

		if (radiiBands.length > 0) {
			var WMSRadii = [];
			var WFSRadii = [];
			var radii = [];
			WMSRadiiString = "radii:";
			WFSRadiiString = "radii:";

			radiiBands.forEach(function (el) {
				var radiusVal;
				var radius;

				if (el.max && !isNaN(el.max)) {
					radiusVal = el.max;
				} else if (!isNaN(el)) {
					radiusVal = el;
				}

				if (!isNaN(radiusVal)) {
					radius = mapController.convertUnits(radiusVal, radiusUnits, "meters");

					radii.push(radius);
				}
			});

			WMSRadii = radii.join("%5C,");
			WFSRadii = radii.join("\\,");

			WMSRadiiString += WMSRadii + ";";
			WFSRadiiString += WFSRadii + ";";
		}

		if (poiString || WMSRadiiString || aggregateString || filterString) {
			response.wms =
				"&viewParams=" +
				poiString +
				WMSRadiiString +
				aggregateString +
				filterString;
		}

		if (cqlFilterString) response.cqlFilter = cqlFilterString;

		if (poiString || WFSRadiiString || aggregateString || filterString) {
			response.wfs =
				poiString + encodeURI(WFSRadiiString) + aggregateString + filterString;
		}
	}

	return response;
}

module.exports = buildGeoserverParams;
