const LayerMultiselectView = require("../../../../../views/controls/layer-multiselect-view.js");

class LayerViewModelBase {
	constructor(
		eventBus,
		mapController,
		clickDetection,
		report,
		tooltips,
		$parentContainer,
		wfsClient,
		reportConfigurationModel,
		overlayFactory,
		dataView
	) {
		let self = this;

		this.eventBus = eventBus;
		this.mapController = mapController;
		this.clickDetection = clickDetection;
		this.report = report;
		this.reportId = report.reportId;
		this.layers = [];
		this.tooltips = tooltips;
		this.$parentContainer = $parentContainer;
		this.wfsClient = wfsClient;
		this.reportConfigurationModel = reportConfigurationModel;
		this.overlayFactory = overlayFactory;
		this.dataView = dataView;
	}

	bind($el) {
		let self = this;

		self.$parentContainer = $el ?? self.$parentContainer;

		self.layerMultiselectView = new LayerMultiselectView(
			self,
			self.reportId,
			this.mapController.TILE_SERVER_DEFAULT_PATH,
			this.tooltips,
			this.eventBus
		);

		self.unsubscribeLayerActivated = self.layerMultiselectView.subscribe(
			"layer:activated",
			({ layer, activated }) => {
				self.setActive(layer, activated);
			}
		);

		self.unsubscribeLayerOpacity = self.layerMultiselectView.subscribe(
			"opacity:changed",
			({ layer, opacity }) => {
				self.setOpacity(layer, opacity);
			}
		);

		// listen for internal dropdown toggle event
		self.unsubscribeDropdownActivated = self.layerMultiselectView.subscribe(
			"dropdown:activated",
			(activated) => {
				self.eventBus.publish(`layers:dropdown:activated`, {
					reportId: self.reportId,
					activated: activated
				});
			}
		);

		self.layerMultiselectView.bind(self.$parentContainer);
	}

	unbind() {
		this.layers.forEach((layer) => {
			this.setActive(layer, false);
		});
		this.removeLayers();
		this?.layerMultiselectView?.unbind();
	}

	getLayers() {
		return this.layers;
	}

	setLayers(layers) {
		this.layers = layers;
	}

	addLayers(layers) {
		let self = this;
		if (!Array.isArray(layers)) {
			layers = [layers];
		}

		layers.forEach((layer) => {
			self.addLayer(layer);
		});
	}

	addLayer(layer) {
		this.layers.push(layer);
		this.layerMultiselectView.publish("layers:state:added", layer);
	}

	removeLayer(layer) {
		let index = this.layers.findIndex((el) => el.domId === layer.domId);
		this.layers.splice(index, 1);
		this.layerMultiselectView.publish("layers:state:removed", layer);
	}

	removeLayers() {
		delete this.layers;
		this.layers = [];
		this.layerMultiselectView.publish("layers:state:removed_all");
	}

	checkIfLayerActive() {
		let activeLayer = false;

		this.layers.forEach((layer) => {
			if (layer.active === true) {
				activeLayer = true;
			}
		});

		this.layerMultiselectView.indicateLayerActive(activeLayer);
	}

	setActive(layer, activated) {
		layer.setActive(activated);
		this.layerMultiselectView.activateLayerOptionControls(layer, activated);
		this.checkIfLayerActive();
	}

	setOpacity(layer, position) {
		layer.setOpacity(position);
	}

	getOpacity(layer) {
		return this.layerMultiselectView.layers[this.reportId][layer].getOpacity();
	}

	refreshLayers() {
		let self = this;

		this.layers.forEach((layer) => {
			if (layer.isActive()) {
				self.setActive(layer, false);
				self.setActive(layer, true);
			}
		});
	}
}

module.exports = LayerViewModelBase;
