const { getReportParameterByName } = require("../../../../utils");

class ClientRenderedProviderBase {
	constructor(
		mapController,
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId,
		wfsClient,
		dataView
	) {
		this.mapController = mapController;
		this.reportId = reportId;
		this.reportConfigurationModel = reportConfigurationModel;
		this.clickDetection = clickDetection;
		this.overlayConfiguration = overlayConfiguration;
		this.baseURL = baseURL;
		this.layerId = layerId;
		this.domId = domId;
		this.wfsClient = wfsClient;
		this.dataView = dataView;

		this.enableClickAtZoomLevel =
			getReportParameterByName(
				this.reportConfigurationModel,
				this.reportId,
				"enableClickAtZoomLevel"
			) || 12;
		this.clickDataSource = overlayConfiguration.clickDataSource || "wfs";
		this.$mapEl = $(mapController.getMapEl());
		this.featureType = overlayConfiguration?.featureType ?? "wfs";
		this.maxZoom = 2;
		this.minZoom = 28;

		this.opacity = overlayConfiguration.opacityLevel ?? 0.75;
		this.active;
		this.features = [];
		this.interactiveFeatures = [];
		this.interactiveFeatureEvents = [];
	}
}

module.exports = ClientRenderedProviderBase;
