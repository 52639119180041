const LayerViewModelBase = require("./layer-view-model-base.js");
const MapriskLayerModel = require("../../model/models/maprisk-layer-model.js");

class MapriskLayerViewModel extends LayerViewModelBase {
	constructor(
		eventBus,
		mapController,
		clickDetection,
		report,
		tooltips,
		$parentContainer,
		wfsClient,
		reportConfigurationModel,
		overlayFactory,
		dataView
	) {
		super(
			eventBus,
			mapController,
			clickDetection,
			report,
			tooltips,
			$parentContainer,
			wfsClient,
			reportConfigurationModel,
			overlayFactory,
			dataView
		);

		let self = this;

		this.bind($parentContainer);

		this.report.layers.forEach((layer) => {
			if (!layer.type || (layer.type && layer?.type !== "features")) {
				self.addLayers(
					new MapriskLayerModel(
						this.mapController,
						this.reportConfigurationModel,
						this.clickDetection,
						this.report,
						layer,
						null,
						this.wfsClient,
						this.overlayFactory,
						this.dataView
					)
				);
			}
		});

		this.unsubscribeMapPOIChange = this.attachMapPOIChangeListener();

		if (this.mapController.currentPoi) {
			this.fetchFeatures();
		}
	}

	bind($el) {
		super.bind($el);
	}

	unbind() {
		super.unbind();
	}

	addLayers(layer) {
		super.addLayers(layer);
	}

	removeLayer(layer) {
		super.removeLayer(layer);
	}

	async fetchFeatures() {
		let self = this;

		if (self.layerMultiselectView) {
			self.layerMultiselectView.addLoadingOverlay();
		}

		for (let layer in this.report.layers) {
			if (
				this.report.layers[layer].type &&
				this.report.layers[layer].type === "features"
			) {
				let response = await self.wfsClient.getFeatures(
					self.mapController,
					self.reportConfigurationModel,
					self.reportId,
					this.report.layers[layer]
				);

				if (response?.features) {
					response.features.forEach(function (feature) {
						self.addFeatureLayer(
							feature.properties.id,
							self.report.layers[layer].namespace,
							feature.properties.layer_name,
							feature.properties.layer_title
						);
					});
				}
			}
		}

		if (self.layerMultiselectView) {
			self.layerMultiselectView.removeLoadingOverlay();
		}
	}

	addFeatureLayer(id, namespace, name, displayName) {
		let self = this;
		let source = "feature";

		let layerConfig = {
			source,
			namespace,
			name,
			displayName
		};

		let layer = new MapriskLayerModel(
			self.mapController,
			self.reportConfigurationModel,
			self.clickDetection,
			self.report,
			layerConfig,
			`${self.reportId}_${namespace}:${name}_${id}`,
			self.wfsClient,
			self.overlayFactory,
			self.dataView
		);

		self.addLayers(layer);
	}

	removeFeatureLayers() {
		for (let i = this.layers.length - 1; i >= 0; i--) {
			if (this.layers[i]?.configuration?.source === "feature") {
				this.removeLayer(this.layers[i]);
			}
		}
	}

	attachMapPOIChangeListener() {
		let self = this;

		return this.eventBus.subscribe("map:poi:changed", () => {
			self.removeFeatureLayers();
			self.fetchFeatures();
		});
	}
}

module.exports = MapriskLayerViewModel;
