class AddUserInterface {
	constructor(accountClient, eventBus, company) {
		this.accountClient = accountClient;
		this.eventBus = eventBus;
		this.company = company;

		this.activeClass = "active";

		this.headingClassList = ["user-manager-add-user-interface-heading"];
		this.headingText = "Add User";

		this.containerId = "user-manager-add-user-interface-container";
		this.containerClassList = ["user-manager-add-user-interface-container"];

		this.closeButtonContainerClassList = [
			"user-manager-add-user-interface-close-button-container"
		];

		this.closeButtonClassList = [
			"user-manager-add-user-interface-close-button"
		];

		this.closeButtonIconClassList = ["icon-cancel"];

		this.backdropId = "user-manager-add-user-interface-backdrop";
		this.backdropClassList = ["user-manager-add-user-interface-backdrop"];

		this.formId = "user-manager-add-user-interface-form";
		this.formClassList = ["user-manager-add-user-interface-form"];
		this.formMethod = "POST";
		this.formAction = "";

		this.addUserButtonContainerClassList = [
			"user-manager-add-user-interface-add-user-button-container"
		];

		this.addUserButtonId = "user-manager-add-user-interface-add-user-button";
		this.addUserButtonClassList = [
			"user-manager-add-user-interface-add-user-button",
			"button-insurity"
		];
		this.addUserButtonText = "Add User";

		this.messageClassList = ["user-manager-add-user-interface-message"];
		this.messageText = "";

		this.createInterface();
	}

	isInterfaceCreated() {
		return document.getElementById(this.containerId) ? true : false;
	}

	createInterface() {
		if (this.isInterfaceCreated === true) {
			return;
		}

		this.backdrop = document.createElement("div");
		this.backdrop.id = this.backdropId;
		this.backdropClassList.forEach((className) => {
			this.backdrop.classList.add(className);
		});

		this.container = document.createElement("div");
		this.container.id = this.containerId;
		this.containerClassList.forEach((className) => {
			this.container.classList.add(className);
		});

		this.heading = document.createElement("h3");
		this.headingClassList.forEach((className) => {
			this.heading.classList.add(className);
		});
		this.heading.innerText = this.headingText;

		this.closeButtonContainer = document.createElement("div");
		this.closeButtonContainerClassList.forEach((className) => {
			this.closeButtonContainer.classList.add(className);
		});

		this.closeButton = document.createElement("a");
		this.closeButtonClassList.forEach((className) => {
			this.closeButton.classList.add(className);
		});

		this.closeButtonIcon = document.createElement("i");
		this.closeButtonIconClassList.forEach((className) => {
			this.closeButtonIcon.classList.add(className);
		});

		this.closeButton.append(this.closeButtonIcon);
		this.closeButtonContainer.append(this.closeButton);

		this.form = document.createElement("form");
		this.form.setAttribute("method", "POST");
		this.form.setAttribute("action", "");
		this.form.id = this.formId;
		this.formClassList.forEach((className) => {
			this.form.classList.add(className);
		});

		let firstNameLabel = document.createElement("label");
		let firstNameInput = document.createElement("input");

		firstNameLabel.innerText = "First Name";

		firstNameInput.setAttribute("name", "firstName");
		firstNameInput.setAttribute("placeholder", "First Name");

		let lastNameLabel = document.createElement("label");
		let lastNameInput = document.createElement("input");

		lastNameLabel.innerText = "Last Name";

		lastNameInput.setAttribute("name", "lastName");
		lastNameInput.setAttribute("placeholder", "Last Name");

		let emailLabel = document.createElement("label");
		let emailInput = document.createElement("input");

		emailLabel.innerText = "Email";

		emailInput.setAttribute("name", "email");
		emailInput.setAttribute("placeholder", "Email Address");

		this.form.append(firstNameLabel);
		this.form.append(firstNameInput);

		this.form.append(lastNameLabel);
		this.form.append(lastNameInput);

		this.form.append(emailLabel);
		this.form.append(emailInput);

		this.addUserButtonContainer = document.createElement("div");
		this.addUserButtonContainerClassList.forEach((className) => {
			this.addUserButtonContainer.classList.add(className);
		});

		this.addUserButton = document.createElement("button");
		this.addUserButton.id = this.addUserButtonId;
		this.addUserButton.setAttribute("type", "button");
		this.addUserButtonClassList.forEach((className) => {
			this.addUserButton.classList.add(className);
		});
		this.addUserButton.innerText = this.addUserButtonText;

		this.addUserButtonContainer.append(this.addUserButton);
		this.form.append(this.addUserButtonContainer);

		this.message = document.createElement("div");
		this.messageClassList.forEach((className) => {
			this.message.classList.add(className);
		});
		this.message.innerText = this.messageText;

		this.container.append(this.heading);
		this.container.append(this.closeButtonContainer);
		this.container.append(this.form);
		this.container.append(this.message);

		document.body.append(this.backdrop);
		document.body.append(this.container);

		this.attachCloseButtonClickHandler();
		this.attachBackdropClickHandler();
		this.attachFormSubmitHandler();
	}

	attachBackdropClickHandler() {
		let self = this;

		this.backdrop.addEventListener("click", (e) => {
			e.preventDefault();

			this.hideMessage();
			this.closeInterface();
		});
	}

	attachCloseButtonClickHandler() {
		let self = this;

		this.closeButton.addEventListener("click", (e) => {
			e.preventDefault();

			this.hideMessage();
			this.closeInterface();
		});
	}

	attachFormSubmitHandler() {
		let self = this;

		this.addUserButton.addEventListener("click", async (e) => {
			e.preventDefault();
			self.hideMessage();

			let payload = {
				firstName: self.form.querySelector("[name='firstName']").value,
				lastName: self.form.querySelector("[name='lastName']").value,
				email: self.form.querySelector("[name='email']").value,
				company: this.company
			};

			let success = (res) => {
				self.showMessage(res.data, "success");
				this.eventBus.publish("account:changed");
			};

			let error = (err) => {
				self.showMessage(err.error, "error");
			};

			await self.accountClient.createSibling(payload, error, success);
		});
	}

	showMessage(message, type) {
		this.message.classList.add(type);
		this.message.innerHTML = message;
		this.message.classList.add("active");
	}

	hideMessage() {
		this.message.classList.remove("error");
		this.message.classList.remove("success");
		this.message.innerHTML = "";
		this.message.classList.remove("active");
	}

	isOpen() {
		return this.container.classList.contains(this.activeClass);
	}

	openBackdrop() {
		this.backdrop.classList.add(this.activeClass);
	}

	closeBackdrop() {
		this.backdrop.classList.remove(this.activeClass);
	}

	openInterface() {
		this.container.classList.add(this.activeClass);
		this.form.querySelector("[name='firstName']").focus();
		this.openBackdrop();
	}

	closeInterface() {
		this.container.classList.remove(this.activeClass);
		this.closeBackdrop();
	}
}

module.exports = AddUserInterface;
