const ServerRenderedProviderBase = require("./server-rendered-provider-base");
const {
	buildDerivedLayerParams,
	buildGeoserverParams,
	MercatorProjection
} = require("../../../../utils");
const crypto = require("crypto");


class GoogleMapsZxyTileImageMapTypeOverlay extends ServerRenderedProviderBase {
	constructor(
		mapController,
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId
	) {
		super(
			mapController,
			reportId,
			reportConfigurationModel,
			clickDetection,
			overlayConfiguration,
			baseURL,
			layerId,
			domId
		);

		this.overlay = this.getOverlay();
		this.authToken = null;
		this.authTokenExpiry = null;
	}

	//TODO: This should be generated on the backend and returned
	calcOrGetSkAuthToken() {
		let now = parseInt(Date.now() / 1000);

		if (this.authTokenExpiry && now < this.authTokenExpiry) return this.authToken;

		const SEPERATOR = "|";
		const key = "ef1SAOwlEJaf9XZB";

		let plainText = this.reportConfigurationModel.reportId + SEPERATOR + this.overlayConfiguration.name + SEPERATOR + "f" + SEPERATOR + parseInt(Date.now() / 1000);

		const cipher = crypto.createCipheriv("aes-128-ecb", key, "");
		let nonUrlSafeToken = Buffer.concat([cipher.update(plainText), cipher.final()]).toString("base64");

		//TODO: change encoding above to base64urlsafe and remove these lines when on newer version of node
		let finalToken = nonUrlSafeToken
			.replaceAll("=", "")
			.replaceAll("+", "-")
			.replaceAll("/", "_");

		this.authToken = finalToken;
		this.authTokenExpiry = now + 60 * 60 * 12;

		return this.authToken;
	}

	getOverlay() {
		let self = this;

		let overlayOptions = {
			getTileUrl: function(coord, zoom) {
				var url = self.baseURL
					.replace("{z}", zoom)
					.replace("{Z}", zoom)
					.replace("{x}", coord.x)
					.replace("{X}", coord.x)
					.replace("{y}", coord.y)
					.replace("{Y}", coord.y)
					//TODO: support more generic ranges
					.replace("[a-d]", String.fromCharCode("a".charCodeAt(0) + parseInt(Math.random() * 4)));

				//Remove the question mark that's inserted automatically in OverlayFactory
				if (url.charAt(url.length - 1) === "?")
					url = url.substring(0, url.length - 1);

				//Only insert SK auth token for SK mapserver layers (SK MVT layers use different auth)
				if (url.indexOf(".spatialkey.com") !== -1 && url.indexOf("mapserver") !== -1)
					url += "/" + self.calcOrGetSkAuthToken() + "?";

				return url;
			},
			tileSize: new google.maps.Size(256, 256),
			minZoom: self.minZoom ?? 1,
			maxZoom: self.maxZoom ?? 19,
			opacity: self.opacity,
			name: self.domId
		};

		return new google.maps.ImageMapType(overlayOptions);
	}
}

module.exports = GoogleMapsZxyTileImageMapTypeOverlay;
