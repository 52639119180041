const ViewBase = require("../view-base.js");

class SliderView extends ViewBase {
	constructor(
		domId,
		containerBaseClass,
		containerClassNames,
		containerInnerBaseClass,
		containerInnerClassNames,
		bgBaseClass,
		bgClassNames,
		bgFillBaseClass,
		bgFillClassNames,
		handleBaseClass,
		handleClassNames,
		initialPosition
	) {
		super();

		this.$sliderContainer = null;
		this.$sliderContainerInner = null;
		this.$sliderBg = null;
		this.$sliderBgFill = null;
		this.$sliderHandle = null;

		this.domId = domId;
		this.containerBaseClass = containerBaseClass ?? "mr-slider-container";
		this.containerClassNames = containerClassNames ?? "";
		this.containerInnerBaseClass =
			containerInnerBaseClass ?? "mr-slider-container-inner";
		this.containerInnerClassNames = containerInnerClassNames ?? "";
		this.bgBaseClass = bgBaseClass ?? "mr-slider-bg";
		this.bgClassNames = bgClassNames ?? "";
		this.bgFillBaseClass = bgFillBaseClass ?? "mr-slider-bg-fill";
		this.bgFillClassNames = bgFillClassNames ?? "";
		this.handleBaseClass = handleBaseClass ?? "mr-slider-handle";
		this.handleClassNames = handleClassNames ?? "";
		this.initialPosition = initialPosition ?? 75;

		$(document).trigger("slider-position-change." + this.domId);

		if (this.$parentContainer) {
			this.bind(this.$parentContainer);
		}
	}

	bind($el) {
		this.$parentContainer = $el ?? this.$parentContainer;

		this.$sliderContainer = $("<div class='enabled'></div>");
		this.$sliderContainerInner = $("<div></div>");
		this.$sliderBg = $("<div></div>");
		this.$sliderBgFill = $("<div></div>");
		this.$sliderHandle = $("<div></div>");

		this.$sliderContainer.attr("id", this.domId);
		this.$sliderContainer.addClass(
			`${this.domId} ${this.containerBaseClass} ${this.containerClassNames}`
		);
		this.$sliderContainerInner.addClass(this.containerInnerBaseClass);
		this.$sliderBg.addClass(`${this.bgBaseClass} ${this.bgClassNames}`);
		this.$sliderBgFill.addClass(
			`${this.bgFillBaseClass} ${this.bgFillClassNames}`
		);
		this.$sliderHandle.addClass(
			`${this.handleBaseClass} ${this.handleClassNames}`
		);

		this.$sliderContainerInner
			.append(this.$sliderBg)
			.append(this.$sliderBgFill)
			.append(this.$sliderHandle);

		this.$sliderContainer.append(this.$sliderContainerInner);
		this.$parentContainer.append(this.$sliderContainer);

		this.attachEvents();
		this.setHandlePosition(this.initialPosition);
	}

	unbind() {
		this.$sliderContainer.remove();
	}

	enable() {
		this.$sliderContainer.removeClass("disabled");
	}

	disable() {
		this.$sliderContainer.addClass("disabled");
	}

	attachEvents() {
		this.attachHandleMouseEnter();
		this.attachHandleMouseLeave();
		this.attachHandleMouseDown();
	}

	attachHandleMouseEnter() {
		this.$sliderHandle.on("mouseenter." + this.domId, function (e) {
			$("html").addClass("mr-slider-grab");
		});
	}

	attachHandleMouseLeave() {
		this.$sliderHandle.on("mouseleave." + this.domId, function (e) {
			$("html").removeClass("mr-slider-grab");
		});
	}

	attachHandleMouseDown() {
		let self = this;

		this.$sliderHandle.on("mousedown." + this.domId, function (e) {
			$("html").addClass("mr-slider-grabbing");

			self.attachHandleDrag();
			self.attachHandleMouseUp();
		});
	}

	detachHandleMouseDown() {
		this.$sliderHandle.off("mousedown." + this.domId);
	}

	attachHandleDrag() {
		let self = this;

		$(document).on("mousemove." + this.domId, (e) => {
			self.moveHandle(e.pageX);
		});
	}

	detachHandleDrag() {
		$(document).off("mousemove." + this.domId);
	}

	attachHandleMouseUp() {
		let self = this;

		$(document).on("mouseup." + this.domId, function (e) {
			$("html").removeClass("mr-slider-grabbing");
			self.detachHandleDrag();
			self.detachHandleMouseUp();
		});
	}

	detachHandleMouseUp() {
		$(document).off("mouseup." + this.domId);
	}

	toAbsolutePosition(relativePosition) {
		return (this.$sliderContainer.outerWidth() / 100) * relativePosition;
	}

	toRelativePosition(absolutePosition) {
		return (absolutePosition / this.$sliderContainer.outerWidth()) * 100;
	}

	setHandlePosition(relativePosition) {
		let absolutePosition = this.toAbsolutePosition(relativePosition);

		let sliderWidth = this.$sliderContainer.outerWidth();

		if (absolutePosition < 0) {
			absolutePosition = 0;
			relativePosition = 0;
		}

		if (absolutePosition > sliderWidth) {
			absolutePosition = sliderWidth;
			relativePosition = 100;
		}

		this.$sliderHandle.css({ left: absolutePosition + "px" });
		this.$sliderBgFill.css({ width: absolutePosition + "px" });

		super.publish("position", relativePosition);
	}

	moveHandle(pageX) {
		let absolutePosition = pageX - this.$sliderContainer.offset().left;
		let relativePosition = this.toRelativePosition(absolutePosition);

		let sliderWidth = this.$sliderContainer.outerWidth();

		if (absolutePosition < 0) {
			absolutePosition = 0;
			relativePosition = 0;
		}

		if (absolutePosition > sliderWidth) {
			absolutePosition = sliderWidth;
			relativePosition = 100;
		}

		this.$sliderHandle.css({ left: absolutePosition + "px" });
		this.$sliderBgFill.css({ width: absolutePosition + "px" });

		super.publish("position", relativePosition);
	}
}

module.exports = SliderView;
