const OverlayFacade = require("./overlay-facade");
const ClientRenderedOverlayFactory = require("./client-rendered");
const ServerRenderedOverlayFactory = require("./server-rendered");

class OverlayFactory {
	constructor(mapController, wfsClient, dataView) {
		this.mapController = mapController;
		this.wfsClient = wfsClient;
		this.dataView = dataView;

		this.clientRenderedOverlayFactory = new ClientRenderedOverlayFactory(
			this.mapController,
			this.wfsClient,
			this.dataView
		);

		this.serverRenderedOverlayFactory = new ServerRenderedOverlayFactory(
			this.mapController
		);
	}

	make(
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		layerId,
		domId
	) {
		let baseURL =
			overlayConfiguration?.baseURL ??
			this.mapController?.TILE_SERVER_DEFAULT_PATH;
		baseURL = baseURL.substr(-1, 1) === "?" ? baseURL : baseURL + "?";

		let overlays = [];

		if (
			overlayConfiguration.enableClick &&
			(overlayConfiguration.name || overlayConfiguration.wfsLayerName)
		) {
			let serverRenderedOverlay = this.serverRenderedOverlayFactory.make(
				reportId,
				reportConfigurationModel,
				clickDetection,
				overlayConfiguration,
				baseURL,
				layerId,
				domId
			);
			let clientRenderedOverlay = this.clientRenderedOverlayFactory.make(
				reportId,
				reportConfigurationModel,
				clickDetection,
				overlayConfiguration,
				baseURL,
				layerId,
				domId
			);

			overlays = [serverRenderedOverlay, clientRenderedOverlay];
		} else if (
			!overlayConfiguration.enableClick &&
			overlayConfiguration.name &&
			!overlayConfiguration.wfsLayerName
		) {
			let serverRenderedOverlay = this.serverRenderedOverlayFactory.make(
				reportId,
				reportConfigurationModel,
				clickDetection,
				overlayConfiguration,
				baseURL,
				layerId,
				domId
			);

			overlays = [serverRenderedOverlay];
		} else if (
			!overlayConfiguration.enableClick &&
			overlayConfiguration.wfsLayerName &&
			!overlayConfiguration.name
		) {
			let clientRenderedOverlay = this.clientRenderedOverlayFactory.make(
				reportId,
				reportConfigurationModel,
				clickDetection,
				overlayConfiguration,
				baseURL,
				layerId,
				domId
			);

			overlays = [clientRenderedOverlay];
		}

		return new OverlayFacade(overlays, this.mapController);
	}
}

module.exports = OverlayFactory;
