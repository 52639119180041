const utils = require("../utils/index.js");

var mrMapZoomControl = function () {
	var self = this;

	self.options = {
		containerBaseClass: "mr-map-controls-container",
		containerClassNames: "",
		containerDataType: "map-zoom",
		containerDataDirection: "in",
		containerTitle: "Zoom",
		innerContainerBaseClass: "mr-map-controls-container-inner",
		innerContainerClassNames: "",
		triggerBaseClass: "mr-map-controls-trigger",
		triggerClassNames: "",
		triggerIconBaseClass: "mr-map-controls-trigger-icon",
		triggerIconClassNames: "icon-plus"
	};

	self.stateModel = {
		visible: true,
		enabled: true
	};

	self.state = JSON.parse(JSON.stringify(self.stateModel));

	self.$mapControlContainer = $("<div></div>");
	self.$mapControlInnerContainer = $("<div></div>");
	self.$mapControlTrigger = $("<div></div>");
	self.$mapControlTriggerIcon = $("<i></i>");
	self.$mapControlDropdownContainer = $("<div></div>");

	self.init = function (mapController, options) {
		if (!mapController) {
			console.log("mapZoomControl error; no mapController specified");

			return;
		}

		if (options) {
			self.options = utils.extend.deepmerge(self.options, options);
		}

		if (!self.options.parentContainer) {
			console.log(
				"mapZoomControl error: no parent container defined in options."
			);

			return;
		}

		if (!self.options.id) {
			console.log("mapZoomControl error: no id specified.");

			return;
		}

		if ($("#" + self.options.id).length <= 0) {
			self.mapController = mapController;
			self.create();
		} else {
			console.log(
				"mapZoomControl error: Cannot create duplicate mapZoomControl container."
			);

			return;
		}
	};

	self.create = function () {
		self.$mapControlContainer.attr("id", self.options.id);
		self.$mapControlContainer.addClass(
			self.options.containerBaseClass + " " + self.options.containerClassNames
		);
		self.$mapControlContainer.attr("data-type", self.options.containerDataType);
		self.$mapControlContainer.attr(
			"data-direction",
			self.options.containerDataDirection
		);
		self.$mapControlContainer.attr("title", self.options.containerTitle);

		self.$mapControlInnerContainer.addClass(
			self.options.innerContainerBaseClass +
				" " +
				self.options.innerContainerClassNames
		);

		self.$mapControlTrigger.addClass(
			self.options.triggerBaseClass + " " + self.options.triggerClassNames
		);

		self.$mapControlTriggerIcon.addClass(
			self.options.triggerIconBaseClass +
				" " +
				self.options.triggerIconClassNames
		);

		self.$mapControlTrigger.append(self.$mapControlTriggerIcon);

		self.$mapControlInnerContainer.append(self.$mapControlTrigger);
		self.$mapControlContainer.append(self.$mapControlInnerContainer);

		$(self.options.parentContainer).append(self.$mapControlContainer);

		self.attachTriggerClick();
	};

	self.destroy = function () {
		self.$mapControlContainer.remove();
	};
	self.enable = function () {
		self.state.enabled = true;
		self.$mapControlContainer.removeClass("disabled");
	};

	self.disable = function () {
		self.state.enabled = false;
		self.$mapControlContainer.addClass("disabled");
	};

	self.attachTriggerClick = function () {
		self.$mapControlTrigger.on("click", function (e) {
			e.preventDefault();

			if (self.state.enabled === true) {
				var zoomType = self.$mapControlContainer.attr("data-direction");
				var currentZoom = self.mapController.map.getZoom();

				if (zoomType === "in") {
					if (currentZoom === 22) {
						return;
					} else {
						self.mapController.map.setZoom(currentZoom + 1);
					}
				} else if (zoomType === "out") {
					if (currentZoom === 3) {
						return;
					} else {
						self.mapController.map.setZoom(currentZoom - 1);
					}
				}
			}
		});
	};
};

module.exports = mrMapZoomControl;
