class ImageryImages {
	constructor(mapController, params) {
		this.path = "/imagery/image";
		this.mapController = mapController;
		this.params = params || {};
	}

	getImage(poi, params) {
		var self = this;
		var queryParams = [];
		var queryString = "";
		var zoom = self.mapController.map.getZoom();
		var width = self.mapController.map.getDiv().offsetWidth;
		var height = self.mapController.map.getDiv().offsetHeight;

		for (var param in params) {
			queryParams.push(param + "=" + params[param]);
		}

		queryString = queryParams.join("&");

		if (queryParams.length > 0) {
			queryString = "?" + queryString;
			queryString += "&x=" + poi.lng;
			queryString += "&y=" + poi.lat;
			queryString += "&zoom=" + zoom;
			queryString += "&width=" + width;
			queryString += "&height=" + height;
		}

		var url = self.path + queryString;

		var bounds = {
			north: mapRiskController.map.getBounds().getNorthEast().lat(),
			east: mapRiskController.map.getBounds().getNorthEast().lng(),
			south: mapRiskController.map.getBounds().getSouthWest().lat(),
			west: mapRiskController.map.getBounds().getSouthWest().lng()
		};

		var overlay = new google.maps.GroundOverlay(url, bounds);

		overlay.namespace = "highres";
		overlay.name = params.provider;
		overlay.type = "image";

		return overlay;
	}
}

module.exports = ImageryImages;
