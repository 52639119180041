const ModelInput = require("./ModelInput");
const moment = require("moment");

class ModelInputFactory {
	static make(serviceType, reportConfiguration, fields, validationParam) {
		let type = validationParam?.Type ?? null;

		let validate = validationParam?.Validate ?? ["website"];
		let shouldValidate = validate.includes(serviceType);

		switch (type) {
			case "ReplacementCost":
				let defaultConfiguration = reportConfiguration?.parameters?.find(
					(param) => param.key.toLowerCase() === "defaultconfiguration"
				);
				return new ModelInput(
					fields,
					validationParam?.[type]?.[
						fields?.reportConfiguration ?? defaultConfiguration?.value
					]?.Config,
					shouldValidate
				);
			default:
				let modelInput = new ModelInput(
					fields,
					validationParam?.Config,
					shouldValidate
				);
				return this.caseInsensitiveProxy(modelInput);
		}
	}

	static makeWebsiteValidator(reportConfiguration, fields, validationParam) {
		return this.make("website", reportConfiguration, fields, validationParam);
	}

	static makeApiValidator(reportConfiguration, fields, validationParam) {
		return this.make("api", reportConfiguration, fields, validationParam);
	}

	static caseInsensitiveProxy(targetObj) {
		if (typeof Proxy === "undefined") {
			return targetObj;
		} else if (targetObj instanceof Array) {
			return targetObj.map((objInArr) => {
				return ModelInputFactory.caseInsensitiveProxy(objInArr);
			});
		} else if (
			typeof targetObj !== "object" ||
			targetObj === null ||
			targetObj === undefined
		) {
			return targetObj;
		}

		return new Proxy(targetObj, {
			get(target, prop) {
				for (let key in target) {
					if (key?.toLowerCase?.() === prop?.toLowerCase?.()) {
						if (target[key] instanceof Array) {
							return target[key].map((objInArr) => {
								return ModelInputFactory.caseInsensitiveProxy(objInArr);
							});
						} else if (target[key] instanceof moment) {
							return target[key];
						} else if (typeof target[key] === "object") {
							return ModelInputFactory.caseInsensitiveProxy(target[key]);
						} else {
							return target[key];
						}
					}
				}
				return target[prop];
			}
		});
	}
}

module.exports = ModelInputFactory;
