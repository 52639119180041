const ClientRenderedOverlayBase = require("./client-rendered-overlay-base");

class ClientRenderedOverlay extends ClientRenderedOverlayBase {
	constructor(
		mapController,
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId,
		wfsClient,
		dataView
	) {
		super(
			mapController,
			reportId,
			reportConfigurationModel,
			clickDetection,
			overlayConfiguration,
			baseURL,
			layerId,
			domId,
			wfsClient,
			dataView
		);
	}
}

module.exports = ClientRenderedOverlay;
