//Component to handle creating the progress bar for when uploading a batch file
class BatchUpload {
	prepareUploadBtn = function () {
		//Hide the contents to make it easier to see the progress bar
		$("#upload-batch-form").css({ visibility: "hidden" });

		//Disable the buttons in the batch dialog
		this.disableButtons();

		//Inform the user of the status of the upload process
		$("#upload-status-text").show();
		$("#upload-status-text").text("Uploading...");
	};

	resetUploadBtnOnSuccess = function () {
		//Add flash animation and show uploaded text to indicate it was
		//uploaded
		$("#upload-progress-bar").addClass("flash-complete");
		$("#upload-status-text").text("Uploaded!");

		//Reset progress bar width after 1 second to ensure flash effect
		//to complete smoothly
		setTimeout(() => {
			$("#upload-status-text").hide();
			$("#upload-progress-bar").removeClass("flash-complete");
			$("#upload-progress-bar").css({ visibility: "hidden" });
			$("#upload-progress-bar").css({ width: 0 });
			//Make batch form content visible
			$("#upload-batch-form").css({ visibility: "visible" });
		}, 1000);
	};
	resetUploadBtnOnError = function () {
		//Add flash animation and show uploaded text to indicate it was
		//uploaded
		$("#upload-progress-bar").addClass("flash-fail");
		$("#upload-status-text").text("Error!");

		//Reset progress bar width after 1 second to ensure flash effect
		//to complete smoothly
		setTimeout(() => {
			$("#upload-status-text").hide();
			$("#upload-progress-bar").removeClass("flash-fail");
			$("#upload-progress-bar").css({ visibility: "hidden" });
			$("#upload-progress-bar").css({ width: 0 });
			//Make batch form content visible
			$("#upload-batch-form").css({ visibility: "visible" });
		}, 1000);
	};
	enableButtons = function () {
		//Remove the disabled attribute from the form input and the batch button
		$("#upload-batch-form").find("input").prop("disabled", false);
		$("#upload-batch-button").removeAttr("disabled");

		//Remove disabled button class from back and next buttons
		$("#batch-upload .back-button").removeClass("button-disabled");
		$("#batch-upload .next-button").removeClass("button-disabled");
	};

	disableButtons = function () {
		//Disable the form input and the batch button
		$("#upload-batch-form").find("input").prop("disabled", true);
		$("#upload-batch-button").attr("disabled", "disabled");

		//Disable the back and next buttons
		$("#batch-upload .back-button").addClass("button-disabled");
		$("#batch-upload .next-button").addClass("button-disabled");
	};
	updateStatus = function (progress) {
		$("#upload-progress-bar").css({ visibility: "visible" });
		$("#upload-progress-bar").css({ width: progress + "%" });
	};
}
module.exports = BatchUpload;
