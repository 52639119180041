const ViewBase = require("../view-base.js");
const ToggleView = require("./toggle-view.js");
const SliderView = require("./slider-view.js");

class LayerOptionView extends ViewBase {
	constructor(layer, type, tileServerPath, tooltips) {
		super();

		this.layer = layer;
		this.type = type ?? "image";
		this.style = layer?.configuration?.style ?? null;
		this.tileServerPath = tileServerPath;
		this.tooltips = tooltips;

		this.toggleView = null;
		this.sliderView = null;

		if (this.$parentContainer) {
			this.bind(this.$parentContainer);
		}
	}

	bind($el) {
		let self = this;

		this.$parentContainer = $el ?? this.$parentContainer;

		this.$optionContainer = $(
			`<div class="report-layer-multiselect-dropdown-option" data-layer-namespace="${this.layer.configuration.namespace}" data-layer-name="${this.layer.configuration.name}" data-layer-type="${this.type}"></div>`
		);

		let $nameWrapper = $(
			'<div class="report-layer-multiselect-dropdown-option-name-wrapper"></div>'
		);
		let $name = $(
			`<span class="report-layer-multiselect-dropdown-option-name">${
				this.layer.configuration.displayName ?? "Layer 0"
			}</span>`
		);
		$nameWrapper.append($name);

		let $toggleWrapper = $(
			'<div class="report-layer-multiselect-dropdown-option-toggle-wrapper"></div>'
		);
		this.toggleView = new ToggleView(
			this.layer.domId,
			null,
			null,
			null,
			null,
			this.layer?.autoEnabled === true ? "on" : "off"
		);

		let $sliderWrapper = $(
			'<div class="report-layer-multiselect-dropdown-option-slider-wrapper"></div>'
		);
		this.sliderView = new SliderView(`mr-layer-slider-${this.layer.domId}`);

		let domId = this.layer.domId.split(":").join("_");
		let $legendWrapper = $(
			`<div class="report-layer-multiselect-dropdown-option-legend-wrapper" id="layer-legend-${domId}"></div>`
		);
		let $legendIcon = $(
			'<i class="icon-key reportLayerLegendImage" title="Layer Legend"></i>'
		);

		let layerLegendContent = self.generateLegendContent();
		$legendWrapper.append($legendIcon);
		$legendWrapper = self.tooltips.modifyElement(
			$legendWrapper,
			`${domId}`,
			JSON.stringify(layerLegendContent),
			"legend",
			"image",
			`.leftSidebarWrapper`,
			"right"
		);

		this.$optionContainer
			.append($nameWrapper)
			.append($toggleWrapper)
			.append($sliderWrapper)
			.append($legendWrapper);

		if (this.layer.configuration.params) {
			this.$optionContainer.attr(
				"data-view-params",
				JSON.stringify(this.layer.configuration.params)
			);
		}

		this.$parentContainer.append(this.$optionContainer);

		this.toggleView.subscribe("checked", (activated) => {
			self.publish("layer:activated", activated);
		});

		this.sliderView.subscribe("position", (position) => {
			self.publish("opacity:changed", position);
		});

		this.sliderView.bind($sliderWrapper);
		this.toggleView.bind($toggleWrapper);
	}

	unbind() {
		this.sliderView.unbind();
		this.toggleView.unbind();
		this.$optionContainer.remove();
	}

	enableControls() {
		this.toggleView.enable();
		this.sliderView.enable();
	}

	disableControls() {
		this.toggleView.disable();
		this.sliderView.disable();
	}

	getSelected() {
		this.$optionContainer.find(
			".report-layer-multiselect-dropdown-option:selected"
		);
	}

	generateLegendContent() {
		let params = [
			"REQUEST=GetLegendGraphic",
			"VERSION=1.0.0",
			"FORMAT=image/png",
			"WIDTH=35",
			"HEIGHT=35",
			`LAYER=${this.layer.namespace || "MapRisk"}:${this.layer.name}`,
			this.style ? `STYLE=${this.style}` : null,
			"LEGEND_OPTIONS=forceLabels:on;fontAntiAliasing:true;fontSize:12;fontName:arial;fontStyle:bold;fontColor:0x333333;dx:15;dy:0;"
		];
		return {
			title: this.layer.displayName || "Layer Legend",
			url:
				this.layer.configuration.legendUrl ||
				this.tileServerPath + params.filter(Boolean).join("&")
		};
	}
}

module.exports = LayerOptionView;
