const ImageryClient = require("../../clients/imagery-client.js");

class ImageryCoverage {
	constructor(mapController) {
		this.mapController = mapController;
		this.client = new ImageryClient();
	}

	getCoverage(poi, params, state) {
		var self = this;
		var coveragePromise = $.Deferred();
		var imageryCoverageEvent;
		var coverage = this.client.getCoverage(poi, params, coveragePromise);

		$.when(coveragePromise).done(function (response) {
			if (response.length) {
				state.imagery.coverage = response;
			} else {
				state.imagery.coverage = [];
			}

			if (typeof Event === "function") {
				imageryCoverageEvent = new Event("imagery_coverage_updated");
			} else {
				imageryCoverageEvent = document.createEvent("Event");
				imageryCoverageEvent.initEvent("imagery_coverage_updated", true, true);
			}

			document.dispatchEvent(imageryCoverageEvent);
		});
	}
}

module.exports = ImageryCoverage;
