const ClientRenderedProviderBase = require("./client-rendered-provider-base");
const MarkerModel = require("../../../models/marker-model");
const { debounce } = require("../../../../utils");

const proj4 = require("proj4");

class GoogleMapsNativeOverlay extends ClientRenderedProviderBase {
	constructor(
		mapController,
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId,
		wfsClient,
		dataView
	) {
		super(
			mapController,
			reportId,
			reportConfigurationModel,
			clickDetection,
			overlayConfiguration,
			baseURL,
			layerId,
			domId,
			wfsClient,
			dataView
		);
	}

	getOpacity() {
		return this.opacity;
	}

	setOpacity(opacity) {
		this?.interactiveFeatures?.forEach((feature) => {
			feature.setOpacity(opacity);
		});

		if (window?.mrState.mrClickableOverrides?.[this.reportId]) {
			window.mrState.mrClickableOverrides[this.reportId].setOpacity(opacity);
		}

		this.opacity = opacity;
	}

	async activate(active) {
		this.clearFeatures();
		this.clearActiveFeature();
		this.clearBoundsChangeListener();

		if (this.overlayConfiguration.enableClickListener) {
			if (active) {
				this.clickDetection.attachClickListener(this.reportId);
			} else {
				this.clickDetection.detachClickListener(this.reportId);
			}
		}

		if (active) {
			if (window?.mrState.mrClickableOverrides?.[this.reportId]) {
				window.mrState.mrClickableOverrides[this.reportId].clearFeatures();
				window.mrState.mrClickableOverrides[this.reportId].createFeatures();
			}

			this.mapController.setCurrentLayerStyle(
				this.reportId,
				this.layerId,
				this.style
			);

			await this.getFeatures();
			this.mapController.drawPoiRadii(this.reportId);
		} else {
			if (window?.mrState.mrClickableOverrides?.[this.reportId]) {
				window.mrState.mrClickableOverrides[
					this.reportId
				].detachBoundsChangeListener();
				window.mrState.mrClickableOverrides[this.reportId].clearFeatures();
			}

			this.mapController.clearPoiRadii(this.reportId);
		}

		this.active = active;
	}

	clearFeatures() {
		let self = this;

		for (let i = 0; i < self?.interactiveFeatures?.length; i++) {
			if (
				self.activeFeatureId !==
				self.interactiveFeatures[i].mrLatitude +
					"," +
					self.interactiveFeatures[i].mrLongitude
			) {
				self.mapController.setMap(self.interactiveFeatures[i], null);
				self.mapController.clearMapListeners(
					self.interactiveFeatures[i],
					"click"
				);
			}
		}

		if (self?.interactiveFeatureEvents?.featureClicks) {
			self.interactiveFeatureEvents.featureClicks.forEach((listener) => {
				self.mapController.removeMapListener(listener);
			});

			self.interactiveFeatureEvents["featureClicks"] = [];
		}

		self.$mapEl.off("click.infoWindowPagination");
	}

	clearActiveFeature() {
		let self = this;

		if (self?.activeFeatureId) {
			for (let i = 0; i < self?.interactiveFeatures?.length; i++) {
				if (
					self.activeFeatureId ===
					self.interactiveFeatures[i].mrLatitude +
						"," +
						self.interactiveFeatures[i].mrLongitude
				) {
					self.interactiveFeatures[i].setMap(null);
					self.mapController.clearMapListeners(
						self.interactiveFeatures[i],
						"click"
					);
				}
			}
		}

		self.activeFeatureId = null;
	}

	clearBoundsChangeListener() {
		let self = this;

		if (this?.interactiveFeatureEvents?.boundsChanged) {
			this.interactiveFeatureEvents.boundsChanged.forEach((listener) => {
				self.mapController.removeMapListener(listener);
			});

			this.interactiveFeatureEvents["boundsChanged"] = [];
		}
	}

	stackFeatures(data) {
		let features = data;
		let stackedProperties = {};
		let srcProjection = new proj4("EPSG:900913");
		let dstProjection = new proj4("EPSG:4326");
		let latLngs = [];

		for (let i = 0; i < features.length; i++) {
			let coordinates = features[i].geometry.coordinates;
			let lat = coordinates[1];
			let lng = coordinates[0];
			let transformedProjectionPoints = proj4(srcProjection, dstProjection, [
				lng,
				lat
			]);

			lat = transformedProjectionPoints[1];
			lng = transformedProjectionPoints[0];

			if (!stackedProperties[lat + "," + lng]) {
				stackedProperties[lat + "," + lng] = [];
				latLngs.push(lat + "," + lng);
			}

			stackedProperties[lat + "," + lng].push(features[i]);
		}

		return stackedProperties;
	}

	stackDetails(data) {
		let features = data;
		let stackedProperties = {};
		let bounds = this.map.getBounds();
		let latLngs = [];

		for (let i = 0; i < features.length; i++) {
			let lat = features[i].latitude;
			let lng = features[i].longitude;
			let latLng = new google.maps.LatLng(lat, lng);

			if (bounds.contains(latLng)) {
				if (!stackedProperties[lat + "," + lng]) {
					stackedProperties[lat + "," + lng] = [];
					latLngs.push(lat + "," + lng);
				}

				stackedProperties[lat + "," + lng].push(features[i]);
			}
		}

		return stackedProperties;
	}

	featureClick(feature) {
		let self = this;

		if (self.currentInfoWindow) {
			self.currentInfoWindow.close();
		}

		self.activeFeatureId = feature.mrLatitude + "," + feature.mrLongitude;
		self.currentInfoWindow = feature.infoWindow;
		feature.infoWindow.open(self.mapController.getMap(), feature);
		self.openInfoWindowId = feature.reportDataIds;

		let infoWindowClick = google.maps.event.addListener(
			self.currentInfoWindow,
			"closeclick",
			function () {
				this.activeFeatureId = null;
				this.currentInfoWindow = null;
				this.openInfoWindowId = null;
			}
		);

		if (!self.interactiveFeatureEvents["infoWindowClicks"]) {
			self.interactiveFeatureEvents["infoWindowClicks"] = [];
		}

		self.interactiveFeatureEvents["infoWindowClicks"].push(infoWindowClick);
	}

	sortLatLngs(stackedProperties, currentPoi) {
		let self = this;
		let latLngs = Object.keys(stackedProperties);

		latLngs.sort(function (a, b) {
			let disp = {};
			let poi = self.mapController.createLatLng(currentPoi.lat, currentPoi.lng);
			let featurePoi = {
				a: {},
				b: {}
			};
			a = a.split(",");
			b = b.split(",");

			let poiA = a.map(function (el) {
				return parseFloat(el);
			});

			let poiB = b.map(function (el) {
				return parseFloat(el);
			});

			featurePoi.a = self.mapController.createLatLng(poiA[0], poiA[1]);
			featurePoi.b = self.mapController.createLatLng(poiB[0], poiB[1]);

			disp.a = self.mapController.computeDistanceBetween(featurePoi.a, poi);
			disp.b = self.mapController.computeDistanceBetween(featurePoi.b, poi);

			return disp.b - disp.a;
		});

		return latLngs;
	}

	sortAndTruncateStackedData(stackedData, currentPoi, source) {
		let latLngs = this.sortLatLngs(stackedData, currentPoi);

		if (latLngs.length > 100) {
			latLngs = latLngs.slice(latLngs.length - 100);
		}

		for (let latLng in stackedData) {
			if (latLngs.indexOf(latLng) < 0) {
				delete stackedData[latLng];
			}
		}

		return stackedData;
	}

	createInfoWindow(stackedProperties, source) {
		let self = this;

		for (let latLng in stackedProperties) {
			let coordinates = latLng.split(",");
			let lat = coordinates[0];
			let lng = coordinates[1];
			let reportDataIds = [];
			let contentBlocks = [];

			stackedProperties[latLng].forEach(function (data) {
				if (data.mapriskId) {
					reportDataIds.push(data.mapriskId);
				} else if (data.id) {
					reportDataIds.push(data.id);
				}

				contentBlocks.push(data);
			});

			let contentDiv =
				'<div class="info-window-container info-window-paginated" data-property-ids="' +
				reportDataIds.join(",") +
				'">';

			if (contentBlocks.length > 1) {
				contentDiv +=
					'<div class="info-window-pagination" data-max-num="' +
					(contentBlocks.length - 1) +
					'" data-current-page="1">';
				contentDiv +=
					'<span class="info-window-page-link info-window-page-link-previous">Prev</span>';
				contentDiv +=
					'<span class="info-window-page-current"><span class="info-window-page-number">1</span> of ' +
					contentBlocks.length +
					"</span>";
				contentDiv +=
					'<span class="info-window-page-link info-window-page-link-next">Next</span>';
				contentDiv += "</div>";
			}

			contentDiv += '<div class="info-window-wrappers">';

			contentBlocks.forEach(function (property, index) {
				let active = index === 0 ? " active" : "";
				let id;
				let policy;

				switch (source) {
					case "details":
						id = property.mapriskId;
						policy = property;
						break;
					case "details&wfs":
						id = property.id || property.mapriskId;
						policy = property.properties || property;
						break;
					case "wfs":
					default:
						id = property.id;
						policy = property.properties;
				}
				let contentWrapper =
					'<div class="info-window-wrapper' +
					active +
					'" id="info-window-wrapper-' +
					id +
					'" data-property-id="' +
					id +
					'" data-page-number="' +
					index +
					'">';

				if (window.batchId && window.portfolioName && window.portfolioReport) {
					// add link to run address to top of infowindow
					let address = `${policy.street} ${policy.city}, ${policy.state} ${policy.zip} ${policy.country}`;
					let addressString = `<a class="info-window-portfolio-address-link" href="#" data-address="${address}">Run Reports at this Address</a>`;

					contentWrapper += addressString;
				}

				let contentString = '<table class="info-window-table">';

				for (let policyIndex in policy) {
					contentString += '<tr class="info-window-table-row">';
					contentString +=
						'<th class="info-window-table-cell info-window-table-cell-heading" scope="row">' +
						policyIndex +
						"</th>";
					contentString +=
						'<td class="info-window-table-cell">' +
						policy[policyIndex] +
						"</td>";
					contentString += "</tr>";
				}

				contentString += "</table>";

				contentWrapper += contentString + "</div>";
				contentDiv += contentWrapper;
			});

			contentDiv += "</div>";
			contentDiv += "</div>";

			let featureIconConfig = null;

			let featureIcon = featureIconConfig || {
				path: self.mapController.getSymbol("circle"),
				fillOpacity: 0,
				fillColor: "#ffcc00",
				strokeOpacity: 1,
				strokeColor: "#000000",
				strokeWeight: 1,
				radius: 25,
				scale: 10
			};

			let feature = new MarkerModel({
				position: self.mapController.createLatLng(lat, lng),
				icon: featureIcon,
				mrLatitude: lat,
				mrLongitude: lng,
				reportId: this.reportId,
				reportDataIds: reportDataIds,
				infoWindow: self.mapController.getInfoWindow({
					content: contentDiv
				})
			}).marker;

			// set newly-drawn markers to current layer opacity
			let layerOpacity = this.opacity;
			feature.setOpacity(layerOpacity);

			if (
				!this.activeFeatureId ||
				this.activeFeatureId !== feature.mrLatitude + "," + feature.mrLongitude
			) {
				feature.setMap(self.mapController.getMap());
				this.interactiveFeatures.push(feature);

				if (!this.interactiveFeatureEvents["featureCreated"]) {
					this.interactiveFeatureEvents["featureCreated"] = [];
				}

				let featureCreatedEvent = document.createEvent("event");
				featureCreatedEvent.initEvent(
					"feature_created:" + feature.mrLatitude + "," + feature.mrLongitude,
					true,
					true
				);

				document.dispatchEvent(featureCreatedEvent);
			}
		}

		this.interactiveFeatures.forEach(function (feature) {
			let featureClick = feature.addListener("click", function () {
				self.featureClick(feature);

				if (
					window.mrState &&
					window.mrState.mrClickableOverrides &&
					window.mrState.mrClickableOverrides[self.reportId] &&
					window.mrState.mrClickableOverrides[self.reportId].activeFeature &&
					window.mrState.mrClickableOverrides[self.reportId].activeFeature
						.infoWindow
				) {
					window.mrState.mrClickableOverrides[
						self.reportId
					].activeFeature.infoWindow.close();
				}
			});

			if (!self.interactiveFeatureEvents["featureClicks"]) {
				self.interactiveFeatureEvents["featureClicks"] = [];
			}

			self.interactiveFeatureEvents["featureClicks"].push(featureClick);
		});
	}

	async fetchFeatures() {
		let response = await self.wfsClient.getFeatures(
			this.mapController,
			this.reportConfigurationModel,
			this.reportId,
			this.overlayConfiguration
		);

		return response.features;
	}

	async getFeatures() {
		let self = this;

		if (!self.overlayConfiguration.enableClick) {
			return;
		}

		let poi = {};

		if (self.mapController.currentPoi) {
			poi = self.mapController.currentPoi;
		} else {
			poi.lat = self.mapController.getCenter().lat;
			poi.lng = self.mapController.getCenter().lng;
		}

		self.clearFeatures();
		self.clearBoundsChangeListener();

		let debouncedGetFeatures = debounce(async () => {
			await self.getFeatures();
		}, 250);

		let boundsChangeEvent = self.mapController.addMapListener(
			"bounds_changed",
			debouncedGetFeatures
		);

		let stackedData;
		self.interactiveFeatureEvents["boundsChanged"] = [boundsChangeEvent];

		if (self.mapController.getZoom() < self.enableClickAtZoomLevel) {
			self.clearFeatures();
			self.clearActiveFeature();
			return;
		}

		switch (self.clickDataSource) {
			case "details":
				let data = [];

				self.dataView.state.currentReports.forEach(function (report) {
					if (report.reportId.toLowerCase() === self.reportId.toLowerCase()) {
						data = report.flatData;
					}
				});

				if (data.length > 0) {
					this.stackedFeatures = this.stackDetails(data);

					this.stackedFeatures = this.sortAndTruncateStackedData(
						this.stackedFeatures,
						poi
					);

					this.createInfoWindow(this.stackedFeatures, self.clickDataSource);
				}

				break;
			case "details&wfs":
				let details;

				self.dataView.state.currentReports.forEach(function (report) {
					if (report.reportId.toLowerCase() === self.reportId.toLowerCase()) {
						details = report.flatData;
					}
				});

				this.features = await this.fetchFeatures();

				this.stackedFeatures = this.stackFeatures(this.features) || {};

				this.stackedFeatures = this.sortAndTruncateStackedData(
					this.stackedFeatures,
					poi
				);

				this.createInfoWindow(this.stackedFeatures, self.clickDataSource);
				break;
			case "wfs":
			default:
				this.features = await this.fetchFeatures();
				this.stackedFeatures = this.stackFeatures(this.features) || {};

				if (Object.keys(this.stackedFeatures).length > 0) {
					this.stackedFeatures = this.sortAndTruncateStackedData(
						this.stackedFeatures,
						poi
					);

					this.createInfoWindow(this.stackedFeatures, self.clickDataSource);
				}
		}
	}
}

module.exports = GoogleMapsNativeOverlay;
