class ServerRenderedProviderBase {
	constructor(
		mapController,
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId
	) {
		this.mapController = mapController;
		this.reportId = reportId;
		this.reportConfigurationModel = reportConfigurationModel;
		this.clickDetection = clickDetection;
		this.overlayConfiguration = overlayConfiguration;
		this.baseURL = baseURL;
		this.layerId = layerId;
		this.domId = domId;

		this.mapType = overlayConfiguration?.mapType?.toLowerCase?.() ?? "wms";
		this.opacity = overlayConfiguration?.opacityLevel ?? 0.75;
		this.layerProjection = overlayConfiguration?.layerProjection ?? 900913;

		this.maxZoom = 2;
		this.minZoom = 28;
		this.tileHeight = 256;
		this.tileWidth = 256;
		this.map = mapController.map;

		this.geoserverEnv = "";
	}

	getOpacity() {
		this.overlay.getOpacity();
	}

	setOpacity(opacity) {
		this.overlay.setOpacity(opacity);
	}

	isActive() {
		throw new Error("Not yet implemented");
	}

	activate(active) {
		this.mapController.activateOverlay(active, this);

		if (this.overlayConfiguration.enableClickListener) {
			if (active) {
				this.clickDetection.attachClickListener(this.reportId);
			} else {
				this.clickDetection.detachClickListener(this.reportId);
			}
		}

		this.active = active;
	}
}

module.exports = ServerRenderedProviderBase;
