class Multiselect {
	constructor(reportId, view, parentContainer) {
		this.reportId = reportId;
		this.view = view;
		this.parentContainer = parentContainer;

		this.settings = {
			containerEl: ".mr-multi-select",
			triggerEl: ".mr-multi-select-trigger",
			labelEl: ".mr-multi-select-label",
			selectedInputContainerEl: ".mr-multi-select-selected-input-container",
			selectedInputEl: ".mr-multi-select-selected-input",
			containerActiveStateClass: "mr-multi-select-active",
			containerActiveLabelsStateClass: "mr-multi-select-labels-active",
			labelActiveStateClass: "mr-multi-select-label-active",
			containerAttr: "data-mr-multi-select-container",
			targetAttr: "data-mr-multi-select-target",
			singleSelectClass: "mr-multiselect-single-select"
		};

		this.$multiSelectContainer = null;
		this.keys = {};

		if (!this.reportId) {
			console.log("MultiSelect error: no reportId defined in options.");
		}

		if (!this.view) {
			console.log("MultiSelect error: no view defined in options.");
		}

		if (!this.parentContainer) {
			console.log("MultiSelect error: no parent container defined in options.");

			return;
		}

		this.create();
	}

	create() {
		let self = this;

		this.$multiSelectContainer = $(
			"#mr-multi-select-" + this.reportId + "_" + this.view
		);

		this.$multiSelectContainer
			.find(this.settings.containerEl)
			.each(function () {
				var key = $(this).attr("data-column");
				self.keys[key] = {};
			});

		this.attachTriggerClick();
		this.attachLabelClick();
	}

	clear() {
		let self = this;

		this.$multiSelectContainer
			.find(this.settings.containerEl + " " + this.settings.labelEl)
			.each(function () {
				var column = $(this).attr("data-column");
				var value = $(this).attr("data-value");
				self.deactivateLabel(column, value);
			});
	}

	triggerLabelClick(column, value) {
		this.$multiSelectContainer
			.find(
				this.settings.containerEl +
					"[data-column='" +
					column +
					"'] " +
					this.settings.labelEl +
					"[data-value='" +
					value +
					"']"
			)
			.trigger("click");
	}

	activateLabel(column, value) {
		let self = this;
		let $el;
		let actualValue;

		this.$multiSelectContainer
			.find(this.settings.containerEl + " " + this.settings.labelEl)
			.each(function () {
				if (
					$(this).attr("data-column").toLowerCase() === column.toLowerCase() &&
					$(this).attr("data-value").toLowerCase() === value.toLowerCase()
				) {
					$el = $(this);
					actualValue = $(this).attr("data-value");
				}
			});

		if ($el) {
			if (
				$el
					.parents(self.settings.containerEl)
					.hasClass(self.settings.singleSelectClass)
			) {
				$el
					.parents(self.settings.containerEl)
					.find(
						self.settings.labelEl + "." + self.settings.labelActiveStateClass
					)
					.each(function () {
						let column = $(this).attr("data-column");
						let value = $(this).attr("data-value");
						self.deactivateLabel(column, value);
					});
			}

			var $container = $el.parents(this.settings.containerEl);
			$el.addClass(this.settings.labelActiveStateClass);
			this.storeInput(column, actualValue);
			this.toggleTriggerState($container);
		}
	}

	deactivateLabel(column, value) {
		var $el;
		var actualValue;

		this.$multiSelectContainer
			.find(this.settings.containerEl + " " + this.settings.labelEl)
			.each(function () {
				if (
					$(this).attr("data-column").toLowerCase() === column.toLowerCase() &&
					$(this).attr("data-value").toLowerCase() === value.toLowerCase()
				) {
					$el = $(this);
					actualValue = $(this).attr("data-value");
				}
			});

		if ($el) {
			var $container = $el.parents(this.settings.containerEl);

			$el.removeClass(this.settings.labelActiveStateClass);
			this.deleteInput(column, actualValue);
			this.toggleTriggerState($container);
		}
	}

	toggleTriggerState($container) {
		if (
			$container.find(
				this.settings.labelEl + "." + this.settings.labelActiveStateClass
			).length > 0
		) {
			$container.addClass(this.settings.containerActiveLabelsStateClass);
		} else {
			$container.removeClass(this.settings.containerActiveLabelsStateClass);
		}
	}

	attachTriggerClick() {
		let self = this;

		this.$multiSelectContainer.on("click", this.settings.triggerEl, function (
			e
		) {
			e.preventDefault();

			var $container = $(this).parents(self.settings.containerEl);
			if ($container.hasClass(self.settings.containerActiveStateClass)) {
				$container.removeClass(self.settings.containerActiveStateClass);
			} else {
				$container.addClass(self.settings.containerActiveStateClass);
			}
		});
	}

	attachLabelClick() {
		let self = this;

		this.$multiSelectContainer.on("click", self.settings.labelEl, function (e) {
			e.preventDefault();

			var column = $(this).attr("data-column");
			var value = $(this).attr("data-value");

			if ($(this).hasClass(self.settings.labelActiveStateClass)) {
				self.deactivateLabel(column, value);
			} else {
				self.activateLabel(column, value);
			}

			self.emitChangeEvent();
		});
	}

	storeInput(column, value) {
		let self = this;

		var $el = this.$multiSelectContainer.find(
			this.settings.labelEl +
				'[data-column="' +
				column +
				'"][data-value="' +
				value +
				'"]'
		);
		var $container = $el.parents(this.settings.containerEl);

		$container
			.find(this.settings.selectedInputContainerEl)
			.append(
				$(
					'<span class="' +
						this.settings.selectedInputEl.split(".").join("") +
						'" data-value="' +
						value +
						'">' +
						value.charAt(0).toUpperCase() +
						value.slice(1) +
						"</span>"
				)
			);

		if (!this.keys[column]) {
			this.keys[column] = {};
		}

		this.keys[column][value] = value.charAt(0).toUpperCase() + value.slice(1);
	}

	deleteInput(column, value) {
		var $container = this.$multiSelectContainer.find(
			this.settings.containerEl + '[data-column="' + column + '"]'
		);

		$container
			.find(this.settings.selectedInputContainerEl)
			.find(this.settings.selectedInputEl + '[data-value="' + value + '"]')
			.remove();

		delete this.keys[column][value];
	}

	emitChangeEvent() {
		$(document).trigger("multiselect_changed", {
			reportId: this.reportId,
			view: this.view
		});
	}
}

module.exports = Multiselect;
