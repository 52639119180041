const $ = require("jquery");
window.$ = window.jQuery = $;

class HeritageCLUnderwritingGuidelines {
	constructor(reportId) {
		this.reportId = reportId;
		this.numberOfBuildings = 0;
		this.defaultNumberOfBuildings = 1;

		this.$container = $(`#div${this.reportId}_index .additional-info-form`);

		this.$numberOfBuildingsInput = this.$container.find(
			'.model-input[name="numberOfBuildings"]'
		);
		this.$highlandExceptionMadeInputGroup = this.$container.find(
			'.input-group[data-name="highlandExceptionMade"]'
		);
		this.$highlandExceptionMadeInput = this.$container.find(
			'.model-input[name="highlandExceptionMade"]'
		);

		this.buildingFieldsetCloneId = "building-fieldset-clone";
		this.$buildingFieldsetClone = this.$container.find(
			`#${this.buildingFieldsetCloneId}`
		);

		this.buildingFieldsetClass = "building-fieldset";
		this.buildingFieldsetLegendClass = "building-fieldset-legend";

		this.$buildingFieldsetClone.hide();

		this.attachNumberOfBuildingsChangeListener();
		this.appendBuildingFieldsets(this.defaultNumberOfBuildings);
	}

	attachNumberOfBuildingsChangeListener() {
		let self = this;

		self.$numberOfBuildingsInput.on("input", function (e) {
			let num = parseInt($(this).val());

			if (!Number.isInteger(num)) {
				return;
			}

			if (num < 1) {
				num = 1;
				$(this).val(1);
			}

			self.appendBuildingFieldsets(num);
		});
	}

	appendBuildingFieldsets(numberOfBuildings) {
		let self = this;

		if (numberOfBuildings < 1) {
			numberOfBuildings = 1;
		}

		if (numberOfBuildings >= self.numberOfBuildings) {
			for (let i = 0; i < numberOfBuildings - self.numberOfBuildings; i++) {
				let numFieldsets = $(`.${self.buildingFieldsetClass}`).length;

				let $buildingFieldset = self.$buildingFieldsetClone
					.clone()
					.removeAttr("id")
					.insertBefore(self.$buildingFieldsetClone)
					.addClass(self.buildingFieldsetClass)
					.show();
				$buildingFieldset
					.find(`.${self.buildingFieldsetLegendClass}`)
					.text(`Building ${numFieldsets + 1}`);
			}
		} else {
			for (let i = self.numberOfBuildings; i > numberOfBuildings; i--) {
				$(`.${self.buildingFieldsetClass}`).last().remove();
			}
		}

		self.numberOfBuildings = numberOfBuildings;
	}

	populateModelInputs(buildings) {
		let self = this;

		this.appendBuildingFieldsets(buildings.length);

		for (let i = 0; i < buildings.length; i++) {
			for (let input in buildings[i]) {
				$(`.${self.buildingFieldsetClass}`)
					.eq(i)
					.find(`*[name="${input}"]`)
					.val(buildings[i][input]);

				if (input === "floodPriorLosses") {
					$(`.${self.buildingFieldsetClass}`)
						.eq(i)
						.find(`*[name="${input}"]`)
						.trigger("change");
				}
			}
		}
	}
}

module.exports = HeritageCLUnderwritingGuidelines;
