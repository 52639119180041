const GoogleMapsWMSImageMapTypeOverlay = require("./google-maps-wms-image-map-type-overlay");
const GoogleMapsWMTSImageMapTypeOverlay = require("./google-maps-wmts-image-map-type-overlay");
const GoogleMapsZxyTileImageMapTypeOverlay = require("./google-maps-zxy-tile-image-map-type-overlay");

class ProviderFactory {
	constructor(mapController) {
		this.mapController = mapController;
	}

	make(
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId
	) {
		switch (overlayConfiguration?.mapType?.toLowerCase()) {
			case "wmts":
				return new GoogleMapsWMTSImageMapTypeOverlay(
					this.mapController,
					reportId,
					reportConfigurationModel,
					clickDetection,
					overlayConfiguration,
					baseURL,
					layerId,
					domId
				);
			case "zxy-tile":
				return new GoogleMapsZxyTileImageMapTypeOverlay(
					this.mapController,
					reportId,
					reportConfigurationModel,
					clickDetection,
					overlayConfiguration,
					baseURL,
					layerId,
					domId
				);
			case "wms":
			default:
				return new GoogleMapsWMSImageMapTypeOverlay(
					this.mapController,
					reportId,
					reportConfigurationModel,
					clickDetection,
					overlayConfiguration,
					baseURL,
					layerId,
					domId
				);
		}
	}
}

module.exports = ProviderFactory;
