module.exports = (elId, elContainerId) => {
	Microsoft.Maps.loadModule("Microsoft.Maps.AutoSuggest", {
		callback: (res) => {
			let options = {
				addressSuggestions: true,
				autoDetectLocation: false,
				businessSuggestions: false,
				maxResults: 5,
				placeSuggestions: false,
				useMapView: false,
				userLocation: false
			};

			let manager = new Microsoft.Maps.AutosuggestManager(options);
			manager.attachAutosuggest(`${elId}`, `${elContainerId}`);
		},
		errorCallback: (err) => {
			throw new Error(err);
		}
	});
};
