const { getCookie } = require("../../utils");
const {
	buildDerivedLayerParams,
	buildGeoserverParams
} = require("../../utils");

class WFS {
	constructor(http) {
		this.http = http;
	}

	async getFeatures(mapController, reportConfigurationModel, reportId, config) {
		let namespace = config.namespace || "MapRisk";
		let name = config.name || null;
		let wfsLayerName;

		if (config?.wfsLayerName?.indexOf?.(":") > -1) {
			namespace = config.wfsLayerName.split(":")[0];
			wfsLayerName = config.wfsLayerName.split(":")[1];
		}

		let token = getCookie("token") || getCookie("key");
		let data = {};
		let paramString;
		let bounds = mapController.getMapBounds();
		let swLat = bounds.getSouthWest().lat();
		let swLng = bounds.getSouthWest().lng();
		let neLat = bounds.getNorthEast().lat();
		let neLng = bounds.getNorthEast().lng();
		let requestParams = buildGeoserverParams(
			reportId,
			namespace,
			wfsLayerName ?? name,
			reportConfigurationModel,
			mapController
		);

		if (config.wfsLayerName && config.wfsLayerName.indexOf(":") > -1) {
			config.namespace = config.wfsLayerName.split(":")[0];
			config.wfsLayerName = config.wfsLayerName.split(":")[1];
		}

		if (config?.filterFields) {
			paramString = buildDerivedLayerParams(reportId, config).wfs;
		}

		data.token = config.token ?? token ?? null;

		paramString = requestParams.wfs;

		let url = `wfs/${namespace}:${
			wfsLayerName ?? name
		}/${swLat}/${swLng}/${neLat}/${neLng}/srsName=EPSG:900913/${reportId}`;

		if (paramString) {
			url += "?viewParams=" + paramString;
		}

		if (requestParams.cqlFilter) {
			url +=
				(paramString ? "&" : "?") + "CQL_FILTER=" + requestParams.cqlFilter;
		}

		try {
			const response = await this.http.get(url, {
				headers: { source: "website" }
			});

			return response.data;
		} catch (error) {
			throw new Error(error);
		}
	}
}

module.exports = WFS;
