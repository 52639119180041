const utils = require("../utils/index.js");

function ConfigurationSettingsClient() {
	let origin = location.protocol + "//" + location.host;
	this.origin = location.origin || origin;
	this.token =
		utils.getAuthTokenFromQueryParam() ||
		utils.getCookie("token") ||
		utils.getCookie("key");

	$.ajaxSetup({
		xhrFields: { withCredentials: true },
		headers: {
			"x-auth-key": this.token,
			source: "website"
		}
	});
}

ConfigurationSettingsClient.prototype.getReportConfigurationSettings = function (
	callback
) {
	var origin = this.origin;
	var data = {};

	$.ajax({
		type: "GET",
		url: origin + "/configurationSettings/reports",
		data: data,
		success: function (data) {
			callback(null, data);
		},
		error: function (err) {
			callback(err);
		}
	});
};

ConfigurationSettingsClient.prototype.getAccountConfigurationSettings = function (
	callback
) {
	var origin = this.origin;
	var data = {};

	$.ajax({
		type: "GET",
		url: origin + "/configurationSettings/account",
		data: data,
		success: function (response) {
			if (!response.success) callback(null, null);
			if (response.success) callback(null, response.data.accountConfiguration);
		},
		error: function (error) {
			callback(error, null);
		}
	});
};

module.exports = ConfigurationSettingsClient;
