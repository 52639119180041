const { eventTracker, getAuthenticationType } = require("../../../utils");
const Tabulator = require("tabulator-tables");
const moment = require("moment");

const AccountToolsInterface = require("../index.js");
const ConfirmDeleteInterface = require("./confirm-delete-interface");

class APIKeyManager extends AccountToolsInterface {
	constructor(accountClient, eventBus) {
		super(accountClient, eventBus);

		this.accountClient = accountClient;
		this.eventBus = eventBus;

		this.data = null;

		this.classList = {
			heading: ["api-key-manager-heading"],
			trigger: ["account-tools-link", "account-tools-link-manage-api-key"],
			container: ["api-key-manager-container"],
			table: ["api-key-manager-api-key-table"],
			closeButtonContainer: ["api-key-manager-close-button-container"],
			closeButton: ["api-key-manager-close-button"],
			closeButtonIcon: ["icon-cancel"],
			backdrop: ["api-key-manager-backdrop"],
			placeholder: ["api-key-manager-placeholder", "active"],
			userSettings: ["api-key-manager-settings"],
			userSettingsIcon: ["icon-cog"],
			addContainer: ["api-key-manager-add-container"],
			addLegend: ["api-key-manager-add-legend"],
			addLabel: ["api-key-manager-add-label"],
			addInput: ["api-key-manager-add-input"],
			addButton: ["api-key-manager-add-button"],
			footer: ["api-key-manager-footer"],
			footerButtonWrapper: ["api-key-manager-footer-button-wrapper"],
			message: ["api-key-manager-message"],
			copyIcon: ["icon-clone-o"],
			deleteIcon: ["icon-trash"]
		};

		this.activeClass = "active";
		this.headingText = "Manage API Keys";
		this.triggerId = "api-key-manager-trigger";
		this.triggerText = "Manage API Keys";
		this.containerId = "api-key-manager-container";
		this.tableId = "api-key-manager-api-key-table";
		this.tableColumns = ["key"];
		this.backdropId = "api-key-manager-backdrop";
		this.placeholderId = "api-key-manager-placeholder";
		this.placeholderText = "No active API keys found";
		this.messageText = "";

		this.createInterface();
	}

	async createInterface() {
		const self = this;
		const classes = this.classList;

		if (this.isInterfaceCreated === true) {
			return;
		}

		if (document.getElementById(this.triggerId)) {
			this.trigger = document.getElementById(this.triggerId);
		} else {
			return;
		}

		this.backdrop = document.createElement("div");
		this.backdrop.id = this.backdropId;
		this.backdrop.classList.add(...classes.backdrop);

		this.container = document.createElement("div");
		this.container.id = this.containerId;
		this.container.classList.add(...classes.container);

		this.heading = document.createElement("h3");
		this.heading.innerText = this.headingText;
		this.heading.classList.add(...classes.heading);

		this.placeholder = document.createElement("h4");
		this.placeholder.innerText = this.placeholderText;
		this.placeholder.classList.add(...classes.placeholder);

		this.closeButtonContainer = document.createElement("div");
		this.closeButtonContainer.classList.add(...classes.closeButtonContainer);

		this.closeButton = document.createElement("a");
		this.closeButton.classList.add(...classes.closeButton);

		this.closeButtonIcon = document.createElement("i");
		this.closeButtonIcon.classList.add(...classes.closeButtonIcon);

		this.closeButton.append(this.closeButtonIcon);
		this.closeButtonContainer.append(this.closeButton);

		this.table = document.createElement("div");
		this.table.id = this.tableId;
		this.table.classList.add(...classes.table);

		this.addContainer = document.createElement("fieldset");
		this.addContainer.classList.add(...classes.addContainer);

		this.addLegend = document.createElement("legend");
		this.addLegend.classList.add(...classes.addLegend);
		this.addLegend.innerText = "Get a New API Key";

		this.addLabel = document.createElement("label");
		this.addLabel.innerText = "Key Label";
		this.addLabel.classList.add(...classes.addLabel);

		this.addInput = document.createElement("input");
		this.addInput.type = "text";
		this.addInput.placeholder = "ex.) Primary API Key";
		this.addInput.classList.add(...classes.addInput);

		this.addButton = document.createElement("button");
		this.addButton.innerText = "Get New API Key";
		this.addButton.classList.add(...classes.addButton);

		this.addContainer.append(this.addLegend);
		this.addContainer.append(this.addLabel);
		this.addContainer.append(this.addInput);
		this.addContainer.append(this.addButton);

		this.footer = document.createElement("div");
		this.footer.classList.add(...classes.footer);

		this.footerButtonWrapper = document.createElement("div");
		this.footerButtonWrapper.classList.add(...classes.footerButtonWrapper);
		this.footerButtonLeftColumnWrapper = document.createElement("div");
		this.footerButtonRightColumnWrapper = document.createElement("div");

		this.message = document.createElement("div");
		this.message.classList.add(...classes.message);

		this.message.innerText = this.messageText;

		this.container.append(this.heading);
		this.container.append(this.placeholder);
		this.container.append(this.closeButtonContainer);
		this.container.append(this.table);
		this.container.append(this.addContainer);
		this.container.append(this.message);
		this.container.append(this.footer);
		this.footer.append(this.footerButtonWrapper);
		this.footerButtonWrapper.append(this.footerButtonLeftColumnWrapper);
		this.footerButtonWrapper.append(this.footerButtonRightColumnWrapper);

		document.body.append(this.backdrop);
		document.body.append(this.container);

		this.attachTriggerClickHandler();
		this.attachCloseButtonClickHandler();
		this.attachBackdropClickHandler();
		this.attachAddButtonClickHandler();

		this.unsubscribeApiKeyRevoked = this.eventBus.subscribe(
			"api-key:revoked",
			async (key) => {
				self.showMessage(
					`API Key ${key.substring(0, 4)} ... ${key.substring(
						key.length - 4,
						key.length
					)} deleted.`,
					"success"
				);
				await self.populateTable();
			}
		);

		this.unsubscribeApiKeyAdded = this.eventBus.subscribe(
			"api-key:added",
			async () => {
				self.showMessage("New API key added.", "success");
				await self.populateTable();
			}
		);
	}

	createCopyIcon(valueToCopy) {
		let icon = document.createElement("i");
		icon.classList.add(...this.classList.copyIcon);
		icon.title = "Copy API Key to Clipboard";
		this.attachCopyIconClickHandler(icon, valueToCopy);

		return icon;
	}

	createDeleteIcon(token, label) {
		let icon = document.createElement("i");
		icon.classList.add(...this.classList.deleteIcon);
		icon.title = `Delete API Key ${label}`;
		this.attachDeleteIconClickHandler(icon, token, label);

		return icon;
	}

	attachCopyIconClickHandler(el, valueToCopy) {
		const self = this;

		el.addEventListener("click", (e) => {
			navigator.clipboard.writeText(valueToCopy);
			self.showMessage("API key copied to clipboard.", "success");
		});
	}

	attachDeleteIconClickHandler(el, token, label) {
		const self = this;

		el.addEventListener("click", (e) => {
			e.preventDefault();
			self.openConfirmDeleteInterface(token, label);
		});
	}

	attachAddButtonClickHandler(el) {
		const self = this;

		this.addButton.addEventListener("click", async (e) => {
			e.preventDefault();

			let success = (res) => {
				self.eventBus.publish("api-key:added");
			};

			let error = (err) => {
				self.showMessage(err.error, "error");
			};

			let data = {
				label: this.addInput.value
			};

			await self.accountClient.addApiKey(data, error, success);
		});
	}

	async openInterface() {
		this.hideMessage();
		await this.populateTable();

		this.container.classList.add(this.activeClass);
	}

	async populateTable() {
		var self = this;

		let success = (res) => {
			self.data = res.data;

			if (!self.data) {
				if (self.tableInstance) {
					self.tableInstance.destroy();
					self.placeholder.classList.add("active");
				}
			} else {
				self.placeholder.classList.remove("active");

				self.tableInstance = new Tabulator("#" + self.tableId, {
					autoResize: true,
					columns: [
						{
							title: "Label",
							field: "label",
							sorter: "string",
							width: "25%",
							headerFilter: true,
							headerFilterPlaceholder: "Search..."
						},
						{
							title: "Key",
							field: "token",
							formatter: (cell, formatterParams, onRendered) => {
								return `<span title="${cell.getValue()}">${cell
									.getValue()
									.substring(0, 4)} ... ${cell
									.getValue()
									.substring(
										cell.getValue().length - 4,
										cell.getValue().length
									)}</span>`;
							},
							sorter: "string",
							width: "25%",
							headerFilter: true,
							headerFilterPlaceholder: "Search..."
						},
						{
							title: "Copy Key",
							field: "token",
							formatter: (cell, formatterParams, onRendered) => {
								return self.createCopyIcon(cell.getValue());
							},
							headerSort: false,
							width: "10%"
						},
						{
							title: "Expiration Date",
							field: "expirationDate",
							formatter: (cell, formatterParams, onRendered) => {
								const options = {
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
									hour: "2-digit",
									minute: "2-digit",
									timeZoneName: "short"
								};

								if (cell.getValue()) {
									return new Date(cell.getValue()).toLocaleString(
										"en-US",
										options
									);
								} else {
									return null;
								}
							},
							sorter: "datetime",
							sorterParams: {
								format: "MMMM DD YYYY, h:mm:ss a"
							},
							width: "30%"
						},
						{
							title: "Delete Key",
							formatter: (cell) => {
								return self.createDeleteIcon(
									cell.getRow().getData().token,
									cell.getRow().getData().label
								);
							},
							headerSort: false,
							width: "10%"
						}
					],
					data: self.data,
					headerFilterDelay: 100,
					layout: "fitColumns",
					layoutColumnsOnNewData: false,
					height: 300,
					maxHeight: "100%",
					placeholder: "No API keys found",
					resizableColumns: false,
					sorting: true
				});
			}
		};

		let error = (err) => {
			self.showMessage(err.error, "error");
		};

		let apiKeys = await this.accountClient.fetchApiKeys(error, success);
	}

	openConfirmDeleteInterface(token, label) {
		const confirmDeleteInterface = new ConfirmDeleteInterface(
			this.accountClient,
			this.eventBus,
			token,
			label
		);

		confirmDeleteInterface.openBackdrop();
		confirmDeleteInterface.openInterface();
	}
}

module.exports = APIKeyManager;
