const {
	eventTracker,
	extend,
	getAuthenticationType,
	getReportParameterByName
} = require("../utils");
const Tabulator = require("tabulator-tables");
const moment = require("moment");

const DataView = function (mapController) {
	var self = this;

	self.options = {
		containerBaseClass: "data-view-container drawer",
		containerClassNames: "",
		innerContainerBaseClass: "data-view-inner-container",
		innerContainerClassNames: "",
		headerBaseClass: "data-view-header",
		headerClassNames: "",
		csvButtonBaseClass: "data-view-csv-button",
		excelButtonBaseClass: "data-view-excel-button",
		resetButtonBaseClass: "data-view-reset-button",
		changeViewButtonBaseClass: "data-view-change-view-button",
		exitButtonBaseClass: "data-view-exit-button",
		wrapperBaseClass: "data-view-wrapper",
		wrapperClassNames: "",
		position: "before"
	};

	self.stateModel = {
		currentReports: [],
		activeReport: null,
		lastKnownColumns: null,
		filterByBounds: true,
		firstRender: true
	};

	self.state = JSON.parse(JSON.stringify(self.stateModel));
	self.mapController = mapController;

	self.$dataViewContainer = $("<div></div>");
	self.$dataViewInnerContainer = $("<div></div>");
	self.$dataViewHeader = $("<div></div>");
	self.$dataViewCSVButton = $(
		"<div class='button-dataview-export-csv button button-cta'>Export Raw Data</div>"
	);
	self.$dataViewExcelButton = $(
		"<a class='button-dataview-export-excel button button-cta'>Export for Excel</a>"
	);
	self.$dataViewResetButton = $(
		"<a class='button-dataview-reset button button-info'>Reset Filters</a>"
	);
	self.$dataViewChangeViewButton = $(
		'<div><i class="icon-window-maximize"></i></div>'
	);
	self.$dataViewExitButton = $('<div><i class="icon-cancel"></i></div>');
	self.$dataViewWrapper = $("<div></div>");

	self.init = function (options) {
		if (options) {
			self.options = extend.deepmerge(self.options, options);
		}

		if (!self.options.parentContainer) {
			console.log("Data View error: no parent container defined in options.");

			return;
		}

		if (!self.options.id) {
			console.log("Data View error: no id specified.");

			return;
		}

		if ($("#" + self.options.id).length <= 0) {
			self.create();
		} else {
			console.log(
				"Data View error: Cannot create duplicate Data View container."
			);

			return;
		}
	};

	self.create = function () {
		self.$dataViewContainer.attr("id", self.options.id);
		self.$dataViewContainer.addClass(
			self.options.containerBaseClass + " " + self.options.containerClassNames
		);
		self.$dataViewHeader.addClass(
			self.options.headerBaseClass + " " + self.options.headerClassNames
		);
		self.$dataViewCSVButton.addClass(self.options.csvButtonBaseClass);
		self.$dataViewExcelButton.addClass(self.options.excelButtonBaseClass);
		self.$dataViewResetButton.addClass(self.options.resetButtonBaseClass);
		self.$dataViewChangeViewButton.addClass(
			self.options.changeViewButtonBaseClass
		);
		self.$dataViewExitButton.addClass(self.options.exitButtonBaseClass);
		self.$dataViewInnerContainer.addClass(self.options.innerContainerBaseClass);
		self.$dataViewWrapper.addClass(
			self.options.wrapperBaseClass + " " + self.options.wrapperClassNames
		);

		self.$dataViewHeader
			.append(self.$dataViewCSVButton)
			.append(self.$dataViewExcelButton)
			.append(self.$dataViewResetButton)
			.append(self.$dataViewChangeViewButton)
			.append(self.$dataViewExitButton);

		self.$dataViewInnerContainer.append(self.$dataViewHeader);

		self.$dataViewContainer.append(self.$dataViewInnerContainer);

		$(self.options.parentContainer).append(self.$dataViewContainer);

		self.events.csvClick();
		self.events.resetClick();
		self.events.changeViewClick();
		self.events.exitClick();
	};

	self.destroy = function () {
		self.hideView();
		self.removeAllReports();
		self.$dataViewContainer.remove();
	};

	self.reset = function () {
		self.hideView();
		self.removeAllReports();
	};

	self.showView = function () {
		// doesn't actually do anything since Tabulator completely eats the browser's render cycle
		$(".loading-overlay").addClass("active");

		if (self.state.currentReports.length === 0) {
			self.$dataViewContainer.addClass("active");
			$(".bodyContentMainWrapper").addClass("data-view-active");
		}

		self.state.currentReports.forEach(function (report) {
			if (report.reportId === self.state.activeReport) {
				var height =
					self.$dataViewContainer.outerHeight() -
					self.$dataViewHeader.outerHeight();
				report.table.setHeight(height);
				report.table.redraw(true);

				self.$dataViewContainer.addClass("active");
				$(".bodyContentMainWrapper").addClass("data-view-active");

				if (
					getReportParameterByName(
						window.reportConfigurationModel,
						report.reportId,
						"enableDataViewExportToExcel"
					) !== true
				) {
					self.$dataViewExcelButton.addClass("disabled");
				} else {
					self.$dataViewExcelButton.removeClass("disabled");
				}
			}
		});
	};

	self.hideView = function () {
		$(".reportNameHeaderDataViewToggleContainer .chkDataViewControl").each(
			function () {
				$(this).removeClass("active");
			}
		);

		self.$dataViewContainer.removeClass("active");
		$(".bodyContentMainWrapper").removeClass("data-view-active");
	};

	self.changeView = function () {
		if ($(self.$dataViewContainer).hasClass("popup")) {
			$(self.$dataViewContainer).removeClass("popup");
			$(self.$dataViewContainer).addClass("drawer");
			self.$dataViewChangeViewButton.html(
				'<i class="icon-window-maximize"></i>'
			);
		} else {
			$(self.$dataViewContainer).removeClass("drawer");
			$(self.$dataViewContainer).addClass("popup");
			self.$dataViewChangeViewButton.html(
				'<i class="icon-window-minimize"></i>'
			);
		}

		self.state.currentReports.forEach(function (report) {
			if (report.reportId === self.state.activeReport) {
				var height =
					self.$dataViewContainer.outerHeight() -
					self.$dataViewHeader.outerHeight();
				report.table.setHeight(height);

				if (
					getReportParameterByName(
						window.reportConfigurationModel,
						report.reportId,
						"enableDataViewExportToExcel"
					) !== true
				) {
					self.$dataViewExcelButton.addClass("disabled");
				} else {
					self.$dataViewExcelButton.removeClass("disabled");
				}
			}
		});
	};

	self.flattenData = function (data) {
		var flatData = [];

		data.forEach(function (el) {
			var flatEl = JSON.parse(JSON.stringify(el));

			for (var prop in flatEl) {
				if (Array.isArray(flatEl[prop]) || flatEl[prop] instanceof Object) {
					flatEl[prop] = JSON.stringify(flatEl[prop]);
				}
			}

			if (flatEl && Object.keys(flatEl).length > 0) {
				flatData.push(flatEl);
			}
		});

		return flatData;
	};

	self.getColumns = function (data, fieldConfiguration) {
		var columns = [];
		var hideColumn = false;
		var columnName;
		var flatData = data.map(function (el) {
			var flatEl = JSON.parse(JSON.stringify(el));

			for (var prop in flatEl) {
				columnName = prop;

				if (
					fieldConfiguration &&
					fieldConfiguration.fields &&
					fieldConfiguration.fields[prop]
				) {
					hideColumn = fieldConfiguration.fields[prop].hide || false;
					columnName =
						fieldConfiguration.fields[prop].displayName || columnName;
				}

				if (columns.indexOf(columnName) === -1 && !hideColumn) {
					columns.push(columnName);
				}
			}
		});

		return columns;
	};

	self.customFilter = function () {
		return true;
	};

	self.mapColumns = function (columns, reRunReportOnFilter) {
		// Maps each column header with configurable filtering method based on reportConfig reRunReportOnFilter parameter
		return columns.map(function (el, index) {
			if (el.toLowerCase() === "mapriskid") {
				var columnMap = {
					title: el,
					field: el,
					headerFilter: false,
					headerFilterLiveFilter: false
				};
			} else {
				var columnMap = {
					title: el,
					field: el,
					headerFilter: true,
					headerFilterLiveFilter: true
				};

				if (reRunReportOnFilter) {
					columnMap.headerFilterFunc = self.customFilter;
					columnMap.headerFilterLiveFilter = false;
				}
			}

			return columnMap;
		});
	};

	self.getFilterMethod = function (report) {
		var param = null;
		if (report && report.reportId) {
			param = getReportParameterByName(
				window.reportConfigurationModel,
				report.reportId,
				"reRunReportOnFilter"
			);
		}
		return param;
	};

	self.addReport = function (report) {
		var report = $.extend(true, [], report);
		var $dataWrapper = self.$dataViewWrapper.clone();
		var height = self.$dataViewInnerContainer.outerHeight();
		var headerHeight = self.$dataViewHeader.outerHeight();
		var tableHeight = height - headerHeight;
		var reportFound = false;
		var reportId = report.reportId;
		var lastIndex = -1;

		var fieldConfiguration = report.fieldConfiguration;
		var columns = self.getColumns(report.details, fieldConfiguration);
		var flatData = self.flattenData(report.details);
		var reRunReportOnFilter = self.getFilterMethod(report) || false;
		var tableColumns = self.mapColumns(columns, reRunReportOnFilter);

		// add toggle to report results sidebar
		self.addToggle(reportId);

		$dataWrapper
			.attr("id", self.options.id + "-" + report.reportId)
			.attr("data-reportId", report.reportId);

		self.$dataViewInnerContainer.append($dataWrapper);

		for (var i = 0; i < self.state.currentReports.length; i++) {
			if (
				self.state.currentReports[i].reportId &&
				self.state.currentReports[i].reportId === reportId
			) {
				reportFound = true;
				self.state.currentReports[i] = report;
				lastIndex = i;

				break;
			}
		}

		if (reportFound === false) {
			self.state.currentReports.push(report);
			lastIndex = self.state.currentReports.length - 1;
		}

		self.state.currentReports[lastIndex].flatData = flatData;
		self.state.currentReports[lastIndex].tableColumns = tableColumns;

		if (
			getReportParameterByName(
				window.reportConfigurationModel,
				reportId,
				"aggregateFeaturesInRadius"
			) != null
		) {
			window.clearAggregateFeaturesInRadius(reportId);
		}

		self.state.currentReports[lastIndex].table = new Tabulator(
			"#" + self.options.id + "-" + report.reportId,
			{
				index: "mapriskId",
				autoResize: true,
				columns: self.state.currentReports[lastIndex].tableColumns,
				data: self.state.currentReports[lastIndex].flatData,
				headerFilterPlaceholder: "filter...",
				height: tableHeight + "px",
				layout: "fitDataFill",
				layoutColumnsOnNewData: false,
				dataLoaded: function () {
					var layerId = $("#selectLayerControl_" + reportId)
						.find("option:selected")
						.val();
					var clickDataSource = "wfs";

					window.reportConfigurationModel[
						reportId.toLowerCase()
					].layers.forEach(function (layer) {
						if (layerId === layer.namespace + ":" + layer.name) {
							clickDataSource = layer.clickDataSource || "wfs";
						}
					});

					if (
						getReportParameterByName(
							window.reportConfigurationModel,
							reportId,
							"aggregateFeaturesInRadius"
						) != null
					) {
						if (
							window.mapRiskController.map.getZoom() <
							window.mapRiskController.layers[reportId].clickZoom
						) {
							window.aggregateFeaturesInRadius(reportId);
						}
					}

					if (clickDataSource === "details") {
						window.mapRiskController.layers[reportId][
							layerId
						].getClickablePoints();
					}
				},
				renderComplete: function () {
					$(".loading-overlay").removeClass("active");

					/*
						Tabulator fires a render event after tabbing out of a header filter input box
						or anything else that might trigger a repaint.  Checking for first render
						needs to be done to keep to the filter inputs from being repopulated after
						any of those events.

						getReportFilterFields() returns the filters currently set by the right
						sidebar multiSelects
					*/
					if (self.state.firstRender === true) {
						self.setFilterParams(
							reportId,
							window.getReportFilterByFields(reportId, "index"),
							false
						);
					}

					// this gonna run after every table refresh, so wipe the keypress event listeners and re-attach
					if (reRunReportOnFilter) {
						self.events.detachHeaderFilterKeyup(report.reportId);
						self.events.attachHeaderFilterKeyup(report.reportId);
					}

					self.state.firstRender = false;
				},
				rowClick: function (e, row) {
					var rowData = row.getData();
					var propertyId = rowData.mapriskId;
					var layerId = $("#selectLayerControl_" + reportId)
						.find("option:selected")
						.val();
					var lat = rowData.latitude;
					var lng = rowData.longitude;
					var marker;
					var infoWindow;

					var markerActions = function (infoWindowIsOpen) {
						marker = window.getClickableMarkerByLatLng(
							reportId,
							layerId,
							lat,
							lng
						);
						infoWindow = marker.infoWindow;

						if (infoWindowIsOpen) {
							window.goToInfoWindowPage(propertyId);
						} else {
							google.maps.event.addListenerOnce(
								infoWindow,
								"domready",
								function () {
									window.goToInfoWindowPage(propertyId);
								}
							);
						}

						window.mapRiskController.layers[reportId][layerId].markerClick(
							marker
						);
					};

					var markerLoadedListener = document.addEventListener(
						"marker_created:" +
							rowData.latitude.toString() +
							"," +
							rowData.longitude.toString(),
						function (e) {
							markerActions();

							e.target.removeEventListener(e.type, arguments.callee);
						},
						false
					);

					var openInfoWindowId =
						window.mapRiskController.layers[reportId][layerId]
							.openInfoWindowId || [];
					if (!Array.isArray(openInfoWindowId)) {
						openInfoWindowId = [openInfoWindowId];
					}

					if (openInfoWindowId.indexOf(propertyId) > -1) {
						markerActions(true);
					} else {
						window.mapRiskController.map.setCenter({ lat: lat, lng: lng });
						window.eventBus.publish("map:poi:changed");
					}
				}
			}
		);

		$(document).on("multiselect_changed", function (e, data) {
			self.setFilterParams(
				reportId,
				window.getReportFilterByFields(data.reportId, data.view),
				false
			);
		});
	};

	self.updateReport = function (reportId, data) {
		var flatData = [];

		self.addToggle(reportId);

		for (var i = 0; i < self.state.currentReports.length; i++) {
			if (
				self.state.currentReports[i].reportId &&
				self.state.currentReports[i].reportId.toLowerCase() ==
					reportId.toLowerCase()
			) {
				flatData = self.flattenData(data);
				self.state.currentReports[i].details = data;
				self.state.currentReports[i].flatData = flatData;
				self.state.currentReports[i].table.setData(flatData);
			}
		}
	};

	self.emptyReport = function (reportId) {
		for (var i = 0; i < self.state.currentReports.length; i++) {
			if (
				self.state.currentReports[i].reportId &&
				self.state.currentReports[i].reportId.toLowerCase() ==
					reportId.toLowerCase()
			) {
				self.state.currentReports[i].details = null;
				self.state.currentReports[i].flatData = null;
				self.state.currentReports[i].table.setData(null);
			}
		}

		self.addToggle(reportId);
	};

	self.removeReport = function (reportId) {
		if (
			self.state.activeReport &&
			self.state.activeReport.toLowerCase() === reportId.toLowerCase()
		) {
			self.state.activeReport = null;
		}

		for (var i = 0; i < self.state.currentReports.length; i++) {
			if (
				self.state.currentReports[i].reportId &&
				self.state.currentReports[i].reportId == reportId
			) {
				self.$dataViewContainer
					.find(
						"." +
							self.options.wrapperBaseClass +
							'[data-reportId="' +
							reportId +
							'"]'
					)
					.remove();

				self.events.detachToggleClick(reportId);
				$(
					".reportNameHeaderDataViewToggleContainer #chkDataViewControl_" +
						reportId
				).remove();
				self.state.currentReports.splice(i, 1);
			}
		}

		if (self.state.currentReports.length <= 0) {
			self.hideView();
		}

		$(document).off("multiselect_changed");
	};

	self.removeAllReports = function () {
		self.state = JSON.parse(JSON.stringify(self.stateModel));

		self.hideView();
		self.$dataViewContainer.find(self.$dataWrapper).each(function () {
			$(this).remove();
		});
		self.events.detachAllToggleClicks();
	};

	self.addToggle = function (reportId) {
		if (
			$(
				"#divAdditionalReportInformation_" +
					reportId +
					"_reportTableRow .report-toolbar .reportNameHeaderDataViewToggleContainer .chkDataViewControl"
			).length <= 0
		) {
			var $toggleWrapper = $(
				"#divAdditionalReportInformation_" +
					reportId +
					"_reportTableRow .report-toolbar .reportNameHeaderDataViewToggleContainer"
			);
			var $toggleIcon = $(
				'<a href="#" id="chkDataViewControl_' +
					reportId +
					'" class="chkDataViewControl" title="Table Data View"><i class="icon-table"></i></a>'
			);

			$toggleWrapper.append($toggleIcon);

			self.events.detachToggleClick(reportId);
			self.events.attachToggleClick(reportId);
		}
	};

	self.enableReportButton = function (reportId) {
		$(
			".reportNameHeaderDataViewToggleContainer #chkDataViewControl_" + reportId
		).removeClass("disabled");
	};

	self.disableReportButton = function (reportId) {
		$(
			".reportNameHeaderDataViewToggleContainer #chkDataViewControl_" + reportId
		).addClass("disabled");
	};

	self.viewReport = function (reportId) {
		// hide all reports
		self.$dataViewContainer
			.find("." + self.options.wrapperBaseClass)
			.removeClass("active");

		// show specified report
		self.$dataViewContainer
			.find("#" + self.options.id + "-" + reportId)
			.addClass("active");
		self.state.activeReport = reportId;

		var tokenUrlQueryParam = (function () {
			var pageUrl = window.location.search.substring(1);
			var urlVariables = pageUrl.split("&");

			for (var i = 0; i < urlVariables.length; i++) {
				var parameterName = urlVariables[i].split("=");

				if (parameterName[0] === "token") {
					return parameterName[1] === undefined
						? true
						: decodeURIComponent(parameterName[1]);
				}
			}
		})();

		self.$dataViewContainer.off(
			"click",
			`.${self.options.excelButtonBaseClass}`
		);
		self.$dataViewContainer.on(
			"click",
			`.${self.options.excelButtonBaseClass}`,
			function (e) {
				e.stopImmediatePropagation();
				e.preventDefault();

				let url = `${window.batchUrlBase}${window.batchPath}/transaction/spreadsheet/${window.reportsModel[reportId].transactionId}`;

				let reportData = {
					reportData: {}
				};

				reportData.reportData[reportId] = {
					filterByFields: window.getReportFilterByFields(reportId, "index")
				};

				if (tokenUrlQueryParam && reportData !== null) {
					url += "?token=" + tokenUrlQueryParam + "&" + $.param(reportData);
				} else if (tokenUrlQueryParam) {
					url += "?token=" + tokenUrlQueryParam;
				} else if (reportData) {
					url += "?" + $.param(reportData);
				}

				window.open(url);
			}
		);

		self.showView();
	};

	self.getFilterParams = function (reportId) {
		var params = {};

		$("#" + self.options.id + "-" + reportId)
			.find(".tabulator-header-filter input")
			.each(function () {
				var col = $(this).parent().siblings(".tabulator-col-title").text();
				var value = $(this).val().toLowerCase();

				if (value) {
					value = value.split("||");

					if (Array.isArray(value)) {
						value.forEach(function (val, index, array) {
							array[index] = val.trim();
						});

						params[col] = value;
					} else {
						params[col] = [value];
					}
				}
			});

		return params;
	};

	self.setFilterParams = function (report, filters, refreshLayer) {
		// Populate tabulator header filters based on filters arg.  ex.) [{key:value},{key:value}]
		let currentReport = self.state.currentReports.find(
			({ reportId }) => report.toLowerCase() === reportId.toLowerCase()
		);

		if (currentReport?.table) {
			for (let filter in filters) {
				let filterString;

				if (filters[filter]) {
					filterString = filters[filter].join(" || ");
				} else {
					filterString = "";
				}

				currentReport.table.setHeaderFilterValue(filter, filterString);
			}

			// force a map refresh if the tabulator filter field change requires a visual layer to update
			if (refreshLayer && self.mapController.map) {
				self.mapController.map.setZoom(self.mapController.map.getZoom());
			}

			if (!currentReport.filters) {
				currentReport.filters = {};
			}

			currentReport.filters = extend.mergeObject(
				currentReport.filters,
				filters
			);
		}
	};

	self.events = {
		exitClick: function () {
			$(self.options.parentContainer).on(
				"click",
				"." + self.options.exitButtonBaseClass,
				function () {
					self.hideView();
				}
			);
		},

		changeViewClick: function () {
			$(self.options.parentContainer).on(
				"click",
				"." + self.options.changeViewButtonBaseClass,
				function () {
					self.changeView();
				}
			);
		},

		csvClick: function () {
			$(self.options.parentContainer).on(
				"click",
				"." + self.options.csvButtonBaseClass,
				function () {
					for (var i = 0; i < self.state.currentReports.length; i++) {
						if (
							self.state.currentReports[i].reportId === self.state.activeReport
						) {
							var date = moment().format("YYYY-MM-DD_HH-mm-ss");

							let label = {
								_id: window?.id,
								reportId: self.state.activeReport,
								request_type: getAuthenticationType()
							};

							eventTracker("user_action", "dataview:csv:export", label);

							self.state.currentReports[i].table.download(
								"csv",
								self.state.currentReports[i].reportId + "_" + date + ".csv"
							);
						}
					}
				}
			);
		},

		resetClick: function () {
			$(self.options.parentContainer).on(
				"click",
				"." + self.options.resetButtonBaseClass,
				function (e) {
					e.preventDefault();
					var filterColumns = {};

					var defaults = getReportParameterByName(
						window.reportConfigurationModel,
						self.state.activeReport,
						"filterDefaults"
					);

					var reRunReportOnFilter = getReportParameterByName(
						window.reportConfigurationModel,
						self.state.activeReport,
						"reRunReportOnFilter"
					);

					if (defaults) {
						if (
							window.mrState.mrMultiselect &&
							window.mrState.mrMultiselect[self.state.activeReport + "_index"]
						) {
							window.mrState.mrMultiselect[
								self.state.activeReport + "_index"
							].clear();

							for (var column in defaults) {
								defaults[column].forEach(function (val) {
									window.mrState.mrMultiselect[
										self.state.activeReport + "_index"
									].activateLabel(column, val);
								});
							}
						}
					}

					self.state.currentReports.forEach(function (el) {
						if (el.reportId === self.state.activeReport) {
							el.tableColumns.forEach(function (col) {
								if (col.headerFilter) {
									filterColumns[col.field] = null;
								}
							});
						}
					});

					var filters = Object.assign(filterColumns, defaults);

					self.setFilterParams(self.state.activeReport, filters, true);

					if (reRunReportOnFilter) {
						window.rerunReportOnModelInputChange(self.state.activeReport);
					}
				}
			);
		},

		attachToggleClick: function (reportId) {
			$(".report-toolbar").on(
				"click." + reportId,
				"#chkDataViewControl_" + reportId,
				function (e) {
					e.preventDefault();

					var otherViewEnabled = false;

					if ($(this).hasClass("active")) {
						$(this).removeClass("active");
					} else {
						$(".report-toolbar .chkDataViewControl").removeClass("active");
						$(this).addClass("active");

						let label = {
							_id: window?.id,
							reportId: reportId,
							request_type: getAuthenticationType()
						};

						eventTracker("user_action", "dataview:open", label);

						self.viewReport(reportId);
					}

					$(".report-toolbar .chkDataViewControl").each(function () {
						if ($(this).hasClass("active")) {
							otherViewEnabled = true;
						}
					});

					if (!otherViewEnabled) {
						self.hideView();
					}
				}
			);
		},

		detachToggleClick: function (reportId) {
			$(".report-toolbar").off("click", "#chkDataViewControl_" + reportId);
		},

		detachAllToggleClicks: function () {
			$(".report-toolbar").off("click", ".chkDataViewControl");
		},

		attachHeaderFilterKeyup: function (reportId) {
			self.state.currentReports.forEach(function (currentReport) {
				if (currentReport.reportId === reportId) {
					if (currentReport?.table?.element) {
						$(currentReport.table.element)
							.find(".tabulator-header-filter input")
							.on("keyup", function (e) {
								e.stopPropagation();
								e.preventDefault();
								// triggering filter on Enter keypress or focus switch
								if (e.key === "Enter" || e.key === "Tab") {
									let filters = currentReport.table.getHeaderFilters();
									currentReport.filters = {};

									filters.forEach((filter) => {
										currentReport.filters[filter.field] = filter.value.split(
											"||"
										);
									});

									if (window?.mrState?.mrMultiselect?.[reportId + "_index"]) {
										window.mrState.mrMultiselect[reportId + "_index"].clear();

										for (let filter in currentReport.filters) {
											let column = filter;

											let vals = currentReport.filters[filter];

											if (!Array.isArray(vals)) {
												vals = [vals];
											}

											vals.forEach((val) => {
												window.mrState.mrMultiselect[
													reportId + "_index"
												].activateLabel(column, val.trim());
											});
										}
									}

									window.rerunReportOnModelInputChange(reportId);
								}
							});
					}
				}
			});
		},

		detachHeaderFilterKeyup: function (reportId) {
			for (var i = 0; i < self.state.currentReports.length; i++) {
				if (self.state.currentReports[i].reportId === reportId) {
					if (
						self.state.currentReports[i].table &&
						self.state.currentReports[i].table.element
					) {
						$(self.state.currentReports[i].table.element)
							.find(".tabulator-header-filter input")
							.off("keyup");
					}
				}
			}
		}
	};
};

module.exports = DataView;
