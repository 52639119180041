const ServerRenderedOverlay = require("./server-rendered-overlay");

class ServerRenderedOverlayFactory {
	constructor(mapController) {
		this.mapController = mapController;
	}

	make(
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId
	) {
		return new ServerRenderedOverlay(
			this.mapController,
			reportId,
			reportConfigurationModel,
			clickDetection,
			overlayConfiguration,
			baseURL,
			layerId,
			domId
		);
	}
}

module.exports = ServerRenderedOverlayFactory;
