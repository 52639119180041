const utils = require("../utils/index.js");
const CONSTANTS = require("../../../../RuntimeService/lib/index");
const dialog = require("../dialog/index");
function GeocodeClient() {
	let origin = location.protocol + "//" + location.host;
	this.origin = location.origin || origin;
	this.token =
		utils.getAuthTokenFromQueryParam() ||
		utils.getCookie("token") ||
		utils.getCookie("key");

	$.ajaxSetup({
		xhrFields: { withCredentials: true },
		headers: {
			"x-auth-key": this.token,
			source: "website"
		}
	});
}

GeocodeClient.prototype.geocodeAddress = function (data, callback) {
	var origin = this.origin;

	$.ajax({
		type: "GET",
		url: origin + "/geocode",
		data: data,
		success: function (data) {
			//If we do not get any data back despite a succesful response
			//we will open a dialog indicating a geocode server error
			callback(null, data);
		},
		error: function (error) {
			callback(error, null);
		},
		statusCode: {
			//Called when passport fails and returns an unauthorized to impersonate
			//error
			401: function () {
				dialog.openDialog(
					CONSTANTS.DIALOG.NOT_AUTHORIZED_TO_IMPERSONATE,
					function () {
						window.location = window.location.href.split("?")[0];
					}
				);
			},
			403: function () {
				dialog.openDialog(CONSTANTS.DIALOG.ACCOUNT_NOT_ACTIVATED);
			},
			422: function () {
				window.addressSearch.enableSearchButton();
				dialog.openDialog(CONSTANTS.DIALOG.GEOCODE_TRANSACTION_ERROR);
			},
			429: function () {
				dialog.openDialog(CONSTANTS.DIALOG.RATE_LIMITED_GEOCODE);
			}
		}
	});
};

module.exports = GeocodeClient;
