const ClientRenderedOverlay = require("./client-rendered-overlay");

class ClientRenderedOverlayFactory {
	constructor(mapController, wfsClient, dataView) {
		this.mapController = mapController;
		this.wfsClient = wfsClient;
		this.dataView = dataView;
	}

	make(
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId
	) {
		return new ClientRenderedOverlay(
			this.mapController,
			reportId,
			reportConfigurationModel,
			clickDetection,
			overlayConfiguration,
			baseURL,
			layerId,
			domId,
			this.wfsClient,
			this.dataView
		);
	}
}

module.exports = ClientRenderedOverlayFactory;
