const utils = require("../utils/index.js");
const CONSTANTS = require("../../../../RuntimeService/lib/index");
const dialog = require("../dialog/index");
class AccountClient {
	constructor() {
		let origin = location.protocol + "//" + location.host;
		this.origin = location.origin || origin;
		this.token =
			utils.getAuthTokenFromQueryParam() ||
			utils.getCookie("token") ||
			utils.getCookie("key");

		$.ajaxSetup({
			xhrFields: { withCredentials: true },
			headers: {
				"x-auth-key": this.token,
				source: "website"
			}
		});
	}

	createSibling(data, error, success) {
		var origin = this.origin;

		$.ajax({
			type: "POST",
			url: origin + "/account/create-sibling",
			data: data,
			statusCode: {
				200: function (data) {
					success({
						success: true,
						data: `<p>A new user account has been created for ${data.firstName} ${data.lastName} and an email has been sent alerting them to their new account with instructions for logging in.</p><p>Please contact our support team with any questions.</p>`
					});
				},
				400: function () {
					error({
						success: false,
						error:
							"Please supply a First Name, Last Name, and Email address to proceed."
					});
				},
				403: function () {
					error({
						success: false,
						error:
							"You are not authorized to create a new account for your organization.  Please contact your system administrators for more information."
					});
				},
				409: function () {
					error({
						success: false,
						error:
							"A user with that e-mail address already exists.  Please try a different address."
					});
				},
				500: function () {
					error({
						success: false,
						error:
							"There was an error creating your account. Please contact <a href='mailto:support@maprisk.com'>support@maprisk.com</a> for assistance."
					});
				}
			}
		});
	}

	getNumCredits(callback) {
		var origin = this.origin;
		var data = {};

		$.ajax({
			type: "GET",
			url: origin + "/account/numCredits",
			data: data,
			success: function (data) {
				callback(null, data);
			},
			error: function (err) {
				callback(err, null);
			}
		});
	}

	login(data, error, success) {
		var origin = this.origin;

		$.ajax({
			type: "POST",
			url: origin + "/account/login",
			data: data,
			success: success,
			error: error,
			statusCode: {
				200: function () {
					location.reload();
				},
				302: function (responseObject, textStatus, jqXHR) {
					location.replace(responseObject.responseJSON.tenantRedirect);
				},
				401: function () {
					dialog.openDialog(CONSTANTS.DIALOG.INVALID_CREDENTIALS);
				},
				403: function () {
					dialog.openDialog(CONSTANTS.DIALOG.ACCOUNT_NOT_ACTIVATED);
				},
				429: function () {
					dialog.openDialog(CONSTANTS.DIALOG.RATE_LIMITED_LOGIN);
				}
			}
		});
	}

	logOut(error, success) {
		var origin = this.origin;
		var data = {};

		$.ajax({
			type: "GET",
			url: origin + "/account/logout",
			data: data,
			cache: false,
			success: success,
			error: error
		});
	}

	async updateContact(data, error, success) {
		var origin = this.origin;

		let csrfTokenResponse = await fetch("/account/csrf-token");

		const { token } = await csrfTokenResponse.json();

		$.ajax({
			type: "POST",
			url: origin + "/account/updateContact",
			data: data,
			headers: {
				"x-csrf-token": token
			},
			success: success,
			error: error
		});
	}

	async updateSibling(data, error, success) {
		var origin = this.origin;

		let csrfTokenResponse = await fetch("/account/csrf-token");

		const { token } = await csrfTokenResponse.json();

		$.ajax({
			type: "POST",
			url:
				origin + `/account/update-sibling/${data.accountId}/${data.contactId}`,
			data: data,
			headers: {
				"x-csrf-token": token
			},
			statusCode: {
				200: function (data) {
					success({
						success: true,
						data: `<p>User information has been updated.</p>`
					});
				},
				400: function () {
					error({
						success: false,
						error: "Please supply a First Name and Last Name to proceed."
					});
				},
				401: function () {
					error({
						success: false,
						error:
							'Something went wrong.  Please contact <a href="mailto:support@maprisk.com"> for more information.'
					});
				},
				403: function () {
					error({
						success: false,
						error:
							"You are not authorized to update this user's information.  Please contact your system administrators for more information."
					});
				}
			}
		});
	}

	async deactivateUserById(data, error, success) {
		var origin = this.origin;

		let csrfTokenResponse = await fetch("/account/csrf-token");

		const { token } = await csrfTokenResponse.json();

		$.ajax({
			type: "POST",
			url: origin + `/account/deactivateUserById/${data.accountId}`,
			data: data,
			headers: {
				"x-csrf-token": token
			},
			statusCode: {
				200: function (data) {
					success({
						success: true,
						data: `<p>User has been deleted.</p>`
					});
				},
				400: function () {
					error({
						success: false,
						error:
							'Something went wrong.  Please contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> for more information.'
					});
				},
				401: function () {
					error({
						success: false,
						error:
							'Something went wrong.  Please contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> for more information.'
					});
				},
				403: function () {
					error({
						success: false,
						error:
							"You are not authorized to delete this user.  Please contact your system administrators for more information."
					});
				},
				500: function () {
					error({
						success: false,
						error:
							'Something went wrong.  Please contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> for more information.'
					});
				}
			}
		});
	}

	requestPasswordReset(data, error) {
		var origin = this.origin;

		$.ajax({
			type: "POST",
			url: origin + "/account/request-password-reset",
			data: data,
			statusCode: {
				200: function () {
					dialog.openDialog(
						CONSTANTS.DIALOG.CHECK_INBOX_FOR_PW_RESET_LINK,
						function () {
							window.location = "/";
						}
					);
				},
				403: function () {
					dialog.openDialog(
						CONSTANTS.DIALOG.ACCOUNT_NOT_ACTIVATED,
						function () {
							window.location = "/";
						}
					);
				},
				404: function () {
					dialog.openDialog(CONSTANTS.DIALOG.ACCOUNT_NOT_FOUND);
				}
			}
		});
	}

	purchaseCredits(data, callback, error) {
		var origin = this.origin;

		$.ajax({
			type: "POST",
			url: origin + "/account/purchaseCredits",
			data: data,
			success: function (response) {
				callback(response.data.purchaseCreditsResult);
			},
			error: error,
			statusCode: {
				422: function () {
					dialog.openDialog(CONSTANTS.DIALOG.CREDIT_PURCHASE_ERROR);
				}
			}
		});
	}

	submitNewPassword(data, error, success) {
		var origin = this.origin;

		$.ajax({
			type: "POST",
			url: origin + "/account/submit-new-password",
			data: data,
			success: success,
			error: error,
			statusCode: {
				200: function () {
					dialog.openDialog(
						CONSTANTS.DIALOG.PASSWORD_RESET_SUCCESS,
						function () {
							window.location = "/account/login";
						}
					);
				},
				400: function () {
					dialog.openDialog(CONSTANTS.DIALOG.SUBMIT_NEW_PASSWORD_ERROR);
				},
				403: function () {
					dialog.openDialog(CONSTANTS.DIALOG.PASSWORD_SECURITY_ERROR);
				},
				404: function () {
					dialog.openDialog(CONSTANTS.DIALOG.ACCOUNT_NOT_FOUND);
				},
				422: function () {
					dialog.openDialog(CONSTANTS.DIALOG.SUBMIT_NEW_PASSWORD_ERROR);
				},
				428: function () {
					dialog.openDialog(CONSTANTS.DIALOG.PASSWORD_RESET_REQUEST_ERROR);
				}
			}
		});
	}

	getSiblings(callback) {
		var origin = this.origin;

		let data = {};

		if (this.token) {
			data.token = this.token;
		}

		$.ajax({
			type: "GET",
			url: origin + "/account/get-siblings",
			data: data,
			success: function (data) {
				callback(null, data);
			},
			error: function (err) {
				callback(err, null);
			}
		});
	}

	async fetchApiKeys(error, success) {
		var origin = this.origin;

		let csrfTokenResponse = await fetch("/account/csrf-token");
		const { token } = await csrfTokenResponse.json();

		$.ajax({
			type: "GET",
			headers: {
				"x-csrf-token": token
			},
			url: origin + "/account/keys/",
			statusCode: {
				200: function (res) {
					success({
						success: true,
						data: res.data
					});
				},
				401: function () {
					error({
						success: false,
						error:
							'Something went wrong.  Please contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> for more information.'
					});
				},
				500: function () {
					error({
						success: false,
						error:
							'Something went wrong.  Please contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> for more information.'
					});
				}
			}
		});
	}

	async addApiKey(data, error, success) {
		var origin = this.origin;

		let csrfTokenResponse = await fetch("/account/csrf-token");
		const { token } = await csrfTokenResponse.json();

		$.ajax({
			type: "POST",
			data: data,
			headers: {
				"x-csrf-token": token
			},
			url: origin + "/account/key/",
			statusCode: {
				200: function (data) {
					success({
						success: true,
						data: `<p>New API key added to account.</p>`
					});
				},
				400: function () {
					error({
						success: false,
						error:
							'Something went wrong.  Please contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> for more information.'
					});
				},
				401: function () {
					error({
						success: false,
						error:
							'Something went wrong.  Please contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> for more information.'
					});
				},
				403: function () {
					error({
						success: false,
						error:
							"You are not authorized to get a new API key.  Please contact your system administrators for more information."
					});
				},
				500: function () {
					error({
						success: false,
						error:
							'Something went wrong.  Please contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> for more information.'
					});
				}
			}
		});
	}

	async revokeApiKey(data, error, success) {
		var origin = this.origin;

		let csrfTokenResponse = await fetch("/account/csrf-token");
		const { token } = await csrfTokenResponse.json();

		$.ajax({
			type: "POST",
			headers: {
				"x-csrf-token": token
			},
			data: data,
			url: origin + `/account/revokeKey`,
			statusCode: {
				200: function (res) {
					success({
						success: true,
						data: res.data
					});
				},
				401: function () {
					error({
						success: false,
						error:
							'Something went wrong.  Please contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> for more information.'
					});
				},
				500: function () {
					error({
						success: false,
						error:
							'Something went wrong.  Please contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> for more information.'
					});
				}
			}
		});
	}
}

module.exports = AccountClient;
