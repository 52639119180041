const LayerViewModelBase = require("./layer-view-model-base.js");
const MapriskLayerModel = require("../../model/models/maprisk-layer-model.js");
const ArchRefacLayerModel = require("../../model/models/arch-refac-layer-model.js");

class ArchRefacLayerViewModel extends LayerViewModelBase {
	constructor(
		eventBus,
		mapController,
		clickDetection,
		report,
		tooltips,
		$parentContainer,
		wfsClient,
		reportConfigurationModel,
		overlayFactory,
		dataView
	) {
		super(
			eventBus,
			mapController,
			clickDetection,
			report,
			tooltips,
			$parentContainer,
			wfsClient,
			reportConfigurationModel,
			overlayFactory,
			dataView
		);

		let self = this;

		this.bind($parentContainer);

		this.report.layers.forEach((layer) => {
			let lm;

			if (
				layer?.enableClick === true &&
				(layer?.clickDataSource === "details&wfs" ||
					layer?.clickDataSource === "details")
			) {
				lm = new ArchRefacLayerModel(
					this.mapController,
					this.reportConfigurationModel,
					this.clickDetection,
					this.report,
					layer,
					null,
					this.wfsClient,
					this.overlayFactory,
					this.dataView
				);
			} else {
				lm = new MapriskLayerModel(
					this.mapController,
					this.reportConfigurationModel,
					this.clickDetection,
					this.report,
					layer,
					null,
					this.wfsClient,
					this.overlayFactory,
					this.dataView
				);
			}

			self.addLayers(lm);
		});
	}

	bind($el) {
		super.bind($el);
	}

	unbind() {
		super.unbind();
	}

	addLayers(layers) {
		super.addLayers(layers);
	}

	addLayer(layer) {
		super.addLayer(layer);
	}

	removeLayer(layer) {
		super.removeLayer(layer);
	}
}

module.exports = ArchRefacLayerViewModel;
