class MercatorProjection {
	constructor() {
		const MERCATOR_RANGE = 256;

		this.pixelOrigin_ = new google.maps.Point(
			MERCATOR_RANGE / 2,
			MERCATOR_RANGE / 2
		);

		this.pixelsPerLonDegree_ = MERCATOR_RANGE / 360;
		this.pixelsPerLonRadian_ = MERCATOR_RANGE / (2 * Math.PI);
	}

	bound(value, opt_min, opt_max) {
		if (opt_min != null) value = Math.max(value, opt_min);
		if (opt_max != null) value = Math.min(value, opt_max);

		return value;
	}

	degreesToRadians(deg) {
		return deg * (Math.PI / 180);
	}

	radiansToDegrees(rad) {
		return rad / (Math.PI / 180);
	}

	fromLatLngToPoint(latLng, opt_point) {
		let self = this;

		let point = opt_point || new google.maps.Point(0, 0);

		let origin = self.pixelOrigin_;

		point.x = origin.x + latLng.lng() * self.pixelsPerLonDegree_;
		// NOTE(appleton): Truncating to 0.9999 effectively limits latitude to
		// 89.189.  This is about a third of a tile past the edge of the world tile.
		let siny = self.bound(
			Math.sin(self.degreesToRadians(latLng.lat())),
			-0.9999,
			0.9999
		);

		point.y =
			origin.y +
			0.5 * Math.log((1 + siny) / (1 - siny)) * -self.pixelsPerLonRadian_;

		return point;
	}

	fromDivPixelToLatLng(pixel, zoom) {
		let self = this;

		let origin = self.pixelOrigin_;
		let scale = Math.pow(2, zoom);
		let lng = (pixel.x / scale - origin.x) / self.pixelsPerLonDegree_;
		let latRadians = (pixel.y / scale - origin.y) / -self.pixelsPerLonRadian_;
		let lat = self.radiansToDegrees(
			2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2
		);

		return new google.maps.LatLng(lat, lng);
	}

	fromDivPixelToSphericalMercator(pixel, zoom) {
		let self = this;
		let coord = self.fromDivPixelToLatLng(pixel, zoom);

		let r = 6378137.0;
		let x = r * self.degreesToRadians(coord.lng());
		let latRad = self.degreesToRadians(coord.lat());
		let y = (r / 2) * Math.log((1 + Math.sin(latRad)) / (1 - Math.sin(latRad)));

		return new google.maps.Point(x, y);
	}
}

module.exports = MercatorProjection;
