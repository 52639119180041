class EventBus {
	constructor() {
		if (!!EventBus.instance) {
			return EventBus.instance;
		}

		this.subscriptions = {};

		EventBus.instance = this;
		return this;
	}

	subscribe(eventName, callback) {
		const id = Symbol();

		if (!this.subscriptions[eventName]) {
			this.subscriptions[eventName] = {};
		}

		this.subscriptions[eventName][id] = callback;

		return {
			unsubscribe: () => {
				delete this.subscriptions[eventName];

				if (Reflect.ownKeys(this.subscriptions[eventName]).length === 0) {
					delete this.subscriptions[eventName];
				}
			}
		};
	}

	publish(eventName, args) {
		if (!this.subscriptions[eventName]) {
			return;
		}

		Reflect.ownKeys(this.subscriptions[eventName]).forEach((id) => {
			this.subscriptions[eventName][id](args);
		});
	}
}

module.exports = EventBus;
