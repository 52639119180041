const ServerRenderedProviderBase = require("./server-rendered-provider-base");
const {
	buildDerivedLayerParams,
	buildGeoserverParams,
	MercatorProjection
} = require("../../../../utils");

class GoogleMapsWMTSImageMapTypeOverlay extends ServerRenderedProviderBase {
	constructor(
		mapController,
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId
	) {
		super(
			mapController,
			reportId,
			reportConfigurationModel,
			clickDetection,
			overlayConfiguration,
			baseURL,
			layerId,
			domId
		);

		this.baseURL = this.mapController.TILE_SERVER_WMTS_PATH;

		let requestParams = [
			"LAYER=" + this.layerId,
			"TILEMATRIXSET=EPSG:" + this.layerProjection,
			"SERVICE=WMTS",
			"REQUEST=GetTile",
			"VERSION=1.0.0",
			"FORMAT=image/png",
			"WIDTH=" + this.tileWidth,
			"HEIGHT=" + this.tileHeight,
			"BGCOLOR=0xFFFFFF",
			"TRANSPARENT=TRUE"
		];

		if (this.overlayConfiguration.color) {
			this.geoserverEnv =
				"color:" + this.overlayConfiguration.color.replace("#", "");
		}

		if (this.overlayConfiguration.size) {
			if (this.geoserverEnv.length > 0) {
				this.geoserverEnv += ";";
				this.geoserverEnv += "size:" + this.overlayConfiguration.size;
			}
		}

		if (this.geoserverEnv.length > 0) {
			requestParams.push("env=" + this.geoserverEnv);
		}

		this.requestParams = requestParams;

		this.overlay = this.getOverlay();
	}

	getOverlay() {
		let self = this;

		let overlayOptions = {
			getTileUrl: function (coord, zoom) {
				let requestParams = self.requestParams.slice();
				let urlResult = "";
				let bboxString = "";
				let paramString = "";

				if (
					self.overlayConfiguration.layerProjection &&
					self.overlayConfiguration.layerProjection == 4326
				) {
					let scale = Math.pow(2, zoom);

					let southWestPoint = self.map
						.getProjection()
						.fromPointToLatLng(
							new google.maps.Point(
								(coord.x * self.tileWidth) / scale,
								((coord.y + 1) * self.tileHeight) / scale
							)
						);
					let northEastPoint = self.map
						.getProjection()
						.fromPointToLatLng(
							new google.maps.Point(
								((coord.x + 1) * self.tileWidth) / scale,
								(coord.y * self.tileHeight) / scale
							)
						);

					bboxString =
						"&bbox=" +
						new google.maps.LatLngBounds(
							southWestPoint,
							northEastPoint
						).toUrlValue();
				} else {
					let projectionMap = new MercatorProjection();

					let lULP = new google.maps.Point(
						coord.x * self.tileWidth,
						(coord.y + 1) * self.tileHeight
					);
					let lLRP = new google.maps.Point(
						(coord.x + 1) * self.tileWidth,
						coord.y * self.tileHeight
					);

					let lULg = projectionMap.fromDivPixelToSphericalMercator(lULP, zoom);
					let lLRg = projectionMap.fromDivPixelToSphericalMercator(lLRP, zoom);

					let UlLatLng = projectionMap.fromDivPixelToLatLng(lULP, zoom);
					let LRLatLng = projectionMap.fromDivPixelToLatLng(lLRP, zoom);

					let lUL_Latitude = lULg.y;
					let lUL_Longitude = lULg.x;
					let lLR_Latitude = lLRg.y;
					let lLR_Longitude = lLRg.x;

					bboxString =
						"&bbox=" +
						lUL_Longitude +
						"," +
						lUL_Latitude +
						"," +
						lLR_Longitude +
						"," +
						lLR_Latitude;

					let center = {
						lat: (UlLatLng.lat() + LRLatLng.lat()) / 2,
						lng: (UlLatLng.lng() + LRLatLng.lng()) / 2
					};
					let distance = self.mapController.getDistance(
						self.mapController.latLngPoi,
						center
					);

					if (
						distance > 4828.03 &&
						(self.reportId == "crime" ||
							self.reportId == "stormsurge" ||
							self.reportId == "slosh" ||
							self.reportId == "floodRiskScore" ||
							self.reportId == "floodRatingFEMA")
					) {
						return null;
					}
				}

				let layerStyle = self.overlayConfiguration.style || null;

				if (layerStyle) {
					requestParams.push("STYLES=" + layerStyle);
				}

				if (self?.mapController?.currentPoi) {
					paramString = buildGeoserverParams(
						self.reportId,
						self.overlayConfiguration.namespace,
						self.overlayConfiguration.name,
						self.reportConfigurationModel,
						self.mapController
					).wms;

					// this is specifically for "derived" layers that are dynamically created by model input choices
					if (self.overlayConfiguration.filterFields) {
						paramString = buildDerivedLayerParams(
							self.reportId,
							self.overlayConfiguration
						).wms;
					}
				}

				urlResult = `
				${self.baseURL}${requestParams.join("&")}&tileMatrix=EPSG:${
					self.layerProjection
				}:${zoom}&tileCol=${coord.x}&tileRow=${coord.y}${paramString}`;

				if (this?.overlayConfiguration?.getDate?.()) {
					urlResult += "&nmd=" + self.overlayConfiguration.getDate();
				}

				return urlResult;
			},
			tileSize: new google.maps.Size(self.tileWidth, self.tileHeight),
			minZoom: self.minZoom,
			maxZoom: self.maxZoom,
			opacity: self.opacity,
			name: self.domId
		};

		return new google.maps.ImageMapType(overlayOptions);
	}
}

module.exports = GoogleMapsWMTSImageMapTypeOverlay;
