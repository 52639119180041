const utils = require("../utils/index.js");

var mrMapTypeControl = function () {
	var self = this;

	self.options = {
		containerBaseClass: "mr-map-controls-container",
		containerClassNames: "",
		containerDataType: "map-type",
		containerTitle: "Change the base map layer",
		innerContainerBaseClass: "mr-map-controls-container-inner",
		innerContainerClassNames: "",
		triggerBaseClass: "mr-map-controls-trigger",
		triggerClassNames: "",
		triggerContentBaseClass: "mr-map-controls-trigger-content",
		triggerContentType: "text",
		triggerContentHTML: "Map Types",
		dropdownContainerBaseClass: "mr-map-controls-dropdown",
		dropdownContainerClassNames: "",
		optionsContainerBaseClass: "mr-map-controls-options",
		optionsContainerClassNames: "",
		optionWrapperBaseClass: "mr-map-controls-option-wrapper",
		optionWrapperClassNames: "",
		optionBaseClass: "mr-map-controls-option",
		optionClassNames: "",
		default: "roadmap"
	};

	self.stateModel = {
		mapTypes: {
			roadmap: {
				displayName: "Road Map",
				enum: "ROADMAP"
			},
			terrain: {
				displayName: "Terrain",
				enum: "TERRAIN"
			},
			hybrid: {
				displayName: "Hybrid",
				enum: "HYBRID"
			}
		},
		currentMapType: null,
		active: false,
		visible: true,
		enabled: true
	};

	self.state = JSON.parse(JSON.stringify(self.stateModel));

	self.$mapControlContainer = $("<div></div>");
	self.$mapControlInnerContainer = $("<div></div>");
	self.$mapControlTrigger = $("<div></div>");
	self.$mapControlTriggerContent = $("<span></span>");
	self.$mapControlDropdownContainer = $("<div></div>");
	self.$mapControlOptionsContainer = $("<div></div>");

	self.init = function (state, mapController, options) {
		if (!mapController) {
			console.log("MapTypeControl error; no mapController specified");

			return;
		}

		if (options) {
			self.options = utils.extend.deepmerge(self.options, options);
		}

		if (!self.options.parentContainer) {
			console.log(
				"MapTypeControl error: no parent container defined in options."
			);

			return;
		}

		if (!self.options.id) {
			console.log("MapTypeControl error: no id specified.");

			return;
		}

		if ($("#" + self.options.id).length <= 0) {
			self.mapController = mapController;
			self.create();
		} else {
			console.log(
				"MapTypeControl error: Cannot create duplicate MapTypeControl container."
			);

			return;
		}
	};

	self.create = function () {
		self.$mapControlContainer.attr("id", self.options.id);
		self.$mapControlContainer.addClass(
			self.options.containerBaseClass + " " + self.options.containerClassNames
		);
		self.$mapControlContainer.attr("data-type", self.options.containerDataType);
		self.$mapControlContainer.attr("title", self.options.containerTitle);

		self.$mapControlInnerContainer.addClass(
			self.options.innerContainerBaseClass +
				" " +
				self.options.innerContainerClassNames
		);

		self.$mapControlTrigger.addClass(
			self.options.triggerBaseClass + " " + self.options.triggerClassNames
		);

		self.$mapControlTriggerContent.addClass(
			self.options.triggerContentBaseClass
		);
		self.$mapControlTriggerContent.html(self.options.triggerContentHTML);

		self.$mapControlDropdownContainer.addClass(
			self.options.dropdownContainerBaseClass +
				" " +
				self.options.dropdownContainerClassNames
		);

		self.$mapControlOptionsContainer.addClass(
			self.options.optionsContainerBaseClass +
				" " +
				self.options.optionsContainerClassNames
		);

		self.$mapControlDropdownContainer.append(self.$mapControlOptionsContainer);

		self.$mapControlTrigger.append(self.$mapControlTriggerContent);

		self.$mapControlInnerContainer.append(self.$mapControlTrigger);
		self.$mapControlContainer.append(self.$mapControlInnerContainer);
		self.$mapControlContainer.append(self.$mapControlDropdownContainer);

		$(self.options.parentContainer).append(self.$mapControlContainer);

		self.mapTypeControlOptions = [];
		for (var el in self.state.mapTypes) {
			var $optionWrapper = $("<div></div>");
			$optionWrapper.addClass(
				self.options.optionWrapperBaseClass +
					" " +
					self.options.optionWrapperClassNames
			);

			var $mapControl = $(
				"<div class='" +
					self.options.optionBaseClass +
					" " +
					self.options.optionClassNames +
					"' data-enum='" +
					self.state.mapTypes[el].enum +
					"' data-type='" +
					el +
					"'>" +
					self.state.mapTypes[el].displayName +
					"</div>"
			);

			$optionWrapper.append($mapControl);
			self.$mapControlOptionsContainer.append($optionWrapper);
			self.mapTypeControlOptions.push($mapControl);
		}

		self.attachTriggerClick();
		self.attachOptionClick();

		if (self.options.default) {
			self.setMapType(self.options.default);
		}
	};

	self.destroy = function () {
		self.$mapControlContainer.remove();
	};

	self.enable = function () {
		self.state.enabled = true;
		self.$mapControlContainer.removeClass("disabled");
	};

	self.disable = function () {
		self.state.enabled = false;
		self.$mapControlContainer.addClass("disabled");
	};

	self.attachTriggerClick = function () {
		self.$mapControlTrigger.on("click", function (e) {
			e.preventDefault();

			if (self.state.enabled === true) {
				if (self.$mapControlContainer.hasClass("active")) {
					self.state.active = false;
					self.$mapControlContainer.removeClass("active");
				} else {
					self.state.active = true;
					self.$mapControlContainer.addClass("active");
				}
			}
		});
	};

	self.attachOptionClick = function () {
		self.mapTypeControlOptions.forEach(function ($el) {
			$el.on("click", function (e) {
				e.preventDefault();
				self.setMapType($(this).attr("data-enum"));
			});
		});
	};

	self.setMapType = function (mapType) {
		var $option;
		mapType = mapType.toUpperCase();

		self.mapTypeControlOptions.forEach(function ($el) {
			var optionEnum = $el.attr("data-enum");
			if (optionEnum === mapType) {
				$option = $el;
			} else {
				$el.removeClass("active");
			}
		});

		if ($option && !$option.hasClass("active")) {
			$option.addClass("active");
			self.mapController.map.setMapTypeId(google.maps.MapTypeId[mapType]);
		}
	};
};

module.exports = mrMapTypeControl;
