const utils = require("../../utils/index.js");

class ImageryTiles {
	constructor(mapController, params) {
		this.origin = location.protocol + "//" + location.host;
		this.mapController = mapController;
		this.width = params && params.width ? params.width : 256;
		this.height = params && params.height ? params.height : 256;
		this.opacity = params && params.opacity ? params.opacity : 1;
		this.path = "/imagery/tile";
	}

	getTiles(params, zoomLevels) {
		var self = this;
		var queryParams = [];
		var queryString = "";

		for (var param in params) {
			queryParams.push(param + "=" + params[param]);
		}

		queryString = queryParams.join("&");

		if (queryParams.length > 0) {
			queryString = "?" + queryString;
		}

		var overlay = new google.maps.ImageMapType({
			getTileUrl: function (coord, zoom) {
				var scale = Math.pow(2, zoom);
				var tileSize = self.width;

				var worldCoord = utils.pixelCoordToWorldCoord(coord, scale, tileSize);

				var tileCoord = new google.maps.Point(
					Math.floor((worldCoord.x * scale) / tileSize),
					Math.floor((worldCoord.y * scale) / tileSize)
				);

				var url =
					self.origin +
					self.path +
					queryString +
					"&x=" +
					coord.x +
					"&y=" +
					coord.y +
					"&tileX=" +
					tileCoord.x +
					"&tileY=" +
					tileCoord.y +
					"&zoom=" +
					zoom;

				return url;
			},
			tileSize: new google.maps.Size(this.width, this.height),
			minZoom: Math.min.apply(null, zoomLevels),
			maxZoom: Math.max.apply(null, zoomLevels),
			opacity: this.opacity
		});

		overlay.namespace = "highres";
		overlay.name = params.provider;
		overlay.type = "tile";
		overlay.id = params.id;

		return overlay;
	}

	project(latLng, tileSize) {
		var siny = Math.sin((latLng.lat() * Math.PI) / 180);

		// Truncating to 0.9999 effectively limits latitude to 89.189. This is
		// about a third of a tile past the edge of the world tile.
		siny = Math.min(Math.max(siny, -0.9999), 0.9999);

		return new google.maps.Point(
			tileSize * (0.5 + latLng.lng() / 360),
			tileSize * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
		);
	}
}

module.exports = ImageryTiles;
