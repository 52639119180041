const utils = require("../utils/index.js");

var mrMapDrawingToolsControl = function () {
	var self = this;

	self.options = {
		containerBaseClass: "mr-map-controls-container",
		containerClassNames: "",
		containerDataType: "drawing-tools",
		containerTitle: "View map drawing tools",
		innerContainerBaseClass: "mr-map-controls-container-inner",
		innerContainerClassNames: "",
		triggerBaseClass: "mr-map-controls-trigger",
		triggerClassNames: "",
		triggerContentBaseClass: "mr-map-controls-trigger-content",
		triggerContentType: "text",
		triggerContentHTML: "Drawing Tools",
		dropdownContainerBaseClass: "mr-map-controls-dropdown",
		dropdownContainerClassNames: "",
		optionBaseClass: "mr-map-controls-option",
		optionClassNames: ""
	};

	self.stateModel = {
		currentTools: {},
		activeTool: null,
		active: false,
		visible: true,
		drawingDisabled: {
			status: false,
			disabledAttemptFunc: null,
			disabledAttemptFuncArgs: []
		},
		overlays: {}
	};

	self.state = JSON.parse(JSON.stringify(self.stateModel));

	self.$mapControlContainer = $("<div></div>");
	self.$mapControlInnerContainer = $("<div></div>");
	self.$mapControlTrigger = $("<div></div>");
	self.$mapControlTriggerContent = $("<span></span>");
	self.$mapControlDropdownContainer = $("<div></div>");

	self.init = function (mapController, options) {
		if (!mapController) {
			console.log("mapDrawingToolsControl error; no mapController specified");

			return;
		}

		if (options) {
			self.options = utils.extend.deepmerge(self.options, options);
		}

		if (!self.options.parentContainer) {
			console.log(
				"mapDrawingToolsControl error: no parent container defined in options."
			);

			return;
		}

		if (!self.options.id) {
			console.log("mapDrawingToolsControl error: no id specified.");

			return;
		}

		if ($("#" + self.options.id).length <= 0) {
			self.mapController = mapController;
			self.create();
		} else {
			console.log(
				"mapDrawingToolsControl error: Cannot create duplicate mapDrawingToolsControl container."
			);

			return;
		}
	};

	self.create = function () {
		self.$mapControlContainer.attr("id", self.options.id);
		self.$mapControlContainer.addClass(
			self.options.containerBaseClass + " " + self.options.containerClassNames
		);
		self.$mapControlContainer.attr("data-type", self.options.containerDataType);
		self.$mapControlContainer.attr("title", self.options.containerTitle);

		self.$mapControlInnerContainer.addClass(
			self.options.innerContainerBaseClass +
				" " +
				self.options.innerContainerClassNames
		);

		self.$mapControlTrigger.addClass(
			self.options.triggerBaseClass + " " + self.options.triggerClassNames
		);

		self.$mapControlTriggerContent.addClass(
			self.options.triggerContentBaseClass
		);
		self.$mapControlTriggerContent.html(self.options.triggerContentHTML);

		self.$mapControlDropdownContainer.addClass(
			self.options.dropdownContainerBaseClass +
				" " +
				self.options.dropdownContainerClassNames
		);

		self.$mapControlTrigger.append(self.$mapControlTriggerContent);

		self.$mapControlInnerContainer.append(self.$mapControlTrigger);
		self.$mapControlContainer.append(self.$mapControlInnerContainer);
		self.$mapControlContainer.append(self.$mapControlDropdownContainer);

		$(self.options.parentContainer).append(self.$mapControlContainer);

		self.attachTriggerClick();
	};

	self.destroy = function () {
		self.$mapControlContainer.remove();
	};

	self.enable = function () {
		self.activateDrawingToolByType("pan");

		for (var tool in self.state.currentTools) {
			self.state.currentTools[tool].$control.removeClass("disabled");
		}

		self.state.drawingDisabled = {
			status: false,
			disabledAttemptFunc: null,
			disabledAttemptFuncArgs: []
		};
	};

	self.disable = function (disabledAttemptFunc, disabledAttemptFuncArgs) {
		self.activateDrawingToolByType("pan");

		for (var tool in self.state.currentTools) {
			self.state.currentTools[tool].$control.addClass("disabled");
		}

		self.state.drawingDisabled = {
			status: true,
			disabledAttemptFunc: disabledAttemptFunc,
			disabledAttemptFuncArgs: disabledAttemptFuncArgs
		};
	};

	self.addTool = function (tool) {
		var $mapControl = $(
			"<div class='" +
				self.options.optionBaseClass +
				" " +
				self.options.optionClassNames +
				" " +
				tool.classNames +
				"' data-type='" +
				tool.type +
				"' id='" +
				tool.id +
				"' title='" +
				tool.title +
				"'></div>"
		);
		var $mapControlIcon = $("<i class='" + tool.iconClassNames + "'></i>");
		$mapControl.append($mapControlIcon);
		self.$mapControlDropdownContainer.append($mapControl);

		tool.$control = $mapControl;

		if (tool.active && tool.active === true) {
			$mapControl.addClass("active");
		}

		self.attachOptionClick(tool);
		self.state.currentTools[tool.type] = tool;
	};

	self.attachTriggerClick = function () {
		self.$mapControlTrigger.on("click", function (e) {
			e.preventDefault();

			if (self.state.drawingDisabled.status === false) {
				if (self.$mapControlContainer.hasClass("active")) {
					self.state.active = false;
					self.$mapControlContainer.removeClass("active");
				} else {
					self.state.active = true;
					self.$mapControlContainer.addClass("active");
				}
			}
		});
	};

	self.attachOptionClick = function (tool) {
		tool.$control.on("click", function (e) {
			e.preventDefault();

			self.activateDrawingToolByType(tool.type);
			self.mapController.drawingManager.setDrawingMode(tool.drawingMode);
			self.state.activeTool = tool.type;
		});
	};

	self.activateDrawingToolByType = function (type) {
		for (var tool in self.state.currentTools) {
			self.state.currentTools[tool].$control.removeClass("active");
		}

		self.state.currentTools[type].$control.addClass("active");
		self.mapController.drawingManager.setDrawingMode(
			type === "pan" ? null : type
		);
	};

	self.deactivateDrawingToolByType = function (type) {
		self.state.currentTools[type].$control.removeClass("active");
		self.mapController.drawingManager.setDrawingMode(
			type === "pan" ? null : type
		);
	};

	self.disableDrawingToolByType = function (type) {
		self.state.currentTools[type].$control.addClass("disabled");
		self.state.currentTools[type].$control.removeClass("active");
		self.mapController.drawingManager.setDrawingMode(null);
	};
};

module.exports = mrMapDrawingToolsControl;
