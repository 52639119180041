class MarkerModel {
	constructor(options) {
		this.options = options;
		this.marker = new google.maps.Marker(this.options);
	}

	getOpacity() {
		return this.marker.getOpacity();
	}

	setOpacity(opacity) {
		this.marker.setOpacity(opacity);
	}

	setMap(map) {
		this.marker.setMap(map);
	}

	addListener(type, callback) {
		return this.marker.addListener(type, callback);
	}
}

module.exports = MarkerModel;
