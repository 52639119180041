const utils = require("../utils/index.js");
function ImageryClient() {
	let origin = location.protocol + "//" + location.host;
	this.origin = location.origin || origin;
	this.token =
		utils.getAuthTokenFromQueryParam() ||
		utils.getCookie("token") ||
		utils.getCookie("key");

	$.ajaxSetup({
		xhrFields: { withCredentials: true },
		headers: {
			"x-auth-key": this.token,
			source: "website"
		}
	});
}

ImageryClient.prototype.addTokenToParams = function (params) {
	if (!params) {
		params = {};
	}

	params.token = this.token;

	return params;
};

ImageryClient.prototype.getCoverage = function (poi, params, promise) {
	var data = {
		poi: poi,
		params: params
	};

	$.ajax({
		type: "GET",
		url: this.origin + "/imagery/coverage",
		data: data,
		timeout: 600000,
		success: function (response) {
			promise.resolve(response);
		},
		error: function (err) {
			promise.reject(err);
		}
	});
};

ImageryClient.prototype.getImage = function (data, callback) {
	$.ajax({
		type: "GET",
		url: this.origin + "/imagery/image",
		data: data,
		timeout: 600000,
		success: function (response) {
			callback(null, response);
		},
		error: function (err) {
			callback(err, null);
		}
	});
};

ImageryClient.prototype.getTile = function (data, callback) {
	$.ajax({
		type: "GET",
		url: this.origin + "/imagery/tile",
		data: data,
		timeout: 600000,
		success: function (response) {
			callback(null, response);
		},
		error: function (err) {
			callback(err, null);
		}
	});
};

module.exports = ImageryClient;
