//  Constants
const DIALOG = {
	ACCOUNT_SETTINGS: 0,
	BATCH: 1,
	TERMS_OF_SERVICE: 2,
	WAIT_FOR_ACTIVATION: 3,
	INSUFFICIENT_FUNDS_GENERAL: 4,
	INSUFFICIENT_FUNDS_BATCH: 5,
	INSUFFICIENT_FUNDS_PDF: 6,
	LOCKED_UNTIL_PURCHASE: 7,
	ACCOUNT_ALREADY_EXISTS: 8,
	ACCOUNT_CREATE_ERROR_GENERIC: 9,
	CAPTCHA_VALIDATION_FAILURE: 10,
	INVALID_CREDENTIALS: 11,
	BATCH_DISABLED: 12,
	CHECK_INBOX_FOR_PW_RESET_LINK: 13,
	PASSWORD_RESET_REQUEST_ERROR: 14,
	PASSWORD_FIELDS_MUST_MATCH: 15,
	SUBMIT_NEW_PASSWORD_ERROR: 16,
	PASSWORD_RESET_SUCCESS: 17,
	PLEASE_ENTER_AN_ADDRESS: 18,
	BATCH_DISABLED_VIEW_ONLY: 19,
	ACCOUNT_SETTINGS_VIEW_ONLY: 20,
	ACCOUNT_NOT_ACTIVATED: 21,
	NOT_AUTHORIZED_TO_IMPERSONATE: 22,
	GEOCODE_ERROR: 23,
	NO_REPORT_DATA_UNABLE_TO_DOWNLOAD_PDF: 24,
	GEOCODE_SERVER_ERROR: 25,
	APPEND_REPORT_RESULT_ERROR: 26,
	BATCH_DELETE_ERROR: 27,
	GEOCODE_TRANSACTION_ERROR: 28,
	DRAG_MARKER_ERROR: 30,
	STREET_VIEW_ERROR: 31,
	INVALID_URL_LINK: 32,
	ACCOUNT_NOT_FOUND: 33,
	CREDIT_PURCHASE_ERROR: 34,
	BATCH_ERROR: 35,
	IE_GOOGLE_MAPS_DEPRECATION: 36,
	RATE_LIMITED_LOGIN: 37,
	PASSWORD_SECURITY_ERROR: 38,
	INVALID_PORTFOLIO_LINK: 39,
	RATE_LIMITED_GEOCODE: 40,
	RATE_LIMITED_REPORT: 41
};
module.exports = DIALOG;
