const { has } = require("lodash");

class UserSettingsInterface {
	constructor(accountClient, eventBus, company) {
		this.accountClient = accountClient;
		this.eventBus = eventBus;
		this.company = company;

		this.activeClass = "active";

		this.headingClassList = ["user-manager-user-settings-interface-heading"];
		this.headingText = "Edit User";

		this.containerId = "user-manager-user-settings-interface-container";
		this.containerClassList = [
			"user-manager-user-settings-interface-container"
		];

		this.closeButtonContainerClassList = [
			"user-manager-user-settings-interface-close-button-container"
		];

		this.closeButtonClassList = [
			"user-manager-user-settings-interface-close-button"
		];

		this.closeButtonIconClassList = ["icon-cancel"];

		this.backdropId = "user-manager-user-settings-interface-backdrop";
		this.backdropClassList = ["user-manager-user-settings-interface-backdrop"];

		this.formId = "user-manager-user-settings-interface-form";
		this.formClassList = ["user-manager-user-settings-interface-form"];
		this.formMethod = "POST";
		this.formAction = "";

		this.inputGroupClassList = [
			"user-manager-user-settings-interface-form-input-group"
		];

		this.accountSettingsFieldsetId =
			"user-manager-user-settings-interface-account-settings-fieldset";
		this.accountSettingsFieldsetClassList = [
			"user-manager-user-settings-interface-account-settings-fieldset"
		];

		this.hasBatchAccessCheckboxId =
			"user-manager-user-settings-interface-has-batch-access-checkbox";
		this.hasBatchAccessCheckboxClassList = [
			"user-manager-user-settings-interface-has-batch-access-checkbox"
		];
		this.hasBatchAccessCheckboxLabelText = "Batch Access";

		this.deleteUserButtonId =
			"user-manager-user-settings-interface-delete-user-button";
		this.deleteUserButtonClassList = [
			"user-manager-user-settings-interface-delete-user-button"
		];
		this.deleteUserButtonText = "Delete User";

		this.saveButtonContainerClassList = [
			"user-manager-user-settings-interface-save-button-container"
		];

		this.saveButtonId = "user-manager-user-settings-interface-save-button";
		this.saveButtonClassList = [
			"user-manager-user-settings-interface-save-button"
		];
		this.saveButtonText = "Save";

		this.messageClassList = ["user-manager-user-settings-interface-message"];
		this.messageText = "";

		this.createInterface();
	}

	isInterfaceCreated() {
		return document.getElementById(this.containerId) ? true : false;
	}

	createInterface() {
		if (this.isInterfaceCreated === true) {
			return;
		}

		let inputGroup = document.createElement("div");
		inputGroup.classList.add(...this.inputGroupClassList);

		this.backdrop = document.createElement("div");
		this.backdrop.id = this.backdropId;
		this.backdrop.classList.add(...this.backdropClassList);

		this.container = document.createElement("div");
		this.container.id = this.containerId;
		this.container.classList.add(...this.containerClassList);

		this.heading = document.createElement("h3");
		this.heading.classList.add(...this.headingClassList);
		this.heading.innerText = this.headingText;

		this.closeButtonContainer = document.createElement("div");
		this.closeButtonContainer.classList.add(
			...this.closeButtonContainerClassList
		);

		this.closeButton = document.createElement("a");
		this.closeButton.classList.add(...this.closeButtonClassList);

		this.closeButtonIcon = document.createElement("i");
		this.closeButtonIcon.classList.add(...this.closeButtonIconClassList);

		this.closeButton.append(this.closeButtonIcon);
		this.closeButtonContainer.append(this.closeButton);

		this.form = document.createElement("form");
		this.form.setAttribute("method", "POST");
		this.form.setAttribute("action", "");
		this.form.id = this.formId;
		this.form.classList.add(...this.formClassList);

		let firstNameInputGroup = inputGroup.cloneNode(true);
		let firstNameLabel = document.createElement("label");
		let firstNameInput = document.createElement("input");
		firstNameLabel.innerText = "First Name";
		firstNameInput.setAttribute("name", "firstName");
		firstNameInput.setAttribute("placeholder", "First Name");
		firstNameInputGroup.append(firstNameLabel);
		firstNameInputGroup.append(firstNameInput);
		this.form.append(firstNameInputGroup);

		let lastNameInputGroup = inputGroup.cloneNode(true);
		let lastNameLabel = document.createElement("label");
		let lastNameInput = document.createElement("input");
		lastNameLabel.innerText = "Last Name";
		lastNameInput.setAttribute("name", "lastName");
		lastNameInput.setAttribute("placeholder", "Last Name");
		lastNameInputGroup.append(lastNameLabel);
		lastNameInputGroup.append(lastNameInput);
		this.form.append(lastNameInputGroup);

		let accountIdInput = document.createElement("input");
		accountIdInput.type = "hidden";
		accountIdInput.setAttribute("name", "accountId");

		let contactIdInput = document.createElement("input");
		contactIdInput.type = "hidden";
		contactIdInput.setAttribute("name", "contactId");

		this.form.append(accountIdInput);
		this.form.append(contactIdInput);

		this.emailMessage = document.createElement("p");
		this.emailMessage.innerHTML =
			'Contact <a href="mailto:support@maprisk.com">support@maprisk.com</a> to change email / login username.';

		this.form.append(this.emailMessage);

		let accountSettingsFieldset = document.createElement("fieldset");
		accountSettingsFieldset.id = this.accountSettingsFieldsetId;
		accountSettingsFieldset.classList.add(
			...this.accountSettingsFieldsetClassList
		);

		let hasBatchAccessCheckboxInputGroup = inputGroup.cloneNode(true);
		hasBatchAccessCheckboxInputGroup.classList.add("inline");
		let hasBatchAccessCheckbox = document.createElement("input");
		hasBatchAccessCheckbox.id = this.hasBatchAccessCheckboxId;
		hasBatchAccessCheckbox.classList.add(
			...this.hasBatchAccessCheckboxClassList
		);

		hasBatchAccessCheckbox.setAttribute("type", "checkbox");
		hasBatchAccessCheckbox.setAttribute("name", "has-batch-access");

		let hasBatchAccessCheckboxLabel = document.createElement("label");
		hasBatchAccessCheckboxLabel.setAttribute(
			"for",
			this.hasBatchAccessCheckboxId
		);
		hasBatchAccessCheckboxLabel.innerText = this.hasBatchAccessCheckboxLabelText;

		hasBatchAccessCheckboxInputGroup.append(hasBatchAccessCheckbox);
		hasBatchAccessCheckboxInputGroup.append(hasBatchAccessCheckboxLabel);
		accountSettingsFieldset.append(hasBatchAccessCheckboxInputGroup);

		this.form.append(accountSettingsFieldset);

		this.deleteUserButton = document.createElement("button");
		this.deleteUserButton.id = this.deleteUserButtonId;
		this.deleteUserButton.setAttribute("type", "button");
		this.deleteUserButton.classList.add(...this.deleteUserButtonClassList);
		this.deleteUserButton.innerText = this.deleteUserButtonText;

		this.form.append(this.deleteUserButton);

		this.saveButtonContainer = document.createElement("div");
		this.saveButtonContainer.classList.add(
			...this.saveButtonContainerClassList
		);

		this.saveButton = document.createElement("button");
		this.saveButton.id = this.saveButtonId;
		this.saveButton.setAttribute("type", "button");
		this.saveButton.classList.add(...this.saveButtonClassList);

		this.saveButton.innerText = this.saveButtonText;

		this.saveButtonContainer.append(this.saveButton);
		this.form.append(this.saveButtonContainer);

		this.message = document.createElement("div");
		this.message.classList.add(this.messageClassList);

		this.message.innerText = this.messageText;

		this.container.append(this.heading);
		this.container.append(this.closeButtonContainer);
		this.container.append(this.form);
		this.container.append(this.message);

		document.body.append(this.backdrop);
		document.body.append(this.container);

		this.attachCloseButtonClickHandler();
		this.attachBackdropClickHandler();
		this.attachDeleteUserButtonHandler();
		this.attachFormSubmitHandler();
	}

	attachBackdropClickHandler() {
		let self = this;

		this.backdrop.addEventListener("click", (e) => {
			e.preventDefault();

			this.hideMessage();
			this.closeInterface();
		});
	}

	attachCloseButtonClickHandler() {
		let self = this;

		this.closeButton.addEventListener("click", (e) => {
			e.preventDefault();

			this.hideMessage();
			this.closeInterface();
		});
	}

	attachDeleteUserButtonHandler() {
		let self = this;

		this.deleteUserButton.addEventListener("click", async (e) => {
			e.preventDefault();

			let payload = {
				accountId: self.form.querySelector("[name='accountId']").value
			};

			let success = (res) => {
				self.showMessage(res.data, "success");
				this.eventBus.publish("account:changed");
				this.saveButton.disabled = true;
			};

			let error = (err) => {
				self.showMessage(err.error, "error");
			};

			await self.accountClient.deactivateUserById(payload, error, success);
		});
	}

	attachFormSubmitHandler() {
		let self = this;

		this.saveButton.addEventListener("click", async (e) => {
			e.preventDefault();
			self.hideMessage();

			let payload = {
				accountId: self.form.querySelector("[name='accountId']").value,
				contactId: self.form.querySelector("[name='contactId']").value,
				contact: {
					firstName: self.form.querySelector("[name='firstName']").value,
					lastName: self.form.querySelector("[name='lastName']").value
				},
				privileges: {
					hasBatchAccess: Boolean(
						self.form.querySelector("[name='has-batch-access']").checked
					)
				}
			};

			let success = (res) => {
				self.showMessage(res.data, "success");
				this.eventBus.publish("account:changed");
			};

			let error = (err) => {
				self.showMessage(err.error, "error");
			};

			await self.accountClient.updateSibling(payload, error, success);
		});
	}

	showMessage(message, type) {
		this.message.classList.add(type);
		this.message.innerHTML = message;
		this.message.classList.add("active");
	}

	hideMessage() {
		this.message.classList.remove("error");
		this.message.classList.remove("success");
		this.message.innerHTML = "";
		this.message.classList.remove("active");
	}

	isOpen() {
		return this.container.classList.contains(this.activeClass);
	}

	openBackdrop() {
		this.backdrop.classList.add(this.activeClass);
	}

	closeBackdrop() {
		this.backdrop.classList.remove(this.activeClass);
	}

	openInterface(data) {
		this.container.classList.add(this.activeClass);
		this.populateForm(data);
		this.form.querySelector("[name='firstName']").focus();
		this.openBackdrop();
	}

	closeInterface() {
		this.container.classList.remove(this.activeClass);
		this.closeBackdrop();
	}

	populateForm(data) {
		this.form.querySelector("[name='firstName']").value = data.firstName;
		this.form.querySelector("[name='lastName']").value = data.lastName;
		this.form.querySelector("[name='accountId']").value = data.accountId;
		this.form.querySelector("[name='contactId']").value = data.contactId;
		this.form.querySelector("[name='has-batch-access']").checked =
			data.hasBatchAccess;
		this.saveButton.disabled = false;
	}
}

module.exports = UserSettingsInterface;
