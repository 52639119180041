const utils = require("../utils/index.js");

var mrMapDrawingToolsFilterControl = function () {
	var self = this;

	self.options = {
		containerBaseClass: "mr-map-controls-container",
		containerClassNames: "",
		containerDataType: "drawing-tools-filter",
		containerTitle: "Filter report results by drawing on the map",
		innerContainerBaseClass: "mr-map-controls-container-inner",
		innerContainerClassNames: "",
		triggerBaseClass: "mr-map-controls-trigger",
		triggerClassNames: "",
		triggerIconBaseClass: "mr-map-controls-trigger-icon",
		triggerIconClassNames: "icon-filter"
	};

	self.stateModel = {
		active: false,
		visible: true,
		enabled: false,
		filterTools: ["pan", "circle", "polygon", "rectangle"]
	};

	self.state = JSON.parse(JSON.stringify(self.stateModel));

	self.$mapControlContainer = $("<div></div>");
	self.$mapControlInnerContainer = $("<div></div>");
	self.$mapControlTrigger = $("<div></div>");
	self.$mapControlTriggerIcon = $("<i></i>");

	self.init = function (mapController, options) {
		if (!mapController) {
			console.log(
				"mapDrawingToolsFilterControl error; no mapController specified"
			);

			return;
		}

		if (options) {
			self.options = utils.extend.deepmerge(self.options, options);
		}

		if (!self.options.parentContainer) {
			console.log(
				"mapDrawingToolsFilterControl error: no parent container defined in options."
			);

			return;
		}

		if (!self.options.id) {
			console.log("mapDrawingToolsFilterControl error: no id specified.");

			return;
		}

		if ($("#" + self.options.id).length <= 0) {
			self.mapController = mapController;
			self.create();
		} else {
			console.log(
				"mapDrawingToolsFilterControl error: Cannot create duplicate mapDrawingToolsFilterControl container."
			);

			return;
		}
	};

	self.create = function () {
		self.$mapControlContainer.attr("id", self.options.id);
		self.$mapControlContainer.addClass(
			self.options.containerBaseClass + " " + self.options.containerClassNames
		);
		self.$mapControlContainer.attr("data-type", self.options.containerDataType);
		self.$mapControlContainer.attr("title", self.options.containerTitle);

		if (self.options.enabled === false) {
			self.$mapControlContainer.addClass("disabled");
			self.state.enabled = false;
		} else {
			self.state.enabled = true;
		}

		self.$mapControlInnerContainer.addClass(
			self.options.innerContainerBaseClass +
				" " +
				self.options.innerContainerClassNames
		);

		self.$mapControlTrigger.addClass(
			self.options.triggerBaseClass + " " + self.options.triggerClassNames
		);

		self.$mapControlTriggerIcon.addClass(
			self.options.triggerIconBaseClass +
				" " +
				self.options.triggerIconClassNames
		);

		self.$mapControlTrigger.append(self.$mapControlTriggerIcon);

		self.$mapControlInnerContainer.append(self.$mapControlTrigger);
		self.$mapControlContainer.append(self.$mapControlInnerContainer);

		$(self.options.parentContainer).append(self.$mapControlContainer);

		self.attachTriggerClick();
	};

	self.destroy = function () {
		self.$mapControlContainer.remove();
	};

	self.enable = function () {
		self.state.enabled = true;
		self.$mapControlContainer.removeClass("disabled");
	};

	self.disable = function () {
		self.state.enabled = false;
		self.$mapControlContainer.addClass("disabled");
	};

	self.attachTriggerClick = function () {
		self.$mapControlTrigger.on("click", function (e) {
			e.preventDefault();
			if (self.state.enabled === true) {
				var overlayFound = false;

				if (self.$mapControlContainer.hasClass("active")) {
					self.state.active = false;
					self.$mapControlContainer.removeClass("active");

					window.mrState.drawingTools.isDrawingToolReportFilteringEnabled = false;

					self.state.filterTools.forEach(function (tool) {
						self.mapController.mapDrawingToolsControl.state.currentTools[
							tool
						].$control.removeClass("filtering");
					});

					self.disableDrawingToolsFilteringByName(self.state.filterTools);

					self.mapController.mapDrawingToolsControl.enable();

					self.mapController.drawingManager.setDrawingMode(null);

					self.mapController.mapDrawingToolsControl.activateDrawingToolByType(
						"pan"
					);
				} else {
					self.state.active = true;
					self.$mapControlContainer.addClass("active");

					if (self.$mapControlContainer.hasClass("disabled")) {
						self.$mapControlContainer.removeClass("disabled");
					}

					window.mrState.drawingTools.isDrawingToolReportFilteringEnabled = true;

					self.state.filterTools.forEach(function (tool) {
						self.mapController.mapDrawingToolsControl.state.currentTools[
							tool
						].$control.addClass("filtering");
					});

					overlayFound = self.mapController.getReportFilterOverlay();

					if (overlayFound) {
						self.mapController.mapDrawingToolsControl.disable();
					} else {
						self.mapController.mapDrawingToolsControl.disableDrawingToolByType(
							"marker"
						);
						self.mapController.mapDrawingToolsControl.disableDrawingToolByType(
							"polyline"
						);
						self.enableDrawingToolsFilteringByName(self.state.filterTools);
					}

					self.mapController.mapDrawingToolsControl.activateDrawingToolByType(
						"pan"
					);
				}
			}
		});
	};

	self.enableDrawingToolsFilteringByName = function (tools) {
		if (!Array.isArray(tools)) {
			tools = [tools];
		}

		if (tools.length <= 0) {
			return;
		}

		var toolOptions = {};
		tools.forEach(function (tool) {
			toolOptions[tool + "Options"] = {
				fillColor: "#0191d9",
				strokeColor: "#0191d9"
			};
		});

		self.mapController.drawingManager.setOptions(toolOptions);
	};

	self.disableDrawingToolsFilteringByName = function (tools) {
		if (!Array.isArray(tools)) {
			tools = [tools];
		}

		if (tools.length <= 0) {
			return;
		}

		var toolOptions = {};
		tools.forEach(function (tool) {
			toolOptions[tool + "Options"] = {
				fillColor: "#fd8421",
				strokeColor: "#fd8421"
			};
		});

		self.mapController.drawingManager.setOptions(toolOptions);
	};
};

module.exports = mrMapDrawingToolsFilterControl;
