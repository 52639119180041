class ViewBase {
	constructor(subscribers) {
		this.subscribers = subscribers ?? [];
		this.listeners = {};
	}

	unbind() {}

	subscribe(name, listener) {
		let self = this;

		let index = this.subscribers.push({ name, listener }) - 1;

		return function unsubscribe() {
			self.subscribers.splice(index, 1);
		};
	}

	publish(messageName, data) {
		this.subscribers.forEach((msg) => {
			if (msg.name === messageName) {
				msg.listener(data);
			}
		});
	}

	attachListener($el, name, selector, data, callback) {
		let listener = $el.on(name, selector, data, callback);
		this.listeners[name] = {
			$el: $el,
			selector: selector,
			data: data,
			callback: callback
		};
	}

	detachListeners() {
		for (var listener in this.listeners) {
			this.listeners[listener].off(listener);
		}
	}

	detachListener($el, name, selector, callback) {
		$el.off(name, selector, callback);
	}
}

module.exports = ViewBase;
