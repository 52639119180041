const { extend } = require("../../utils");

class LoadingOverlay {
	constructor(options) {
		this.options = {
			containerBaseClass: "mr-loading-overlay",
			containerClassNames: "",
			containerInnerBaseClass: "mr-loading-overlay-inner",
			containerInnerClassNames: "ripple",
			type: "ripple"
		};

		if (options) {
			this.options = extend.deepmerge(this.options, options);
		}

		switch (this.options.type) {
			case "spinner":
				this.options.containerInnerClassNames = "spinner";
				this.options.animationMarkup = "<div><div></div></div>";
				break;
			case "ripple":
			default:
				this.options.containerInnerClassNames = "ripple";
				this.options.animationMarkup = "<div><div></div><div></div></div>";
				break;
		}

		if (!this.options.parentContainer) {
			console.log(
				"Loading Overlay error: no parentContainer defined in options"
			);

			return;
		}

		if (!this.options.id) {
			console.log("Loading Overlay error: no id specified");

			return;
		}

		if ($("#" + this.options.id).length <= 0) {
			this.create();
		} else {
			console.log(
				"Loading Overlay error: Cannot create duplicate Loading Overlay container."
			);

			return;
		}
	}

	create() {
		this.$loadingOverlayContainer = $("<div></div>");
		this.$loadingOverlayContainerInner = $("<div></div>");

		this.$loadingOverlayContainer.attr("id", this.options.id);
		this.$loadingOverlayContainer.addClass(
			this.options.id +
				" " +
				this.options.containerBaseClass +
				" " +
				this.options.containerClassNames
		);
		this.$loadingOverlayContainerInner.addClass(
			this.options.containerInnerBaseClass
		);
		this.$loadingOverlayContainerInner.addClass(
			this.options.containerInnerClassNames
		);

		this.$loadingOverlayContainerInner.append(this.options.animationMarkup);

		this.$loadingOverlayContainer.append(this.$loadingOverlayContainerInner);

		$(this.options.parentContainer).append(this.$loadingOverlayContainer);
	}

	destroy() {
		this.$loadingOverlayContainer.remove();
	}

	enable() {
		this.$loadingOverlayContainer.addClass("active");
	}

	disable() {
		this.$loadingOverlayContainer.removeClass("active");
	}
}

module.exports = LoadingOverlay;
