const convertObjectToString = function (val) {
	var stringBuilder = "";

	if (typeof val === "object" && val !== null) {
		for (var e in val) {
			if (stringBuilder.length > 0) {
				stringBuilder += "<br>";
			}

			if (Array.isArray(val)) {
				stringBuilder += convertObjectToString(val[e]);
			} else {
				stringBuilder += e + ": " + convertObjectToString(val[e]);
			}
		}

		return stringBuilder;
	} else {
		return val;
	}
};

module.exports = convertObjectToString;
