const ViewBase = require("../view-base.js");

class ToggleView extends ViewBase {
	constructor(
		domId,
		containerBaseClass,
		containerClassNames,
		switchBaseClass,
		switchClassNames,
		initialPosition
	) {
		super();

		this.$toggleContainer = null;
		this.domId = domId;
		this.containerBaseClass = containerBaseClass ?? "mr-toggle-container";
		this.containerClassNames = containerClassNames ?? "";
		this.switchBaseClass = switchBaseClass ?? "mr-toggle-switch";
		this.switchClassNames = switchClassNames ?? "";

		this.initialPosition = initialPosition ?? "off";

		if (this.$parentContainer) {
			this.bind(this.$parentContainer);
		}
	}

	bind($el) {
		let self = this;
		this.$parentContainer = $el ?? this.$parentContainer;

		this.$toggleContainer = $("<div></div>");

		let $toggleSwitch = $("<div></div>");

		this.$toggleContainer.attr("id", this.domId);
		this.$toggleContainer.addClass(
			this.domId +
				" " +
				this.containerBaseClass +
				" " +
				this.containerClassNames
		);

		$toggleSwitch.addClass(this.switchBaseClass + " " + this.switchClassNames);

		this.$toggleContainer.append($toggleSwitch);

		this.setState(this.initialPosition);

		this.$toggleContainer.on("click." + this.domId, function (e) {
			self.setState();
		});

		this.$parentContainer.append(this.$toggleContainer);
	}

	unbind() {
		this.$toggleContainer.off("click." + this.domId);
		this.$toggleContainer.remove();
	}

	enable() {}

	disable() {}

	setState(state) {
		if (state === "on" || state === "off") {
			this.$toggleContainer.attr("data-position", state);
		} else {
			if (this.$toggleContainer.attr("data-position") === "on") {
				this.$toggleContainer.attr("data-position", "off");
				state = "off";
			} else {
				this.$toggleContainer.attr("data-position", "on");
				state = "on";
			}
		}

		this.publish("checked", state === "on" ? true : false);
	}
}

module.exports = ToggleView;
