const $ = require("jquery");
const _ = require("lodash");

const CONSTANTS = require("../../../lib/index");
const dialog = require("../dialog/index.js");
const utils = require("../utils/index.js");
const eventTracker = utils.eventTracker;

const TransactionClient = require("../clients/transaction-client.js");

const bingAutoSuggest = require("../components/map/bing/auto-suggest.js");
const ImageryCoverage = require("../imagery/coverage/index.js");
const ImageryImages = require("../imagery/images/index.js");
const ImageryTiles = require("../imagery/tiles/index.js");
const mrMapTypeControl = require("../controls/map-type.js");
const mrMapLabelControl = require("../controls/label.js");
const mrMapZoomControl = require("../controls/zoom.js");
const mrMapImageryControl = require("../controls/imagery.js");
const mrMapDrawingToolsControl = require("../controls/drawing-tools.js");
const mrMapDrawingToolsFilterControl = require("../controls/drawing-tools-filter.js");
const mrMapStreetViewControl = require("../controls/street-view.js");

class MapriskController {
	constructor(options) {
		this.options = options || {};
		this.drawingDisabled = {
			status: false,
			disabledAttemptFunc: null,
			disabledAttemptFuncArgs: []
		};
		this.layers = {};
		this.reportOverlays = {};
		this.userOverlays = {};
		this.layerStyles = {};
		this.imagery;
		this.enabledOverlays = [];

		var accountConfigurationModel =
			this.options.accountConfigurationModel || {};
		var runtimeConfiguration =
			accountConfigurationModel.runtimeConfiguration || {};
		var websiteConfiguration = runtimeConfiguration.website || {};

		if (websiteConfiguration.defaultMapType && this.options.mapping.mapTypeId) {
			this.defaultMapType = this.options.mapping.mapTypeId;
		} else if (websiteConfiguration.defaultMapType) {
			this.defaultMapType = websiteConfiguration.defaultMapType;
		} else if (this.options.mapping.mapTypeId) {
			this.defaultMapType = this.options.mapping.mapTypeId;
		} else {
			this.defaultMapType = "roadmap";
		}

		// satellite view has been removed from the map and replaced with 'hybrid' type and option to hide/show labels
		if (this.defaultMapType.toLowerCase() === "satellite") {
			this.defaultMapType = "hybrid";
		}

		this.allowHighResImagery = this.getImageryPrivileges(runtimeConfiguration);
		this.imageryConfiguration = utils.getImageryConfig(runtimeConfiguration);

		this.listeners = {}; // general storage property for event listener references
	}

	getImageryPrivileges(runtimeConfiguration) {
		var allowed = false;

		if (
			runtimeConfiguration &&
			runtimeConfiguration.privileges &&
			runtimeConfiguration.privileges.allowHighResImagery === true
		) {
			if (!runtimeConfiguration.imagery) {
				runtimeConfiguration.imagery = {};
			}

			if (!runtimeConfiguration.imagery.Types) {
				runtimeConfiguration.imagery.Types = [];
			}

			if (runtimeConfiguration.imagery.Types.indexOf["Nearmap"] < 0) {
				runtimeConfiguration.imagery.Types.push("Nearmap");
			}

			allowed = true;
		} else if (
			runtimeConfiguration &&
			runtimeConfiguration.imagery &&
			runtimeConfiguration.imagery.Types &&
			runtimeConfiguration.imagery.Types.length > 0
		) {
			allowed = true;
		}

		return allowed;
	}

	addReportOverlay(overlay, noLabel) {
		if (!this.reportOverlays[overlay.mr_type]) {
			this.reportOverlays[overlay.mr_type] = [];
		}

		var meta;

		if (!noLabel) {
			meta = this.initReportOverlayMeta(overlay);
		} else {
			meta = { label: { map: null } };
		}

		overlay.mr_meta = meta;
		this.updateOverlayMeta(overlay, {
			forceUpdate: true,
			isReportOverlay: true
		});

		overlay.setMap(this.map);
		this.reportOverlays[overlay.mr_type].push(overlay);
	}

	getReportFilterOverlay() {
		var self = this;
		var response;

		for (var overlayType in self.userOverlays) {
			self.userOverlays[overlayType].forEach(function (userOverlay) {
				if (userOverlay.mr_reportFilter) {
					response = userOverlay;
				}
			});

			if (response) {
				break;
			}
		}

		return response;
	}

	addUserOverlay(overlay) {
		var self = this;
		var filterTools = ["circle", "polygon", "rectangle"];
		var overlayFound = false;

		if (!self.userOverlays[overlay.mr_type]) {
			self.userOverlays[overlay.mr_type] = [];
		}

		var meta = self.initUserOverlayMeta(overlay);

		overlay.mr_meta = meta;

		self.updateOverlayMeta(overlay, { forceUpdate: true });

		overlay.setMap(self.map);
		self.userOverlays[overlay.mr_type].push(overlay);

		overlayFound = self.getReportFilterOverlay();
		if (
			overlayFound &&
			window.mrState.drawingTools.isDrawingToolReportFilteringEnabled
		) {
			self.drawingManager.setDrawingMode(null);
			self.mapDrawingToolsControl.disable();
		}

		google.maps.event.addListener(overlay, "rightclick", function (mouseEvent) {
			var index = -1;
			var overlayFound = false;
			var filterTools = ["pan", "circle", "polygon", "rectangle"];

			for (var i = 0; i < self.userOverlays[overlay.mr_type].length; i++) {
				if (overlay.mr_id === self.userOverlays[overlay.mr_type][i].mr_id) {
					index = i;
					break;
				}
			}

			self.userOverlays[overlay.mr_type].splice(index, 1);

			if (self.userOverlays[overlay.mr_type].length < 1) {
				delete self.userOverlays[overlay.mr_type];
			}

			self.removeOverlay(overlay);

			if (!self.getReportFilterOverlay()) {
				self.mapDrawingToolsControl.enable();
				self.mapDrawingToolsControl.activateDrawingToolByType("pan");

				if (window.mrState.drawingTools.isDrawingToolReportFilteringEnabled) {
					self.mapDrawingToolsControl.disableDrawingToolByType("marker");
					self.mapDrawingToolsControl.disableDrawingToolByType("polyline");
					self.mapDrawingToolsFilterControl.enableDrawingToolsFilteringByName(
						filterTools
					);

					for (var reportId in window.reportRequestList) {
						if (
							utils.getReportParameterByName(
								window.reportConfigurationModel,
								reportId,
								"filterResultsWithDrawingTools"
							) &&
							window.currentPoiInfo
						) {
							window.rerunReportOnModelInputChange(reportId);
						}
					}
				}
			}
		});
	}

	initReportOverlayMeta(overlay) {
		switch (overlay.mr_type) {
			case "circle":
				break;
			case "marker":
				return { label: this.initOverlayLabel(overlay, true, false) };
			case "polyline":
				return { label: this.initOverlayLabel(overlay) };
			default:
				return null;
		}
	}

	initUserOverlayMeta(overlay) {
		switch (overlay.mr_type) {
			case "circle":
				var center = overlay.getCenter();
				var line = this.drawLine(center, center);

				var radiusLabel = this.initOverlayLabel(line);
				var areaLabel = this.initOverlayLabel(overlay);

				overlay.mr_clickListener = overlay.addListener("click", function () {
					areaLabel.show();
					radiusLabel.show();
				});

				var self = this;

				overlay.addListener("center_changed", function () {
					self.updateOverlayMeta(overlay, { positionOnly: true });
				});

				overlay.addListener("radius_changed", function () {
					self.updateOverlayMeta(overlay);
				});

				return {
					line: line,
					radiusLabel: radiusLabel,
					areaLabel: areaLabel
				};
			case "marker":
				return { label: this.initOverlayLabel(overlay, true) };
			case "polygon":
				return this.initOverlayLabel(overlay);
			case "polyline":
				var self = this;

				this.addListeners(overlay, ["drag", "dragend"], function () {
					self.updateOverlayMeta(overlay, { positionOnly: true });
				});

				this.addListeners(
					overlay.getPath(),
					["insert_at", "remove_at"],
					function () {
						self.updateOverlayMeta(overlay);
					}
				);

				// mousedown Does not fire when clicking the handles to resize.
				overlay.addListener("mousedown", function () {
					overlay.mr_setAtRemoveHandle.remove();
				});

				overlay.addListener("mouseup", function () {
					overlay.mr_setAtRemoveHandle = overlay
						.getPath()
						.addListener("set_at", function () {
							self.updateOverlayMeta(overlay);
						});
				});

				google.maps.event.trigger(overlay, "mouseup");

				return { label: this.initOverlayLabel(overlay) };
			case "rectangle":
				return this.initOverlayLabel(overlay);
			default:
				return null;
		}
	}

	initOverlayLabel(overlay, inputLabel, showLabel) {
		// Default
		if (typeof showLabel === "undefined") showLabel = true;

		var label;

		if (inputLabel) label = this.getInputLabel(overlay, ""); // Here, labelContent is the placeholder

		switch (overlay.mr_type) {
			case "circle":
				label = this.getBasicLabel(null, "");
				break;
			case "marker":
				// Label might already be created as input label
				if (!label) label = this.getBasicLabel(overlay, "");

				overlay.mr_clickListener = overlay.addListener("click", function () {
					label.toggle();
				});

				var basicShowFunc = label.show;

				label.show = function (overlay) {
					basicShowFunc(overlay);
				};
				break;
			case "polygon":
				// Polygon is special because it gets 2 labels
				var paths = overlay.getPaths();

				var areaLabel = this.getBasicLabel(null, "");
				var perimeterLabel = this.getBasicLabel(null, "");

				// These need to be specifically called.
				// Normally it would happen for a single label after the switch statement
				perimeterLabel.mr_type = "label";
				areaLabel.mr_type = "label";
				perimeterLabel.show();
				areaLabel.show();
				//

				overlay.mr_clickListener = overlay.addListener("click", function () {
					perimeterLabel.show();
					areaLabel.show();
				});

				var self = this;

				overlay.addListener("drag", function () {
					self.updateOverlayMeta(overlay, { positionOnly: true });
				});

				this.addListeners(paths, ["insert_at", "remove_at"], function () {
					self.updateOverlayMeta(overlay);
				});

				paths.getArray().forEach(function (path) {
					self.addListeners(path, ["insert_at", "remove_at"], function (e) {
						self.updateOverlayMeta(overlay);
					});
				});

				// Prevent 'set_at' from being called a million times when a polygon is moved around
				overlay.addListener("dragstart", function () {
					overlay.mr_setAtRemoveHandle.remove();

					while (overlay.mr_pathSetAtRemoveHandles.length > 0)
						overlay.mr_pathSetAtRemoveHandles.pop().remove();
				});

				overlay.addListener("dragend", function () {
					overlay.mr_setAtRemoveHandle = overlay.addListener(
						"set_at",
						function () {
							self.updateOverlayMeta(overlay);
						}
					);

					paths.getArray().forEach(function (path) {
						overlay.mr_pathSetAtRemoveHandles = (
							overlay.mr_pathSetAtRemoveHandles || []
						).concat(
							path.addListener("set_at", function () {
								self.updateOverlayMeta(overlay);
							})
						);
					});
				});

				google.maps.event.trigger(overlay, "dragend");

				return {
					perimeterLabel: perimeterLabel,
					areaLabel: areaLabel
				};
			case "polyline":
				label = this.getBasicLabel(null, "");

				overlay.mr_clickListener = overlay.addListener("click", function () {
					label.show();
				});
				break;
			case "rectangle":
				var areaLabel = this.getBasicLabel(null, "");
				var perimeterLabel = this.getBasicLabel(null, "");

				// These need to be specifically called.
				// Normally it would happen for a single label after the switch statement
				perimeterLabel.mr_type = "label";
				areaLabel.mr_type = "label";
				perimeterLabel.show();
				areaLabel.show();
				//

				overlay.mr_clickListener = overlay.addListener("click", function () {
					perimeterLabel.show();
					areaLabel.show();
				});

				var self = this;

				overlay.addListener("drag", function () {
					self.updateOverlayMeta(overlay, { positionOnly: true });
				});

				// mousedown Does not fire when clicking the handles to resize.
				overlay.addListener("mousedown", function () {
					overlay.mr_boundsChangedRemoveHandle.remove();
				});

				overlay.addListener("mouseup", function () {
					overlay.mr_boundsChangedRemoveHandle = overlay.addListener(
						"bounds_changed",
						function () {
							self.updateOverlayMeta(overlay);
						}
					);
				});

				google.maps.event.trigger(overlay, "mouseup");

				/////
				return {
					perimeterLabel: perimeterLabel,
					areaLabel: areaLabel
				};
		}

		label.mr_type = "label";

		if (showLabel) label.show();

		return label;
	}

	zoomChanged() {
		var self = this;

		google.maps.event.addListener(self.map, "zoom_changed", function () {
			for (var reportId in window.reportsModel) {
				var enableClickAtZoomLevel = utils.getReportParameterByName(
					window.reportConfigurationModel,
					reportId.toLowerCase(),
					"enableClickAtZoomLevel"
				);
				var aggregateFeatures = utils.getReportParameterByName(
					window.reportConfigurationModel,
					reportId.toLowerCase(),
					"aggregateFeaturesInRadius"
				);
				var zoom = self.map.getZoom();

				if (
					enableClickAtZoomLevel &&
					Number.isInteger(enableClickAtZoomLevel) &&
					aggregateFeatures
				) {
					if (zoom < enableClickAtZoomLevel) {
						window.aggregateFeaturesInRadius(reportId);
					} else {
						window.clearAggregateFeaturesInRadius(reportId);
					}
				}
			}
		});
	}

	refresh() {
		google.maps.event.trigger(this.map, "resize");
	}

	ZoomControl(map, mapDiv, zoomContainerDiv, isZoomIn) {
		var zoomType = isZoomIn ? "In" : "Out";
		var zoomIcon = isZoomIn ? "+" : "-";
		var $controlUI = $("<div class='map-control'></div>");
		var $controlText = $("<div class='map-control-text'></div>");

		$controlUI.attr("data-type", "zoom-" + zoomType.toLowerCase());
		$controlUI.attr("title", "Zoom " + zoomType);
		$controlText.text(zoomIcon);
		$controlUI.append($controlText);

		$(zoomContainerDiv).append($controlUI);

		$controlUI.on("click", function () {
			var currentZoom = map.getZoom();

			if ((isZoomIn && currentZoom === 22) || (!isZoomIn && currentZoom === 3))
				return;

			currentZoom = isZoomIn ? ++currentZoom : --currentZoom;

			map.setZoom(currentZoom);
		});
	}

	getCurrentLayerStyle(reportId, layerId) {
		if (!this.layerStyles[reportId]) {
			this.layerStyles[reportId] = {};
		}

		if (!this.layerStyles[reportId][layerId]) {
			this.layerStyles[reportId][layerId] = {
				current: null,
				list: []
			};
		}

		var style = this.layerStyles[reportId][layerId].current;

		return style;
	}

	getCurrentReportStyle(reportId) {
		return this.reportStyles[reportId] != null
			? this.reportStyles[reportId].current
			: null;
	}

	setCurrentLayerStyle(reportId, layerId, style) {
		if (!this.layerStyles[reportId]) {
			this.layerStyles[reportId] = {};
		}

		if (!this.layerStyles[reportId][layerId]) {
			this.layerStyles[reportId][layerId] = {
				current: null,
				list: []
			};
		}

		this.layerStyles[reportId][layerId].current = style;
	}

	StreetViewResize(map) {
		google.maps.event.trigger(map.getStreetView(), "resize");
	}

	createMapControlsContainer() {
		$("#GoogleMapDiv").append($("<div class='mr-map-controls'></div>"));
	}

	initMap(reportConfiguration) {
		var mapOptions = this.options.mapping;
		var mapObject = mapOptions.mapObject;
		var mapDiv = mapObject.get(0);
		var mapId = mapDiv.id;
		var centerPoi = mapOptions.centerPoi;
		var initalZoom = mapOptions.initalZoom;
		var txtInputReportAddress = $("#txtInputReportAddress")[0];
		var self = this;

		self.radiusOverlays = []; // collects custom radius overlays for certain reports

		var styles = this.options.mapping.styles || { default: [] };

		// Create a map object and specify the DOM element for display.
		var map = new google.maps.Map(mapDiv, {
			center: centerPoi,
			disableDoubleClickZoom: false,
			mapTypeControl: mapOptions.disableDefaultUI ? false : false,
			mapTypeId: this.defaultMapType,
			scaleControl: mapOptions.disableDefaultUI ? false : true,
			zoom: initalZoom,
			tilt: 0,
			zoomControl: mapOptions.disableDefaultUI ? false : false,
			disableDefaultUI: mapOptions.disableDefaultUI,
			panControl: mapOptions.panControl === false ? false : true,
			draggable: mapOptions.draggable === false ? false : true,
			gestureHandling: "greedy",
			fullscreenControl: false,
			rotateControl: false
		});

		map.setOptions({
			styles: styles.default
		});

		if (!mapOptions.disableDefaultUI) {
			self.createMapControlsContainer();

			// check if any reports have drawing tools filtering to enable the control
			self.filterResultsWithDrawingTools = false;

			for (var reportId in window.reportConfigurationModel) {
				if (
					utils.getReportParameterByName(
						window.reportConfigurationModel,
						reportId,
						"filterResultsWithDrawingTools"
					)
				) {
					self.filterResultsWithDrawingTools = true;
				}
			}

			google.maps.event.addListenerOnce(map, "tilesloaded", function () {
				if (self.allowHighResImagery === true) {
					self.imageryCoverage = new ImageryCoverage(self);
					self.imageryImages = new ImageryImages(self);
					self.imageryTiles = new ImageryTiles(self);
				}

				self.mapStreetViewControl = new mrMapStreetViewControl();
				self.mapStreetViewControl.init(self, {
					id: "mr-map-control-map_street_view-main",
					parentContainer: ".mr-map-controls",
					enabled:
						window.currentPoiInfo &&
						window.currentPoiInfo.lat &&
						window.currentPoiInfo.lng
							? true
							: false
				});

				self.mapTypeControl = new mrMapTypeControl();
				self.mapTypeControl.init(window.mrState, self, {
					id: "mr-map-control-map_type-main",
					parentContainer: ".mr-map-controls",
					default: self.defaultMapType
				});

				self.mapLabelControl = new mrMapLabelControl();
				self.mapLabelControl.init(self, {
					id: "mr-map-control-map_label-main",
					parentContainer: ".mr-map-controls",
					active: true
				});

				self.mapZoomOutControl = new mrMapZoomControl();
				self.mapZoomOutControl.init(self, {
					id: "mr-map-control-map_zoom_out-main",
					parentContainer: ".mr-map-controls",
					active: true,
					containerDataDirection: "out",
					triggerIconClassNames: "icon-minus",
					containerTitle: "Zoom Out"
				});

				self.mapZoomInControl = new mrMapZoomControl();
				self.mapZoomInControl.init(self, {
					id: "mr-map-control-map_zoom_in-main",
					parentContainer: ".mr-map-controls",
					active: true,
					containerDataDirection: "in",
					triggerIconClassNames: "icon-plus",
					containerTitle: "Zoom In"
				});

				if (self.allowHighResImagery === true) {
					self.mapOverlayControl = new mrMapImageryControl(
						window.mrState.imagery.zoomLevels
					);
					self.mapOverlayControl.init(self, {
						id: "mr-map-control-map_overlay-main",
						parentContainer: ".mr-map-controls",
						enabled:
							window.currentPoiInfo &&
							window.currentPoiInfo.lat &&
							window.currentPoiInfo.lng
								? true
								: false
					});
				}

				self.mapDrawingToolsControl = new mrMapDrawingToolsControl();
				self.mapDrawingToolsControl.init(self, {
					id: "mr-map-control-map_drawing_tools-main",
					parentContainer: ".mr-map-controls",
					containerClassNames: self.filterResultsWithDrawingTools
						? "filtered"
						: ""
				});
				self.mapDrawingToolsControl.addTool({
					id: "drawing-tool-pan-main",
					classNames: "pan-button",
					type: "pan",
					drawingMode: null,
					iconClassNames: "icon-hand-paper",
					title: "Cancel Drawing",
					active: true
				});
				self.mapDrawingToolsControl.addTool({
					id: "drawing-tool-marker-main",
					classNames: "marker-button",
					type: "marker",
					drawingMode: google.maps.drawing.OverlayType.MARKER,
					iconClassNames: "icon-logo-icon",
					title: "Add a Marker"
				});
				self.mapDrawingToolsControl.addTool({
					id: "drawing-tool-circle-main",
					classNames: "circle-button",
					type: "circle",
					drawingMode: google.maps.drawing.OverlayType.CIRCLE,
					iconClassNames: "icon-circle",
					title: "Draw a Circle"
				});
				self.mapDrawingToolsControl.addTool({
					id: "drawing-tool-polygon-main",
					classNames: "polygon-button",
					type: "polygon",
					drawingMode: google.maps.drawing.OverlayType.POLYGON,
					iconClassNames: "icon-polygon",
					title: "Draw a Polygon"
				});
				self.mapDrawingToolsControl.addTool({
					id: "drawing-tool-polyline-main",
					classNames: "polyline-button",
					type: "polyline",
					drawingMode: google.maps.drawing.OverlayType.POLYLINE,
					iconClassNames: "icon-polyline",
					title: "Draw a Polyline"
				});
				self.mapDrawingToolsControl.addTool({
					id: "drawing-tool-rectangle-main",
					classNames: "rectangle-button",
					type: "rectangle",
					drawingMode: google.maps.drawing.OverlayType.RECTANGLE,
					iconClassNames: "icon-square",
					title: "Draw a Rectangle"
				});

				if (self.filterResultsWithDrawingTools) {
					self.mapDrawingToolsFilterControl = new mrMapDrawingToolsFilterControl();
					self.mapDrawingToolsFilterControl.init(self, {
						id: "mr-map-control-map_drawing_tools_filter-main",
						parentContainer: ".mr-map-controls",
						enabled:
							window.currentPoiInfo &&
							window.currentPoiInfo.lat &&
							window.currentPoiInfo.lng
								? true
								: false
					});
				}
			});

			self.googleMapTypes = [
				google.maps.MapTypeId.ROADMAP,
				google.maps.MapTypeId.TERRAIN,
				google.maps.MapTypeId.HYBRID
			];

			map.setOptions({
				mapTypeControl: false,
				streetViewControl: true,
				streetViewControlOptions: {
					position: google.maps.ControlPosition.TOP_RIGHT
				}
			});

			self.geoserver =
				window.accountConfigurationModel.runtimeConfiguration.website.geoserver;

			self.TILE_SERVER_DEFAULT_PATH =
				self.geoserver.protocol +
				"://" +
				self.geoserver.host +
				self.geoserver.path +
				"?";

			self.TILE_SERVER_WMTS_PATH =
				self.geoserver.protocol +
				"://" +
				self.geoserver.host +
				self.geoserver.wmtsPath +
				"?";

			if (
				window.accountConfigurationModel.runtimeConfiguration?.website
					?.useBingAutoSuggestModule === true
			) {
				let autoSuggest = bingAutoSuggest(
					"#txtInputReportAddress",
					"#addressSearchContainer"
				);
			} else {
				let autocomplete = new google.maps.places.Autocomplete(
					txtInputReportAddress
				);

				autocomplete.bindTo("bounds", map);
				autocomplete.setTypes(["geocode"]);
			}
		}

		self.map = map;

		self.map.addListener("center_changed", function () {
			// User Overlays
			self.userOverlays.circle &&
				self.userOverlays.circle.forEach(function (overlay) {
					self.updateOverlayMeta(overlay, { positionOnly: true });
				});

			self.userOverlays.polyline &&
				self.userOverlays.polyline.forEach(function (overlay) {
					self.updateOverlayMeta(overlay, { positionOnly: true });
				});

			self.userOverlays.polygon &&
				self.userOverlays.polygon.forEach(function (overlay) {
					self.updateOverlayMeta(overlay, { positionOnly: true });
				});

			self.userOverlays.rectangle &&
				self.userOverlays.rectangle.forEach(function (overlay) {
					self.updateOverlayMeta(overlay, { positionOnly: true });
				});

			// Report Overlays
			self.reportOverlays.polyline &&
				self.reportOverlays.polyline.forEach(function (overlay) {
					self.updateOverlayMeta(overlay, {
						positionOnly: true,
						isReportOverlay: true
					});
				});
		});

		if (self.options.enableDrawing) {
			self.drawingManager = new google.maps.drawing.DrawingManager({
				drawingControl: false,
				circleOptions: {
					fillColor: "#fd8421",
					strokeColor: "#fd8421"
				},
				polygonOptions: {
					fillColor: "#fd8421",
					strokeColor: "#fd8421"
				},
				polylineOptions: {
					fillColor: "#fd8421",
					strokeColor: "#fd8421"
				},
				rectangleOptions: {
					fillColor: "#fd8421",
					strokeColor: "#fd8421"
				}
			});

			self.drawingManager.setMap(self.map);

			google.maps.event.addListener(
				self.drawingManager,
				"overlaycomplete",
				function (e) {
					var overlay = e.overlay;
					var type = e.type;

					if (
						self.mapDrawingToolsControl.state.drawingDisabled.status === true
					) {
						overlay.setMap(null);
						self.mapDrawingToolsControl.state.drawingDisabled.disabledAttemptFunc.apply(
							this,
							self.mapDrawingToolsControl.state.drawingDisabled
								.disabledAttemptFuncArgs
						);

						return;
					}

					overlay.mr_id = self.userOverlays[type]
						? self.userOverlays[type].length
						: 0;
					overlay.mr_type = type;
					self.addUserOverlay(overlay);

					if (window.mrState.drawingTools.isDrawingToolReportFilteringEnabled) {
						overlay.mr_reportFilter = true;

						self.mapDrawingToolsControl.activateDrawingToolByType("pan");
						self.mapDrawingToolsFilterControl.state.filterTools.forEach(
							function (tool) {
								self.mapDrawingToolsControl.state.currentTools[
									tool
								].$control.addClass("disabled");
							}
						);

						for (var reportId in window.reportRequestList) {
							if (window.reportConfigurationModel[reportId.toLowerCase()]) {
								if (
									utils.getReportParameterByName(
										window.reportConfigurationModel,
										reportId,
										"filterResultsWithDrawingTools"
									) &&
									window.currentPoiInfo
								) {
									window.rerunReportOnModelInputChange(reportId);
								}
							}
						}
					}

					let label = {
						_id: window.id,
						shape: e.type,
						request_type: utils.getAuthenticationType()
					};

					eventTracker("user_action", "draw_shape", label);
				}
			);

			google.maps.event.addListener(self.map, "rightclick", function (e) {
				self.mapDrawingToolsControl.activateDrawingToolByType("pan");
			});
		}

		// attach listener to detect map type changes
		self.mapTypeIdChange();
	}

	clearReportOverlays() {
		var self = this;

		Object.keys(this.reportOverlays).forEach(function (key) {
			while (self.reportOverlays[key].length > 0)
				self.removeOverlay(self.reportOverlays[key].pop());
		});
	}

	clearUserOverlays() {
		var self = this;

		Object.keys(this.userOverlays).forEach(function (key) {
			while (self.userOverlays[key].length > 0) {
				self.removeOverlay(self.userOverlays[key].pop());
			}

			if (self.userOverlays[key].length < 1) {
				delete self.userOverlays[key];
			}
		});
	}

	clearMap() {
		this.clearReportOverlays();
		this.clearUserOverlays();
	}

	removeOverlay(overlay) {
		if (overlay.mr_clickListener) {
			overlay.mr_clickListener.remove();
		}

		if (overlay.mr_meta) {
			Object.keys(overlay.mr_meta).forEach(function (metaKey) {
				if (
					typeof overlay.mr_meta[metaKey].map !== "undefined" &&
					overlay.mr_meta[metaKey].map
				) {
					overlay.mr_meta[metaKey].setMap(null);
				}
			});
		}

		overlay.setMap(null);
	}

	setMapCenter(poi, offsetX, offsetY, zoom) {
		let map = this.map;
		let latLng = new google.maps.LatLng(poi.lat, poi.lng);
		let point1 = map.getProjection().fromLatLngToPoint(latLng);

		offsetX = offsetX ?? 0;
		offsetY = offsetY ?? 0;
		zoom = zoom ?? -1;

		offsetX = isNaN(offsetX) ? 0 : Number(offsetX);
		offsetY = isNaN(offsetY) ? 0 : Number(offsetY);

		offsetX /= Math.pow(2, zoom);
		offsetY /= Math.pow(2, zoom);

		map.setCenter(
			map
				.getProjection()
				.fromPointToLatLng(
					new google.maps.Point(point1.x - offsetX, point1.y + offsetY)
				)
		);

		if (zoom && zoom > -1) {
			this.setZoom(zoom);
		}

		window.eventBus.publish("map:poi:changed");
	}

	setMapPoi(poi, offsetX, offsetY, zoom) {
		var self = this;

		self.currentPoi = poi;
		self.latLngPoi = new google.maps.LatLng(poi.lat, poi.lng);
		self.setMapCenter(poi, offsetX, offsetY, zoom);

		self.map.getStreetView().setPosition(poi);

		if (
			!self.options.mapping.disableDefaultUI &&
			self.allowHighResImagery === true
		) {
			if (self.imageryCoverage) {
				self.imageryCoverage.getCoverage(
					{ lat: poi.lat, lng: poi.lng },
					null,
					window.mrState
				);
				self.mapOverlayControl.reset();
				self.mapOverlayControl.setLoadingState();
			}

			document.addEventListener("imagery_coverage_updated", function () {
				if (
					window.mrState &&
					window.mrState.imagery &&
					window.mrState.imagery.coverage &&
					Object.keys(window.mrState.imagery.coverage).length > 0
				) {
					self.mapOverlayControl.enable();
				} else {
					self.mapOverlayControl.disable();
					window.mapUNC.pushMessage(
						window.mrUserNotifications.highResolutionImageryUnavailable,
						"warning"
					);
				}

				self.mapOverlayControl.setLoadedState();

				self.mapOverlayControl.populateSelect(window.mrState.imagery.coverage);
			});
		}
	}

	enableMapType(mapType) {
		var self = this;

		if (self.googleMapTypes.indexOf(mapType) < 0) {
			self.googleMapTypes.push(mapType);
		}
	}

	disableMapType(mapType) {
		var self = this;

		if (self.googleMapTypes.indexOf(mapType) >= 0) {
			delete self.googleMapTypes[self.googleMapTypes.indexOf(mapType)];
		}
	}

	mapTypeIdChange() {
		var self = this;

		self.map.addListener("maptypeid_changed", function () {
			let label = {
				_id: window.id,
				map_type: self.map.getMapTypeId(),
				request_type: utils.getAuthenticationType()
			};

			eventTracker("user_action", "change_map_type", label);
		});
	}

	disableZoom() {
		var self = this;

		self.map.setOptions({
			scrollwheel: false,
			disableDoubleClickZoom: true
		});

		self.mapZoomInControl.disable();
		self.mapZoomOutControl.disable();
	}

	enableZoom() {
		var self = this;

		self.map.setOptions({
			scrollwheel: true,
			disableDoubleClickZoom: false
		});

		self.mapZoomInControl.enable();
		self.mapZoomOutControl.enable();
	}

	disablePanning() {
		var self = this;

		self.map.setOptions({
			draggable: false,
			keyboardShortcuts: false
		});
	}

	enablePanning() {
		var self = this;

		self.map.setOptions({
			draggable: true,
			keyboardShortcuts: true
		});
	}

	setZoom(val) {
		this.map.setZoom(val);
	}

	drawPolygon(polygon) {
		var polygonPoints = [];

		for (var pointIndex in polygon) {
			polygonPoints.push({
				lat: Number(polygon[pointIndex].latitude),
				lng: Number(polygon[pointIndex].longitude)
			});
		}
		var googleMapPolygon = new google.maps.Polygon({
			paths: polygonPoints,
			strokeColor: "#FF0000",
			strokeOpacity: 0.8,
			strokeWeight: 2
		});

		googleMapPolygon.setMap(this.map);
		googleMapPolygon.mr_type = "polygon";

		return googleMapPolygon;
	}

	handleMarkerDragStart(latLng, marker, dragConfig, isPoi) {
		var markerIndex = isPoi ? 0 : 1;
		var index = -1;

		if (
			this.reportOverlays.polyline &&
			this.reportOverlays.polyline.length > 0
		) {
			index = this.reportOverlays.polyline.findIndex(function (line) {
				// comparing the lat/lng of marker in question against the polyline's corresponding
				// marker on file.
				var path = line.getPath();
				var points = path.getArray();

				return (
					points[0].lat() === latLng.lat() && points[0].lng() === latLng.lng()
				);
			});

			var lineOverlay = this.reportOverlays.polyline.splice(index, 1)[0];

			this.removeOverlay(lineOverlay);
		}

		index = -1;

		if (isPoi) {
			if (!this.originalPoi)
				this.originalPoi = JSON.parse(JSON.stringify(this.currentPoi));

			// add circle at currentPoi with radius defined in dragConfig
			var circle = this.drawCircle(
				this.originalPoi.lat,
				this.originalPoi.lng,
				dragConfig.maxMoveRadius
			);

			circle.name = "dragMarkerBoundary";

			this.addReportOverlay(circle, false);
		}
	}

	handleMarkerDrag(latLng, marker, dragConfig) {
		var index = this.reportOverlays.circle.findIndex(function (circle) {
			return circle.name === "dragMarkerBoundary";
		});

		var circle = this.reportOverlays.circle[index];
		var circleCenter = circle.getCenter();
		var radius = circle.getRadius();
		var distance = this.getDistance(latLng, circleCenter);

		marker.withinBounds = distance <= radius ? true : false;

		if (marker.withinBounds) {
			marker.setIcon();
			window.tempPoi = latLng;
		} else {
			marker.setIcon("https://www.google.com/mapfiles/marker_grey.png");
		}
	}

	handleMarkerDragEnd(latLng, marker, dragConfig, isPoi, reportId) {
		var addressInput;

		if (isPoi) {
			// update currentPoi to last good position tracked by drag event
			if (marker.withinBounds) {
				var varContainingLatLngs;

				marker.setPosition(window.tempPoi);
				marker.setIcon();

				this.skipGeocodeOnNextSearch = true;
				this.currentPoi.lat = window.tempPoi.lat();
				this.currentPoi.latitude = window.tempPoi.lat();
				this.currentPoi.lng = window.tempPoi.lng();
				this.currentPoi.longitude = window.tempPoi.lng();

				if (dragConfig.remainingReRuns > 0) {
					dragConfig.remainingReRuns--;
				}

				if (dragConfig.remainingReRuns === 0) {
					marker.setDraggable(false);
				}

				// wipe out all other overlays
				for (var prop in this.reportOverlays) {
					varContainingLatLngs =
						prop === "circle" || prop === "radii" ? "center" : "position";

					for (var index in this.reportOverlays[prop]) {
						if (
							this.reportOverlays[prop][index][varContainingLatLngs].lat() !==
								this.currentPoi.lat &&
							this.reportOverlays[prop][index][varContainingLatLngs].lng() !==
								this.currentPoi.lng
						)
							this.removeOverlay(this.reportOverlays[prop][index]);
					}
				}

				if (
					window.accountConfigurationModel.runtimeConfiguration.website &&
					window.accountConfigurationModel.runtimeConfiguration.website.poi &&
					window.accountConfigurationModel.runtimeConfiguration.website.poi
						.moveMarkerForReportReRun &&
					window.accountConfigurationModel.runtimeConfiguration.website.poi
						.moveMarkerForReportReRun.preserveOriginalAddress === true
				) {
					addressInput = $(window.addressSearch.textbox).val();
				} else {
					$(window.addressSearch.textbox).val(
						this.currentPoi.lat + "," + this.currentPoi.lng
					);
					addressInput = $(window.addressSearch.textbox).val();
				}

				let label = {
					_id: window.id,
					request_type: utils.getAuthenticationType()
				};

				eventTracker("user_action", "poi_marker_drag", label);

				window.runSearch(addressInput);
			} else {
				dialog.openDialog(CONSTANTS.DIALOG.DRAG_MARKER_ERROR);
				marker.setPosition({
					lat: this.currentPoi.lat,
					lng: this.currentPoi.lng
				});
				marker.setIcon();

				if (
					this.reportOverlays.circle &&
					this.reportOverlays.circle.length > 0
				) {
					var index = this.reportOverlays.circle.findIndex(function (circle) {
						return circle.name === "dragMarkerBoundary";
					});

					if (index > -1) this.removeOverlay(this.reportOverlays.circle[index]);
				}
			}
		} else {
			var line = this.drawLine(this.currentPoi, latLng);

			this.addReportOverlay(line, true);

			var distanceMeters = this.getDistance(this.currentPoi, marker.position);

			var transactionId = window.reportsModel[reportId].transactionId;

			var data = {};

			data[reportId] = {
				results: [
					{
						distanceToFeature: distanceMeters,
						distanceToFeatureUnits: "meters",
						closestFeaturePoint: {
							latitude: latLng.lat(),
							longitude: latLng.lng()
						}
					}
				]
			};

			var transactionClient = new TransactionClient();

			var updateTransactionCallback = function (err, transactionData) {
				if (err) console.log(err);

				for (var reportId in transactionData.response.reportResults) {
					if (window.reportsModel[reportId]) {
						window.reportsModel[reportId].report =
							transactionData.response.reportResults;
					} else {
						window.reportsModel[reportId] = {};
						window.reportsModel[reportId].report =
							transactionData.response.reportResults;
						window.reportsModel[reportId].transactionId =
							transactionData.request.transactionId;
					}

					var reportDataTable = $("#divAdditionalReportInformation_" + reportId)
						.children()
						.children("tbody");

					reportDataTable.empty();

					for (var reportRow in transactionData.response.reportResults[
						reportId
					]) {
						if (reportRow === "features") {
							continue;
						} else if (reportRow === "results") {
							var results =
								transactionData.response.reportResults[reportId][reportRow];

							for (var index = 0; index < results.length; index++) {
								for (var key in results[index]) {
									if (key === "closestFeaturePoint") continue;

									var isUnitsField = key.indexOf("Units") > -1;
									var hasUnitsTwin =
										!isUnitsField && results[index][key + "Units"]
											? true
											: false;

									if (!isUnitsField && hasUnitsTwin)
										reportDataTable.append(
											'<tr><td class="colName">' +
												utils.titeify(key) +
												':</td><td class="colValue">' +
												utils.convertObjectToString(
													results[index][key] +
														" " +
														results[index][key + "Units"]
												) +
												"</td></tr>"
										);
									else if (!isUnitsField && !hasUnitsTwin)
										reportDataTable.append(
											'<tr><td class="colName">' +
												utils.titeify(key) +
												':</td><td class="colValue">' +
												utils.convertObjectToString(results[index][key]) +
												"</td></tr>"
										);
									else continue;
								}
							}
						} else {
							var isUnitsField = reportRow.indexOf("Units") > -1;
							var hasUnitsTwin =
								!isUnitsField &&
								transactionData.response.reportResults[reportId][
									reportRow + "Units"
								]
									? true
									: false;

							if (!isUnitsField && hasUnitsTwin)
								reportDataTable.append(
									'<tr><td class="colName">' +
										utils.titeify(reportRow) +
										':</td><td class="colValue">' +
										utils.convertObjectToString(
											transactionData.response.reportResults[reportId][
												reportRow
											] +
												" " +
												transactionData.response.reportResults[reportId][
													reportRow + "Units"
												]
										) +
										"</td></tr>"
								);
							else if (!isUnitsField && !hasUnitsTwin)
								reportDataTable.append(
									'<tr><td class="colName">' +
										utils.titeify(reportRow) +
										':</td><td class="colValue">' +
										utils.convertObjectToString(
											transactionData.response.reportResults[reportId][
												reportRow
											]
										) +
										"</td></tr>"
								);
							else continue;
						}
					}
				}
			};

			transactionClient.updateTransaction(
				transactionId,
				data,
				updateTransactionCallback
			);
		}
	}

	addMarker(poi, txt, addTxtAsPdfLabel, dragConfig, isPoi, reportId) {
		var self = this;
		var dragEnabled = dragConfig && dragConfig.enabled;

		poi = this.normalizePoint(poi);

		if (poi == null) return;

		var marker = new google.maps.Marker({
			draggable: dragEnabled,
			map: this.map,
			position: poi,
			title: txt
		});

		if (dragEnabled) {
			marker.addListener("dragstart", function (e) {
				self.handleMarkerDragStart(e.latLng, marker, dragConfig, isPoi);
			});

			// add listener for drag. track position of marker and only update its position if within bounds of circle
			if (isPoi) {
				marker.addListener("drag", function (e) {
					self.handleMarkerDrag(e.latLng, marker, dragConfig);
				});
			}

			marker.addListener("dragend", function (e) {
				self.handleMarkerDragEnd(e.latLng, marker, dragConfig, isPoi, reportId);
			});
		}

		if (addTxtAsPdfLabel) marker.mr_pdfLabel = txt;

		marker.mr_type = "marker";

		return marker;
	}

	drawLine(start, end) {
		start = this.normalizePoint(start);
		end = this.normalizePoint(end);

		var line = [start, end];

		var googleLine = new google.maps.Polyline({
			path: line,
			geodesic: true,
			strokeColor: "#FF0000",
			strokeOpacity: 1.0,
			strokeWeight: 2
		});

		googleLine.setMap(this.map);
		googleLine.mr_type = "polyline";

		return googleLine;
	}

	drawCircle(lat, lng, rad) {
		var center = { lat: lat, lng: lng };
		var radius = rad ? rad : 402.336;

		var circle = new google.maps.Circle({
			strokeColor: "#FF0000",
			strokeOpacity: "0.8",
			strokeWeight: 2,
			fillColor: "#FF0000",
			fillOpacity: 0.35,
			map: this.map,
			center: center,
			radius: radius
		});

		circle.mr_type = "circle";

		return circle;
	}

	recenter() {
		var self = this;

		if (self.currentPoi) {
			self.map.setCenter(
				new google.maps.LatLng(self.currentPoi.lat, self.currentPoi.lng)
			);
		}
	}

	getBasicLabel(anchorOrPosition, content, customConfig) {
		if (!content) content = "";

		var anchor = null;
		var position = null;

		if (anchorOrPosition instanceof google.maps.LatLng) {
			position = anchorOrPosition;
		} else if (
			!!anchorOrPosition &&
			typeof anchorOrPosition.getPosition === "function"
		) {
			anchor = anchorOrPosition;
			position = anchor.getPosition();
		} else if (!!anchorOrPosition) {
			throw new Error(
				"getBasicLabel needs an anchor google.maps.MVCObject or a google.maps.LatLng"
			);
		}

		var labelConfig = {
			content: content,
			position: position,
			disableAutoPan: true
		};

		if (Object.assign) {
			labelConfig = Object.assign({}, labelConfig, customConfig);
		} else {
			for (var e in customConfig) {
				labelConfig[e] = customConfig[e];
			}
		}

		var label = new google.maps.InfoWindow(labelConfig);

		///////////// Augment InfoWindow's functions

		label.hide = label.close;

		var self = this;

		if (anchor) {
			label.show = function () {
				label.open(self.map, anchor);
			};
		} else {
			label.show = function (anchor) {
				if (anchor && !anchor instanceof google.maps.MVCObject)
					throw new Error("Must be instance of google.maps.MVCObject");

				label.open(self.map, anchor || null);
			};
		}

		label.toggle = function () {
			if (this.map) this.hide();
			else this.show();
		};

		return label;
	}

	getInputLabel(anchorOrPosition, placeholder, customConfig) {
		if (!placeholder) placeholder = "Type here";

		var $input = $("<input/>");

		$input.attr("placeholder", placeholder);

		var input = $input[0];

		input.style.border = "none";
		input.style.outline = "none";
		input.style.transitionDuration = "0ms, 0ms";

		input.addEventListener("focus", function () {
			this.style.border = "none";
		});

		// Get the basic label, then enhance it
		var label = this.getBasicLabel(anchorOrPosition, input, customConfig);

		var basicShowFunc = label.show;

		label.show = function (anchor) {
			basicShowFunc(anchor);
			input.focus();
		};

		label.mr_isInputLabel = true;

		input.focus();

		return label;
	}

	getPointOnLineByPercent(start, end, percent) {
		start = this.normalizePoint(start);
		end = this.normalizePoint(end);

		// Midpoint
		if (!percent) percent = 0.5;

		return google.maps.geometry.spherical.interpolate(start, end, percent);
	}

	convertPointsToLines(points, forceLoop) {
		points = points.slice();
		var lines = [];

		if (forceLoop)
			lines.push([
				this.normalizePoint(points[0]),
				this.normalizePoint(points[points.length - 1])
			]);

		while (points.length > 1) {
			points[0] = this.normalizePoint(points[0]);
			points[1] = this.normalizePoint(points[1]);
			lines.push([points.shift(), points[0]]);
		}

		return lines;
	}

	getClosestPointFromLines(lines, target, offset) {
		if (!target) target = this.map.getCenter();

		var self = this;

		lines.map(function (line) {
			return [self.normalizePoint(line[0]), self.normalizePoint(line[1])];
		});

		if (lines.length === 1)
			return this.getPointOnLineNearTarget(lines[0][0], lines[0][1], {
				target: target,
				offset: offset
			});

		var self = this;

		var lowestPtDistance = lines.reduce(function (collector, line) {
			var closestPoint = self.getPointOnLineNearTarget(line[0], line[1], {
				target: target,
				offset: offset
			});
			var distance = self.getDistance(closestPoint, target);

			if (!collector.distance || collector.distance >= distance) {
				collector = closestPoint;
				collector.distance = distance;
			}

			return collector;
		}, {});

		return lowestPtDistance;
	}

	updateOverlayMeta(overlay, options) {
		options = this.applyOptionsSchema(options, [
			"isReportOverlay",
			"positionOnly",
			"forceUpdate"
		]);

		// "measurements" will be used in each switch case.
		// After the switch case, labels will be updated with
		// normalized unit values

		// measurements schema
		// [...
		//     {
		//         valueToNorm: Number, // number to normalize (in meters)
		//         label: Object, // label to give the value to
		//         suffix: String, // suffix to give for the normalized value
		//         mutateAfter: Function // a function to mutate the value after it's been normalized
		//     }
		// ...]
		var measurements = [];

		switch (overlay.mr_type) {
			case "circle":
				// Radius line
				var center = overlay.getCenter();
				var radiusLinePoints = [
					center,
					google.maps.geometry.spherical.computeOffset(
						center,
						overlay.getRadius(),
						90
					)
				];

				if (!overlay.mr_meta || !overlay.mr_meta.line) break;

				overlay.mr_meta.line.setPath(radiusLinePoints);

				var circleRadius = overlay.getRadius();

				// Radius label
				if (options.forceUpdate || overlay.mr_meta.radiusLabel.map) {
					// this.updateLineLabelPosition returns the points
					this.updateLineLabelPosition(
						overlay.mr_meta.line,
						overlay.mr_meta.radiusLabel,
						{ offset: { left: 105 } }
					);

					if (!options.positionOnly) {
						measurements.push({
							valueToNorm: circleRadius,
							label: overlay.mr_meta.radiusLabel
						});
					}
				}

				// Area label
				if (options.forceUpdate || overlay.mr_meta.areaLabel.map) {
					overlay.mr_meta.areaLabel.setPosition(overlay.getCenter());

					if (!options.positionOnly) {
						measurements.push({
							valueToNorm: circleRadius,
							mutateAfter: function (radius) {
								return Math.PI * Math.pow(radius, 2);
							},
							label: overlay.mr_meta.areaLabel,
							suffix: " <sup>2</sup>"
						});
					}
				}
				break;
			case "polygon":
				var paths = overlay.getPaths();
				var points = [];

				paths.forEach(function (path, i) {
					points = points.concat(path.getArray());
				});

				var lines = this.convertPointsToLines(points, true);
				var perimeter = this.calculatePerimeterFromLines(lines);

				// Perimeter label
				if (options.forceUpdate || overlay.mr_meta.perimeterLabel.map) {
					var closestPoint = this.getClosestPointFromLines(lines);

					overlay.mr_meta.perimeterLabel.setPosition(closestPoint);

					if (!options.positionOnly) {
						measurements.push({
							valueToNorm: perimeter,
							label: overlay.mr_meta.perimeterLabel
						});
					}
				}

				// Area label
				if (options.forceUpdate || overlay.mr_meta.areaLabel.map) {
					var centroid = this.getCentroidFromPoints(points);

					overlay.mr_meta.areaLabel.setPosition(centroid);

					var self = this;

					if (!options.positionOnly) {
						measurements.push({
							valueToNorm: perimeter,
							matchUnits: function (units) {
								var fixedUnits;

								if (units === "feet") {
									fixedUnits = 0;
									units = "sqfeet";
								} else if (units === "miles") {
									fixedUnits = 2;
									units = "sqmiles";
								}

								return self.convertUnits(
									google.maps.geometry.spherical.computeArea(points),
									"sqmeters",
									units,
									fixedUnits
								);
							},
							label: overlay.mr_meta.areaLabel,
							suffix: " <sup>2</sup>"
						});
					}
				}
				break;
			case "polyline":
				if (options.forceUpdate || overlay.mr_meta.label.map) {
					var offset = false;

					if (options.isReportOverlay)
						offset = { left: "marker", right: "marker" };

					var points = overlay.mr_meta
						? this.updateLineLabelPosition(overlay, overlay.mr_meta.label, {
								offset: offset
						  })
						: null;

					if (points && !options.positionOnly) {
						var lines = this.convertPointsToLines(points);

						measurements.push({
							valueToNorm: this.calculatePerimeterFromLines(lines),
							label: overlay.mr_meta.label
						});
					}
				}
				break;
			case "rectangle":
				var bounds = overlay.getBounds();

				var ne = bounds.getNorthEast();
				var sw = bounds.getSouthWest();

				var se = this.normalizePoint({ lat: ne.lat(), lng: sw.lng() });
				var nw = this.normalizePoint({ lat: sw.lat(), lng: ne.lng() });

				var points = [nw, ne, se, sw];

				var distanceTop = google.maps.geometry.spherical.computeDistanceBetween(
					nw,
					ne
				);
				var distanceRight = google.maps.geometry.spherical.computeDistanceBetween(
					ne,
					se
				);

				var lines = this.convertPointsToLines(points, true);

				var perimeter = this.calculatePerimeterFromLines(lines);

				// Perimeter label
				if (options.forceUpdate || overlay.mr_meta.perimeterLabel.map) {
					var closestPoint = this.getClosestPointFromLines(lines);

					overlay.mr_meta.perimeterLabel.setPosition(closestPoint);

					if (!options.positionOnly) {
						measurements.push({
							valueToNorm: perimeter,
							label: overlay.mr_meta.perimeterLabel
						});
					}
				}

				// Area label
				if (options.forceUpdate || overlay.mr_meta.areaLabel.map) {
					overlay.mr_meta.areaLabel.setPosition(bounds.getCenter());

					var self = this;

					if (!options.positionOnly) {
						measurements.push({
							valueToNorm: perimeter,
							matchUnits: function (units) {
								var fixedUnits;

								if (units === "feet") {
									fixedUnits = 0;
									units = "sqfeet";
								} else if (units === "miles") {
									fixedUnits = 2;
									units = "sqmiles";
								}

								return self.convertUnits(
									distanceTop * distanceRight,
									"sqmeters",
									units,
									fixedUnits
								);
							},
							label: overlay.mr_meta.areaLabel,
							suffix: " <sup>2</sup>"
						});
					}
				}
				break;
		}

		// Now normalize the values collected in measurements.
		// If any measurements are below 1 mile, then both get
		// converted to feet

		// using for loops here for speed

		for (var i = 0; i < measurements.length; ++i) {
			var measureItem = measurements[i];
			var normedVals = this.normalizeUnits(measureItem.valueToNorm);

			if (measureItem.mutateAfter) {
				normedVals.forEach(function (normedVal) {
					normedVal.value = measureItem.mutateAfter(normedVal.value);

					if (normedVal.units === "ft")
						normedVal.value = Math.round(normedVal.value);
					else if (normedVal.units === "mi")
						normedVal.value = normedVal.value.toFixed(2);
				});
			}

			if (measureItem.matchUnits) {
				normedVals.forEach(function (normedVal) {
					var units;

					if (normedVal.units === "mi") {
						units = "miles";
					}

					if (normedVal.units === "ft") {
						units = "feet";
					}

					normedVal.value = measureItem.matchUnits(units);
				});
			}

			var labelContent = [];

			normedVals.forEach(function (normedVal) {
				var str = String(normedVal.value) + " " + normedVal.units;

				if (measureItem.suffix) {
					str += measureItem.suffix;
				}

				labelContent.push(str);
			});

			labelContent = labelContent.join("<br>");

			measureItem.label.setContent(labelContent);
		}
	}

	updateLineLabelPosition(line, label, options) {
		options = this.applyOptionsSchema(options, ["target", "offset"]);

		if (!label || !label.map) return;

		if (!options.target) options.target = this.map.getCenter();

		var points = line.getPath().getArray();

		if (points.length === 2) {
			var closestPoint = this.getPointOnLineNearTarget(points[0], points[1], {
				target: options.target,
				offset: options.offset
			});
		} else {
			var lines = this.convertPointsToLines(points);
			var closestPoint = this.getClosestPointFromLines(
				lines,
				options.target,
				options.offset
			);
		}

		label.setPosition(closestPoint);

		return points;
	}

	getPointOnLineNearTarget(lineStart, lineEnd, options) {
		options = this.applyOptionsSchema(options, ["target", "offset"]);

		if (!options.target) options.target = this.map.getCenter();

		// Offset start and end points to prevent the label from covering up markers and other things
		var markerOffset = 65;

		lineStart = this.normalizePoint(lineStart);
		lineEnd = this.normalizePoint(lineEnd);

		if (options.offset) {
			var distanceMeters = google.maps.geometry.spherical.computeDistanceBetween(
				lineStart,
				lineEnd
			);

			if (options.offset.left) {
				if (options.offset.left === "marker")
					options.offset.left = markerOffset;

				var leftPercentage = options.offset.left / distanceMeters;

				lineStart = this.getPointOnLineByPercent(
					lineStart,
					lineEnd,
					leftPercentage
				);
			}

			if (options.offset.right) {
				if (options.offset.right === "marker")
					options.offset.right = markerOffset;

				var rightPercentage =
					(distanceMeters - options.offset.right) / distanceMeters;

				lineEnd = this.getPointOnLineByPercent(
					lineStart,
					lineEnd,
					rightPercentage
				);
			}
		}

		// Adapted from the python function found here!
		// http://gis.stackexchange.com/questions/108117/closest-point-on-a-line-segment-from-point-outside-the-line#answer-108364

		var A = lineStart;
		var B = lineEnd;

		var A0 = A.lat();
		var A1 = A.lng();
		var B0 = B.lat();
		var B1 = B.lng();

		var p = options.target;

		var p0 = p.lat();
		var p1 = p.lng();

		var AB0 = B0 - A0;
		var AB1 = B1 - A1;

		var closestPoint;

		var AB_squared = AB0 * AB0 + AB1 * AB1 + 0.0;

		if (AB_squared == 0) {
			closestPoint = A;
		} else {
			var Ap0 = p0 - A0;
			var Ap1 = p1 - A1;
			var t = (Ap0 * AB0 + Ap1 * AB1) / AB_squared;

			if (t < 0) closestPoint = { lat: A0, lng: A1 };
			else if (t > 1) closestPoint = { lat: B0, lng: B1 };
			else closestPoint = { lat: A0 + t * AB0, lng: A1 + t * AB1 };
		}

		closestPoint = this.normalizePoint(closestPoint);

		return closestPoint;
	}

	calculatePerimeterFromLines(lines) {
		var perimeter = 0;
		var self = this;

		lines.forEach(function (line) {
			perimeter += self.getDistance(line[0], line[1]);
		});

		return perimeter;
	}

	getDistance(start, end) {
		var startLatlng = this.normalizePoint(start);
		var endLatlng = this.normalizePoint(end);

		if (!startLatlng || !endLatlng) return 0;

		return google.maps.geometry.spherical.computeDistanceBetween(
			startLatlng,
			endLatlng
		); // distance in metres rounded to 1dp
	}

	getCentroidFromPoints(points) {
		var centroid = { lat: 0, lng: 0 };
		var self = this;

		points.forEach(function (point) {
			point = self.normalizePoint(point);
			centroid.lat += point.lat();
			centroid.lng += point.lng();
		});

		centroid.lat /= points.length;
		centroid.lng /= points.length;

		return this.normalizePoint(centroid);
	}

	addListeners(listenObj, listenerTypes, handler) {
		var removeListenerHandles = [];

		listenerTypes.forEach(function (listenerType) {
			removeListenerHandles.push(
				google.maps.event.addListener(listenObj, listenerType, handler)
			);
		});

		return removeListenerHandles;
	}

	convertUnits(val, inputUnits, outputUnits, roundToXDecimals) {
		var convertedVal;

		if (inputUnits === outputUnits) {
			if (typeof roundToXDecimals !== "undefined") {
				if (roundToXDecimals === 0) convertedVal = Math.round(val);
				else convertedVal = val.toFixed(roundToXDecimals);
			}

			return convertedVal;
		}

		switch (inputUnits) {
			// -> feet
			case "feet":
				switch (outputUnits) {
					case "miles":
						convertedVal = val / 5280;
						break;
					case "meters":
						convertedVal = val / 3.28084;
						break;
				}
				break;
			// -> meters
			case "meters":
				switch (outputUnits) {
					// -> meters : miles ->
					case "miles":
						convertedVal = val / 1609.344;
						break;
					// -> meters : feet ->
					case "feet":
						convertedVal = val * 3.28084;
						break;
				}
				break;
			// -> miles
			case "miles":
				switch (outputUnits) {
					// -> miles : feet ->
					case "feet":
						convertedVal = val * 5280;
						break;
					case "meters":
						convertedVal = val * 1609.34;
						break;
				}
				break;
			// -> skwimmiters
			case "sqmeters":
				switch (outputUnits) {
					case "sqfeet":
						convertedVal = val * 10.7639;
						break;
					case "sqmiles":
						convertedVal = (val / 1000000) * 0.3861000002314;
						break;
				}
		}

		if (typeof roundToXDecimals !== "undefined") {
			if (roundToXDecimals === 0) convertedVal = Math.round(convertedVal);
			else convertedVal = convertedVal.toFixed(roundToXDecimals);
		}

		return convertedVal;
	}

	normalizeUnits(val) {
		// NOTE: val is assumed to be in meters, the Google maps default
		var vals = [
			{
				value: this.convertUnits(val, "meters", "miles", 2),
				units: "mi"
			},
			{
				value: this.convertUnits(val, "meters", "feet", 0),
				units: "ft"
			}
		];

		return vals;
	}

	normalizePoint(point) {
		if (point == null) return null;

		if (point instanceof google.maps.LatLng) return point;

		return new google.maps.LatLng(point.lat, point.lng);
	}

	applyOptionsSchema(options, schema) {
		options = options || {};

		schema.forEach(function (schemaKey) {
			if (!options[schemaKey]) options[schemaKey] = false;
		});

		return options;
	}

	convertBoundsToPoints(bounds) {
		var ne = bounds.getNorthEast();
		var sw = bounds.getSouthWest();

		var se = this.normalizePoint({ lat: ne.lat(), lng: sw.lng() });
		var nw = this.normalizePoint({ lat: sw.lat(), lng: ne.lng() });

		return [nw, ne, se, sw, nw];
	}

	toStaticMapUrl() {
		var delimiter = "|";
		var markerLabelLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var markerLabelNumbers = "123456789";
		var markerStyles = "size:mid";

		var params = {};

		function addUrlMarker(markerLocation, markerLabelValue, isMetaLabel) {
			if (!params.markers) {
				params.markers = [];
			}

			var newMarker = "";

			if (markerLabelValue) {
				if (!params.markerLabels) {
					params.markerLabels = "";
				} else {
					params.markerLabels += "--amp--";
				}

				var markerLabel = markerLabelLetters[0];

				markerLabelLetters = markerLabelLetters.substr(1);
				params.markerLabels += markerLabel + "=" + markerLabelValue;

				newMarker +=
					"label:" + markerLabel + delimiter + markerStyles + delimiter;

				if (isMetaLabel) {
					newMarker += "color:yellow" + delimiter + markerStyles + delimiter;
				}
			}

			newMarker += markerLocation;

			params.markers = params.markers.concat(newMarker);
		}

		function addPath(latLngs, closedPath, lineColor, fillColor, opacity) {
			if (!params.path) {
				params.path = [];
			}

			if (!lineColor) {
				var lineColor = "000000";
			}

			if (!fillColor) {
				var fillColor = "000000";
			}

			if (!opacity) {
				var opacity = "50";
			}

			var newPath = "";

			if (closedPath) {
				newPath += "fillcolor:0x" + fillColor + opacity + delimiter;
			}

			if (lineColor) newPath += "color:0x" + lineColor + delimiter;

			newPath += "weight:2" + delimiter + latLngs;

			params.path = params.path.concat(newPath);
		}

		var self = this;

		function overlaysToUrlValue(overlayCollection) {
			Object.keys(overlayCollection).forEach(function (key) {
				overlayCollection[key].forEach(function (overlay) {
					if (!overlay.map) return;

					// Handle main overlay. Next is it's meta
					switch (key) {
						case "marker":
							var markerPosition = overlay.getPosition().toUrlValue();

							// Need to check if marker has a label
							var label = overlay.mr_meta.label;

							if (label.map)
								addUrlMarker(
									markerPosition,
									"Marker - " + label.getContent().value
								);
							else if (overlay.mr_pdfLabel)
								addUrlMarker(markerPosition, "Marker - " + overlay.mr_pdfLabel);
							else addUrlMarker(markerPosition);

							break;

						case "polyline":
							var lineColor = null;

							if (overlayCollection === self.reportOverlays)
								lineColor = "ff0000";

							var urlValues = overlay
								.getPath()
								.getArray()
								.map(function (point) {
									return point.toUrlValue();
								})
								.join(delimiter);

							addPath(urlValues, null, lineColor);

							var label = overlay.mr_meta.label;

							if (label.map)
								addUrlMarker(
									label.getPosition().toUrlValue(),
									"Line - " + label.getContent(),
									true
								);

							break;
						case "rectangle":
							var urlValues = self
								.convertBoundsToPoints(overlay.getBounds())
								.map(function (point) {
									return point.toUrlValue();
								})
								.join(delimiter);

							addPath(urlValues, true, "000000");
							self.updateOverlayMeta(overlay, { forceUpdate: true });

							var areaLabel = overlay.mr_meta.areaLabel;
							var labelContent =
								"Area: " +
								areaLabel.getContent() +
								", Perimeter: " +
								overlay.mr_meta.perimeterLabel.getContent();

							labelContent = labelContent.replace(
								new RegExp("<sup>2</sup>", "g"),
								"(squared)"
							);
							addUrlMarker(
								areaLabel.getPosition().toUrlValue(),
								"Rectangle - " + labelContent,
								true
							);

							break;
						case "polygon":
							overlay
								.getPaths()
								.getArray()
								.map(function (path) {
									return path.getArray();
								})
								.map(function (pathArr) {
									pathArr = pathArr.map(function (point) {
										return point.toUrlValue();
									});

									pathArr.push(pathArr[0]);

									return pathArr;
								})
								.forEach(function (pathArr) {
									addPath(pathArr.join(delimiter), true, "000000");
								});

							self.updateOverlayMeta(overlay, { forceUpdate: true });

							var areaLabel = overlay.mr_meta.areaLabel;
							var labelContent =
								"Area: " +
								areaLabel.getContent() +
								", Perimeter: " +
								overlay.mr_meta.perimeterLabel.getContent();

							labelContent = labelContent.replace(
								new RegExp("<sup>2</sup>", "g"),
								"(squared)"
							);
							addUrlMarker(
								areaLabel.getPosition().toUrlValue(),
								"Polygon - " + labelContent,
								true
							);

							break;
						case "circle":
							var c = overlay.getCenter();
							var cx = c.lat();
							var cy = c.lng();

							var r = overlay.getRadius();

							var circlePoints = "";

							for (var i = 0; i < 361; i += 6) {
								var circumLatLng = google.maps.geometry.spherical.computeOffset(
									c,
									r,
									i
								);
								circlePoints +=
									circumLatLng.lat().toFixed(6) +
									"," +
									circumLatLng.lng().toFixed(6);

								if (i < 360) circlePoints += delimiter;
							}

							addPath(circlePoints, true, "000000");

							self.updateOverlayMeta(overlay, { forceUpdate: true });

							var areaLabel = overlay.mr_meta.areaLabel;
							var labelContent =
								"Area: " +
								areaLabel.getContent() +
								", Radius: " +
								overlay.mr_meta.radiusLabel.getContent();

							labelContent = labelContent.replace(
								new RegExp("<sup>2</sup>", "g"),
								"(squared)"
							);
							addUrlMarker(
								areaLabel.getPosition().toUrlValue(),
								"Circle - " + labelContent,
								true
							);
							break;
						/**
						 * radii type currently only used with custom radius overlays for certain aggregate report types
						 * Potentially could just use 'circle' type, with checks for the existence of a label, as we don't
						 * want markers or labels to be added to the PDF for each radius
						 * 20181025 -CA
						 */
						/*case "radii":
							var c = overlay.getCenter();
							var cx = c.lat();
							var cy = c.lng();
							var r = overlay.getRadius();
							var circlePoints = "";

							for (var i = 0; i < 361; i += 6) {
								circumLatLng = google.maps.geometry.spherical.computeOffset(c, r, i);
								circlePoints += circumLatLng.lat().toFixed(6) + "," + circumLatLng.lng().toFixed(6);

								if (i < 360) circlePoints += delimiter;
							}

							addPath(circlePoints, true, "000000", "000000", "00");

							self.updateOverlayMeta(overlay, { forceUpdate: true });

							break;*/
					}
					// Now handle the overlay's meta
					switch (key) {
						case "marker":
							var label = overlay.mr_meta.label;

							if (label.map) {
								if (label.mr_isInputLabel) {
									label.getContent().value;
								} else {
									///////////////
								}
							}
							break;
						case "polyline":
							break;
						case "rectangle":
							break;
						case "polygon":
							break;
						case "circle":
							break;
					}
				});
			});
		}

		if (this.map.getStreetView().getVisible()) {
			var streetView = this.map.getStreetView();
			var streetPov = streetView.getPov();

			// params.location = streetView.getLocation();
			params.pano = streetView.getPano();
			params.heading = streetPov.heading;
			params.pitch = streetPov.pitch;
			// unused by pano, but pdf dies without these params
			params.center =
				this.currentPoi.latitude + "," + this.currentPoi.longitude;
			params.zoom = this.map.getZoom();
		} else {
			params.center =
				this.currentPoi.latitude + "," + this.currentPoi.longitude;
			params.zoom = this.map.getZoom();
			params.maptype = this.map.getMapTypeId();

			// Add values to params based on overlays
			overlaysToUrlValue(this.reportOverlays);
			overlaysToUrlValue(this.userOverlays);
		}

		// --amp-- must be replaced with '&' on the server.
		// This is used so these params don't get broken up into separate items by the express parser

		var compiledUrlParams = Object.keys(params).reduce(function (
			collector,
			paramKey,
			i
		) {
			if (i !== 0) collector += "--amp--";

			switch (paramKey) {
				case "markers":
					params[paramKey].forEach(function (markerStr, j) {
						collector += "markers=" + markerStr;

						if (j < params[paramKey].length - 1) collector += "--amp--";
					});

					return collector;
				case "path":
					params[paramKey].forEach(function (pathStr, j) {
						collector += "path=" + pathStr;

						if (j < params[paramKey].length - 1) collector += "--amp--";
					});

					return collector;
				case "markerLabels":
					return collector;
			}

			collector += paramKey + "=" + params[paramKey];

			return collector;
		},
		"");

		if (params.markerLabels)
			compiledUrlParams += "--labels--" + params.markerLabels;

		return compiledUrlParams;
	}

	createPoiRadius(poi, radius, radiusUnits, reportId, clickable) {
		var self = this;
		var min = radius.min;
		var max = radius.max;
		var circle;

		// convert units to meters
		switch (radiusUnits) {
			case "kilometers":
				min = min * 1000;
				max = max * 1000;
				break;

			case "feet":
				min = min * 0.3048;
				max = max * 0.3048;
				break;

			case "miles":
				min = min * 1609.34;
				max = max * 1609.34;
				break;
		}

		circle = new google.maps.Circle({
			clickable: false,
			strokeColor: "#000000",
			strokeOpacity: 0.8,
			strokeWeight: 5,
			fillColor: "#6d45b2",
			fillOpacity: 0,
			center: {
				lat: poi.latitude,
				lng: poi.longitude
			},
			radius: max,
			originalRadius: radius.max,
			mr_type: "radii",
			reportId: reportId
		});

		return circle;
	}

	drawPoiRadii(id) {
		var self = this;
		var radiusFound = false;

		if (typeof self.radiusOverlays[id] === "undefined") {
			return;
		}

		self.radiusOverlays[id].radii = self.radiusOverlays[id].radii.reverse();

		for (var i = 0; i < self.radiusOverlays[id].radii.length; i++) {
			if (typeof self.radiusOverlays[id].radii[i].overlay !== "undefined") {
				if (self.reportOverlays && self.reportOverlays.radii) {
					self.reportOverlays.radii.forEach(function (overlay) {
						if (self.radiusOverlays[id].radii[i].radius == overlay.radius) {
							radiusFound = true;
						}
					});
				}

				if (!radiusFound) {
					self.addReportOverlay(
						self.radiusOverlays[id].radii[i].overlay,
						false
					);
				}
			}
		}
	}

	clearPoiRadii(id) {
		var self = this;

		if (typeof id !== "undefined") {
			if (typeof self.radiusOverlays[id] === "undefined") {
				return;
			}

			for (var i = self.radiusOverlays[id].radii.length; i--; ) {
				var el = self.radiusOverlays[id].radii[i];

				if (typeof el.overlay !== "undefined") {
					self.reportOverlays.radii.splice(
						self.reportOverlays.radii.indexOf(el.overlay),
						1
					);
					self.removeOverlay(el.overlay);
				}
			}
		} else {
			for (var radiusOverlay in self.radiusOverlays) {
				for (var i = self.radiusOverlays[radiusOverlay].radii.length; i--; ) {
					var el = self.radiusOverlays[radiusOverlay].radii[i];

					if (typeof el.overlay !== "undefined") {
						self.reportOverlays.radii.splice(
							self.reportOverlays.radii.indexOf(el.overlay),
							1
						);
						self.removeOverlay(el.overlay);
					}
				}
			}
		}
	}

	activateOverlay(active, overlay) {
		let self = this;

		if (active) {
			this.map.overlayMapTypes.insertAt(0, overlay.overlay);
			this.enabledOverlayIds;
		} else {
			let layerIndices = [];
			this.map.overlayMapTypes.forEach((obj, index) => {
				if (obj.name === overlay.domId) {
					layerIndices.push(index);
				}
			});

			layerIndices.forEach((index) => {
				self.map.overlayMapTypes.removeAt(index);
			});
		}
	}

	getMap() {
		return this.map;
	}

	getMapEl() {
		return this.map.getDiv();
	}

	setMap(el, map) {
		el.setMap(map);
	}

	getMapBounds() {
		return this.map.getBounds();
	}

	getCenter() {
		return {
			lat: this.map.getCenter().lat(),
			lng: this.map.getCenter().lng()
		};
	}

	getZoom() {
		return this.map.getZoom();
	}

	addMapListener(event, callback) {
		return this.map.addListener(event, callback);
	}

	clearMapListeners(el, event) {
		google.maps.event.clearListeners(el, event);
	}

	removeMapListener(listener) {
		google.maps.event.removeListener(listener);
	}

	createLatLng(lat, lng) {
		return new google.maps.LatLng(lat, lng);
	}

	computeDistanceBetween(a, b) {
		return google.maps.geometry.spherical.computeDistanceBetween(a, b);
	}

	getInfoWindow(options) {
		return new google.maps.InfoWindow(options);
	}

	getSymbol(type) {
		return google.maps.SymbolPath[type.toUpperCase()];
	}

	trackOverlayOrder(overlay, active) {
		let i = 0;

		// Remove overlay from stack if it exists
		while (i < this.enabledOverlays.length) {
			let layer = this.enabledOverlays[i];

			if (layer.domId === overlay.domId) {
				this.enabledOverlays.splice(i, 1);
			} else {
				i++;
			}
		}

		// If activated, push overlay back on to stack
		if (active) {
			this.enabledOverlays.push(overlay);
		}
	}
}

module.exports = MapriskController;
