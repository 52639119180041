module.exports = function (configuration, reportId, parameterName) {
	var value = null;

	if (configuration && reportId) {
		if (
			configuration[reportId.toLowerCase()] &&
			configuration[reportId.toLowerCase()].parameters
		) {
			for (
				var i = 0;
				i < configuration[reportId.toLowerCase()].parameters.length;
				i++
			) {
				var parameter = configuration[reportId.toLowerCase()].parameters[i];

				if (parameter.key.toLowerCase() === parameterName.toLowerCase()) {
					value = parameter.value;
					break;
				}
			}
		}
	}

	return value;
};
