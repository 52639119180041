class AccountToolsInterface {
	constructor(accountClient, eventBus) {
		this.accountClient = accountClient;
		this.eventBus = eventBus;
	}

	isInterfaceCreated() {
		return document.getElementById(this.containerId) ? true : false;
	}

	isOpen() {
		return this.container.classList.contains(this.activeClass);
	}

	attachBackdropClickHandler() {
		this.backdrop.addEventListener("click", (e) => {
			e.preventDefault();

			this.closeBackdrop();
			this.closeInterface();
		});
	}

	attachTriggerClickHandler() {
		this.trigger.addEventListener("click", (e) => {
			e.preventDefault();

			this.handleTriggerClick();
		});
	}

	handleTriggerClick() {
		if (this.isOpen()) {
			this.closeBackdrop();
			this.closeInterface();
		} else {
			this.openBackdrop();
			this.openInterface();
		}
	}

	attachCloseButtonClickHandler() {
		this.closeButton.addEventListener("click", (e) => {
			e.preventDefault();
			this.handleCloseButtonClick();
		});
	}

	handleCloseButtonClick() {
		this.closeBackdrop();
		this.closeInterface();
	}

	async openInterface() {
		this.container.classList.add(this.activeClass);
	}

	closeInterface() {
		this.container.classList.remove(this.activeClass);
	}

	openBackdrop() {
		this.backdrop.classList.add(this.activeClass);
	}

	closeBackdrop() {
		this.backdrop.classList.remove(this.activeClass);
	}

	showMessage(message, type) {
		this.message.classList.add(type);
		this.message.innerHTML = message;
		this.message.classList.add("active");
	}

	hideMessage() {
		this.message.classList.remove("error");
		this.message.classList.remove("success");
		this.message.innerHTML = "";
		this.message.classList.remove("active");
	}
}

module.exports = AccountToolsInterface;
