const { eventTracker, getAuthenticationType } = require("../../utils");
const Tabulator = require("tabulator-tables");
const moment = require("moment"); // utilized by Tabulator
const AddUserInterface = require("./add-user-interface.js");
const UserSettingsInterface = require("./user-settings-interface.js");

class UserManager {
	constructor(accountClient, eventBus, company) {
		this.accountClient = accountClient;
		this.eventBus = eventBus;
		this.company = company;

		this.data = null;

		this.activeClass = "active";

		this.headingClassList = ["user-manager-heading"];
		this.headingText = "Manage Users";

		this.triggerId = "user-manager-trigger";
		this.triggerText = "Manage Users";
		this.triggerClassList = [
			"account-tools-link",
			"account-tools-link-manage-users"
		];

		this.containerId = "user-manager-container";
		this.containerClassList = ["user-manager-container"];

		this.tableId = "user-manager-user-table";
		this.tableClassList = ["user-manager-user-table"];
		this.tableColumns = ["lastName", "firstName", "email", "lastLoginDate"];

		this.closeButtonContainerClassList = [
			"user-manager-close-button-container"
		];

		this.closeButtonClassList = ["user-manager-close-button"];

		this.closeButtonIconClassList = ["icon-cancel"];

		this.backdropId = "user-manager-backdrop";
		this.backdropClassList = ["user-manager-backdrop"];

		this.placeholderId = "user-manager-placeholder";
		this.placeholderClassList = ["user-manager-placeholder", "active"];
		this.placeholderText = "No sibling accounts found";

		this.userSettingsClassList = ["user-manager-settings"];
		this.userSettingsIconClassList = ["icon-cog"];

		this.footerClassList = ["user-manager-footer"];

		this.addUserButtonId = "user-manager-add-user-button";
		this.addUserButtonClassList = ["user-manager-add-user-button"];
		this.addUserButtonText = "Add User";

		this.exportCSVButtonId = "user-manager-export-csv-button";
		this.exportCSVButtonClassList = ["user-manager-export-csv-button"];
		this.exportCSVButtonText = "Export Users";

		this.resetFilterButtonId = "user-manager-reset-filter-button";
		this.resetFilterButtonClassList = ["user-manager-reset-filter-button"];
		this.resetFilterButtonText = "Reset Filters";

		this.addUserInterface = new AddUserInterface(
			this.accountClient,
			this.eventBus,
			this.company
		);

		this.userSettingsInterface = new UserSettingsInterface(
			this.accountClient,
			this.eventBus,
			this.company
		);

		this.createInterface();
	}

	isInterfaceCreated() {
		return document.getElementById(this.containerId) ? true : false;
	}

	async createInterface() {
		let self = this;

		if (this.isInterfaceCreated === true) {
			return;
		}

		if (document.getElementById(this.triggerId)) {
			this.trigger = document.getElementById(this.triggerId);
		} else {
			return;
		}

		this.backdrop = document.createElement("div");
		this.backdrop.id = this.backdropId;
		this.backdropClassList.forEach((className) => {
			this.backdrop.classList.add(className);
		});

		this.container = document.createElement("div");
		this.container.id = this.containerId;
		this.containerClassList.forEach((className) => {
			this.container.classList.add(className);
		});

		this.heading = document.createElement("h3");
		this.headingClassList.forEach((className) => {
			this.heading.classList.add(className);
		});
		this.heading.innerText = this.headingText;

		this.placeholder = document.createElement("h4");
		this.placeholderClassList.forEach((className) => {
			this.placeholder.classList.add(className);
		});
		this.placeholder.innerText = this.placeholderText;

		this.closeButtonContainer = document.createElement("div");
		this.closeButtonContainerClassList.forEach((className) => {
			this.closeButtonContainer.classList.add(className);
		});

		this.closeButton = document.createElement("a");
		this.closeButtonClassList.forEach((className) => {
			this.closeButton.classList.add(className);
		});

		this.closeButtonIcon = document.createElement("i");
		this.closeButtonIconClassList.forEach((className) => {
			this.closeButtonIcon.classList.add(className);
		});

		this.closeButton.append(this.closeButtonIcon);
		this.closeButtonContainer.append(this.closeButton);

		this.table = document.createElement("div");
		this.table.id = this.tableId;
		this.tableClassList.forEach((className) => {
			this.table.classList.add(className);
		});

		this.footer = document.createElement("div");
		this.footerClassList.forEach((className) => {
			this.footer.classList.add(className);
		});

		this.footerButtonWrapper = document.createElement("div");
		this.footerButtonWrapper.classList.add(
			"user-manager-footer-button-wrapper"
		);
		this.footerButtonLeftColumnWrapper = document.createElement("div");
		this.footerButtonRightColumnWrapper = document.createElement("div");

		this.exportCSVButton = document.createElement("button");
		this.exportCSVButton.id = this.exportCSVButtonId;
		this.exportCSVButton.setAttribute("type", "button");
		this.exportCSVButtonClassList.forEach((className) => {
			this.exportCSVButton.classList.add(className);
		});
		this.exportCSVButton.innerText = this.exportCSVButtonText;

		this.resetFilterButton = document.createElement("button");
		this.resetFilterButton.id = this.resetFilterButtonId;
		this.resetFilterButton.setAttribute("type", "button");
		this.resetFilterButtonClassList.forEach((className) => {
			this.resetFilterButton.classList.add(className);
		});
		this.resetFilterButton.innerText = this.resetFilterButtonText;

		this.addUserButton = document.createElement("button");
		this.addUserButton.id = this.addUserButtonId;
		this.addUserButton.setAttribute("type", "button");
		this.addUserButtonClassList.forEach((className) => {
			this.addUserButton.classList.add(className);
		});
		this.addUserButton.innerText = this.addUserButtonText;

		this.container.append(this.heading);
		this.container.append(this.placeholder);
		this.container.append(this.closeButtonContainer);
		this.container.append(this.table);

		this.container.append(this.footer);
		this.footer.append(this.footerButtonWrapper);
		this.footerButtonWrapper.append(this.footerButtonLeftColumnWrapper);
		this.footerButtonWrapper.append(this.footerButtonRightColumnWrapper);
		this.footerButtonLeftColumnWrapper.append(this.exportCSVButton);
		this.footerButtonLeftColumnWrapper.append(this.resetFilterButton);
		this.footerButtonRightColumnWrapper.append(this.addUserButton);

		document.body.append(this.backdrop);
		document.body.append(this.container);

		this.attachTriggerClickHandler();
		this.attachCloseButtonClickHandler();
		this.attachBackdropClickHandler();
		this.attachExportCSVButtonClickHandler();
		this.attachResetFilterButtonClickHandler();
		this.attachAddUserButtonClickHandler();

		this.unsubscribeUserAdded = this.eventBus.subscribe(
			"account:changed",
			async () => {
				await self.populateTable();
			}
		);
	}

	attachBackdropClickHandler() {
		this.backdrop.addEventListener("click", (e) => {
			e.preventDefault();

			this.closeBackdrop();
			this.closeInterface();
			this.closeAddUserInterface();
			this.closeUserSettingsInterface();
		});
	}

	attachTriggerClickHandler() {
		this.trigger.addEventListener("click", (e) => {
			e.preventDefault();

			this.handleTriggerClick();
		});
	}

	handleTriggerClick() {
		if (this.isOpen()) {
			this.closeBackdrop();
			this.closeInterface();
		} else {
			this.openBackdrop();
			this.openInterface();
		}
	}

	attachCloseButtonClickHandler() {
		this.closeButton.addEventListener("click", (e) => {
			e.preventDefault();
			this.handleCloseButtonClick();
		});
	}

	handleCloseButtonClick() {
		this.closeBackdrop();
		this.closeInterface();
	}

	attachExportCSVButtonClickHandler() {
		this.exportCSVButton.addEventListener("click", (e) => {
			e.preventDefault();
			this.handleExportCSVButtonClick();
		});
	}

	handleExportCSVButtonClick() {
		if (this?.tableInstance) {
			var date = moment().format("YYYY-MM-DD_HH-mm-ss");

			let label = {
				_id: window?.id,
				request_type: getAuthenticationType()
			};

			eventTracker("user_action", "usermanager:csv:export", label);

			this.tableInstance.download("csv", `active-users-${date}.csv`);
		}
	}

	attachResetFilterButtonClickHandler() {
		this.resetFilterButton.addEventListener("click", (e) => {
			e.preventDefault();

			this.handleResetFilterButtonClick();
		});
	}

	handleResetFilterButtonClick() {
		if (this?.tableInstance) {
			this.tableInstance.clearHeaderFilter();
		}
	}

	attachAddUserButtonClickHandler() {
		this.addUserButton.addEventListener("click", (e) => {
			e.preventDefault();

			this.handleAddUserButtonClick();
		});
	}

	attachUserSettingsLinkClickHandler(el, data) {
		el.addEventListener("click", (e) => {
			e.preventDefault();

			this.openUserSettingsInterface(data);
		});
	}

	handleAddUserButtonClick() {
		this.openAddUserInterface();
	}

	isOpen() {
		return this.container.classList.contains(this.activeClass);
	}

	async openInterface() {
		await this.populateTable();

		this.container.classList.add(this.activeClass);
	}

	closeInterface() {
		this.container.classList.remove(this.activeClass);
	}

	openBackdrop() {
		this.backdrop.classList.add(this.activeClass);
	}

	closeBackdrop() {
		this.backdrop.classList.remove(this.activeClass);
	}

	openAddUserInterface() {
		this.addUserInterface.openBackdrop();
		this.addUserInterface.openInterface();
	}

	closeAddUserInterface() {
		this.addUserInterface.closeBackdrop();
		this.addUserInterface.closeInterface();
	}

	openUserSettingsInterface(data) {
		this.userSettingsInterface.openBackdrop();
		this.userSettingsInterface.openInterface(data);
	}

	closeUserSettingsInterface() {
		this.userSettingsInterface.closeBackdrop();
		this.userSettingsInterface.closeInterface();
	}

	addUserInterfaceIsOpen() {
		return this.addUserInterface.isOpen();
	}

	userSettingsInterfaceIsOpen() {
		return this.userSettingsInterface.isOpen();
	}

	async populateTable() {
		var self = this;

		let callback = function (error, response) {
			self.data = response?.data;

			if (!self.data) {
				if (self.tableInstance) {
					self.tableInstance.destroy();
					self.placeholder.classList.add("active");
				}
			} else {
				self.placeholder.classList.remove("active");

				self.tableInstance = new Tabulator("#" + self.tableId, {
					autoResize: true,
					columns: [
						{
							title: "Last Name",
							field: "lastName",
							sorter: "string",
							width: "20%",
							headerFilter: true,
							headerFilterPlaceholder: "Search..."
						},
						{
							title: "First Name",
							field: "firstName",
							sorter: "string",
							width: "20%",
							headerFilter: true,
							headerFilterPlaceholder: "Search..."
						},
						{
							title: "Email",
							field: "email",
							sorter: "string",
							width: "30%",
							headerFilter: true,
							headerFilterPlaceholder: "Search..."
						},
						{
							title: "Last Login",
							field: "lastLoginDate",
							formatter: (cell, formatterParams, onRendered) => {
								const options = {
									year: "numeric",
									month: "2-digit",
									day: "2-digit",
									hour: "2-digit",
									minute: "2-digit",
									timeZoneName: "short"
								};

								if (cell.getValue()) {
									return new Date(cell.getValue()).toLocaleString(
										"en-US",
										options
									);
								} else {
									return null;
								}
							},
							sorter: "datetime",
							sorterParams: {
								format: "MMMM DD YYYY, h:mm:ss a"
							},
							width: "25%"
						},
						{
							title: "Has Batch Access",
							field: "hasBatchAccess",
							sorter: "boolean",
							width: "0%",
							visible: false,
							download: true
						},
						{
							align: "center",
							formatter: (cell) => {
								let userSettingsLink = document.createElement("a");
								self.userSettingsClassList.forEach((className) => {
									userSettingsLink.classList.add(className);
								});

								let icon = document.createElement("i");
								self.userSettingsIconClassList.forEach((className) => {
									icon.classList.add(className);
								});

								userSettingsLink.append(icon);

								self.attachUserSettingsLinkClickHandler(
									userSettingsLink,
									cell.getData()
								);

								return userSettingsLink;
							},
							headerSort: false,
							width: "5%"
						}
					],
					data: self.data,
					headerFilterDelay: 100,
					height: 450,
					initialFilter: [
						{
							field: "activated",
							headerSort: false,
							type: "=",
							value: true
						}
					],
					layout: "fitColumns",
					layoutColumnsOnNewData: false,
					placeholder: "No Users Found",
					resizableColumns: false,
					sorting: true
				});
			}
		};

		let siblings = await this.accountClient.getSiblings(callback);
	}
}

module.exports = UserManager;
