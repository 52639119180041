module.exports = (reportId, layerConfig) => {
	let response = {
		wms: "",
		wfs: ""
	};

	if (!reportId || !layerConfig) {
		return response;
	}

	let viewParams = {};

	if (window?.mrState?.layerMultiselects?.[reportId]?.layers) {
		viewParams = layerConfig.params;
	}

	if (viewParams) {
		let layerFilterString = "";

		for (let param in viewParams) {
			layerFilterString += param + ":" + viewParams[param] + ";";
		}

		if (layerFilterString) {
			response.wms = "&viewParams=" + layerFilterString;
			response.wfs = layerFilterString.split("-").join("%2D");
		}
	}

	return response;
};
