class MapClickDetection {
	constructor(state, targetId) {
		if (!!MapClickDetection.instance) {
			return MapClickDetection.instance;
		}

		this.state = state;
		this.targetId = targetId;

		if (!this.targetId) {
			throw new Error("No targetId specified in options");
		}

		if (!document.getElementById(this.targetId)) {
			throw new Error("targetId element specified does not exist in DOM");
		}

		MapClickDetection.instance = this;
		return this;
	}

	attachClickListener(reportId) {
		let self = this;

		if (self.state.reports[reportId] && self.state.reports[reportId].listener) {
			return;
		} else {
			self.state.reports[reportId] = {};
			self.state.reports[
				reportId
			].listener = window.mapRiskController.map.addListener("click", function (
				e
			) {
				var lat = e.latLng.lat();
				var lng = e.latLng.lng();

				if (!self.state.reports[reportId]) {
					self.state.reports[reportId] = {};
				}

				self.state.reports[reportId].lastClickLocation = {
					lat: lat,
					lng: lng
				};

				window.runSearch(lat + "," + lng);
			});
		}
	}

	detachClickListener(reportId) {
		let self = this;

		if (self.state.reports[reportId] && self.state.reports[reportId].listener) {
			google.maps.event.removeListener(self.state.reports[reportId].listener);
			delete self.state.reports[reportId].listener;
		}
	}
}

module.exports = MapClickDetection;
