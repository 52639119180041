module.exports = function (str) {
	return typeof str !== "string" || str.includes(" ")
		? str
		: str
				// insert a space between lower & upper
				.replace(/([a-z])([A-Z])/g, "$1 $2")
				// insert a space between lower & number
				.replace(/([a-z])([0-9])/g, "$1 $2")
				// insert a space between last number in a sequence and upper
				.replace(/\b([0-9]+)([A-Z])([a-z])/g, "$1 $2$3")
				// space before last upper in a sequence followed by lower
				.replace(/\b([A-Z]+)([A-Z])([a-z])/g, "$1 $2$3")
				// insert space between lower and parenthesis
				.replace(/([a-z])([\(])/g, "$1 $2")
				// uppercase the first character
				.replace(/^./, function (str) {
					return str.toUpperCase();
				});
};
