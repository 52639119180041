const TooltipsClient = require("../../clients/tooltips-client.js");
const { eventTracker, extend, getAuthenticationType } = require("../../utils/");

class Tooltips {
	constructor(options) {
		this.options = {
			bucketId: "mr-tooltip-bucket",
			bucketBaseClass: "mr-tooltip-bucket",
			bucketParentEl: "body",
			tooltipBaseClass: "mr-tooltip",
			pageWrapperBaseClass: "mr-tooltip-wrapper",
			containerBaseClass: "mr-tooltip-container",
			headerBaseClass: "mr-tooltip-header",
			headingBaseClass: "mr-tooltip-heading",
			contentBaseClass: "mr-tooltip-content",
			triggerBaseClass: "mr-tooltip-trigger",
			closeButtonBaseClass: "mr-tooltip-close",
			overlayBaseClass: "mr-tooltip-overlay",
			maskBaseClass: "mr-tooltip-mask",
			attachToAttribute: "data-mr-tooltip-attach-to",
			positionAttribute: "data-mr-tooltip-position",
			highlightElementAttribute: "data-mr-tooltip-highlight-element",
			idAttribute: "data-mr-tooltip-id",
			contentAttribute: "data-mr-tooltip-content",
			contentTypeAttribute: "data-mr-tooltip-content-type",
			typeAttribute: "data-mr-tooltip-type",
			fileNotFoundPath: ""
		};

		this.currentTooltips = {};

		if (options) {
			this.options = extend.deepmerge(this.options, options);
		}

		try {
			this.createBucket();
		} catch (error) {
			console.error(error);
		}

		this.attachTriggerClickListener();
	}

	createBucket() {
		if (
			this.options.bucketId &&
			this.options.bucketBaseClass &&
			this.options.bucketParentEl
		) {
			if ($("#" + this.options.bucketId).length <= 0) {
				var $bucket = $(
					'<div id="' +
						this.options.bucketId +
						'" class="' +
						this.options.bucketBaseClass +
						'"></div>'
				);
				$(this.options.bucketParentEl).append($bucket);
			}
		} else {
			throw new Error(
				'mrTooltips says: "bucketId, bucketBaseClass, and bucketParentEl required"'
			);
		}
	}

	attachTriggerClickListener() {
		let self = this;

		$(document).on(
			"click.mr-tooltip",
			"." + this.options.triggerBaseClass,
			function (e) {
				e.preventDefault();

				let parameters = {
					content: $(this).attr(self.options.contentAttribute),
					type: $(this).attr(self.options.typeAttribute),
					contentType: $(this).attr(self.options.contentTypeAttribute),
					id: $(this).attr(self.options.idAttribute),
					attachTo: $(this)?.attr(self.options.attachToAttribute),
					position: $(this).attr(self.options.positionAttribute),
					highlightElement: $(this).attr(self.options.highlightElementAttribute)
				};

				switch (parameters.contentType.toLowerCase()) {
					case "image":
						self.create(parameters);
						break;
					case "html":
					default:
						self.fetch(parameters);
				}
			}
		);
	}

	async create(parameters, data) {
		let self = this;
		let $container = $("<div></div>");

		$container.addClass(this.options.containerBaseClass);
		$container.attr(this.options.attachToAttribute, parameters.attachToElement);
		$container.attr(this.options.positionAttribute, parameters.position);
		$container.attr(
			this.options.highlightElementAttribute,
			parameters.highlightElement
		);
		$container.attr(this.options.typeAttribute, parameters.type);
		$container.attr(this.options.contentTypeAttribute, parameters.contentType);
		$container.attr(this.options.idAttribute, parameters.id);

		switch (parameters.contentType.toLowerCase()) {
			case "image":
				let image = JSON.parse(parameters.content);
				let $tooltip = $(
					`<div class="${this.options.tooltipBaseClass}"></div>`
				);
				let $wrapper = $(
					`<div class="${this.options.pageWrapperBaseClass}"></div>`
				);
				let $header = $(`<div class="${this.options.headerBaseClass}">`);
				let $heading = $(
					"<h2 class='" +
						this.options.headingBaseClass +
						"'>" +
						image.title +
						"</h2>"
				);
				let $img = $("<img>");

				let $content = $("<div class='" + self.options.contentBaseClass + "'>");
				$header.append($heading);
				$wrapper.append($header);
				$wrapper.append($content);
				$tooltip.append($wrapper);

				$img.on("load", function () {
					if (this.complete && this.naturalHeight >= 15) {
						$content.append($img);
						self.appendTooltip(parameters, $container, $tooltip);
					} else {
						window.mapUNC.pushMessage(
							window.mrUserNotifications.legendNotFound,
							"error"
						);

						let label = {
							_id: window.id,
							tooltip_id: parameters.id,
							request_type: getAuthenticationType()
						};

						eventTracker("tooltips", "legend.error", label);
					}
				});

				$img.on("error", function () {
					window.mapUNC.pushMessage(
						window.mrUserNotifications.legendNotFound,
						"error"
					);

					let label = {
						_id: window.id,
						tooltip_id: parameters.id,
						request_type: getAuthenticationType()
					};

					eventTracker("tooltips", "legend.error", label);
				});

				$img.attr("src", image.url);
				break;
			case "html":
			default:
				self.appendTooltip(parameters, $container, data);
		}
	}

	appendTooltip(parameters, $container, data) {
		switch (parameters.contentType.toLowerCase()) {
			case "image":
				$container.append(data);
				break;
			case "html":
			default:
				$container.html(data);
		}

		if (
			$(
				"." +
					this.options.containerBaseClass +
					"[" +
					this.options.typeAttribute +
					'="' +
					parameters.type +
					'"][' +
					this.options.idAttribute +
					'="' +
					parameters.id +
					'"]'
			).length > 0
		) {
			$(
				"." +
					this.options.containerBaseClass +
					"[" +
					this.options.typeAttribute +
					'="' +
					parameters.type +
					'"][' +
					this.options.idAttribute +
					'="' +
					parameters.id +
					'"]'
			).remove();
		}

		$("#" + this.options.bucketId).append($container[0].outerHTML);

		this.open(parameters);
	}

	open(parameters) {
		var position;
		var $tooltip = $(
			"#" +
				this.options.bucketId +
				" ." +
				this.options.containerBaseClass +
				"[" +
				this.options.typeAttribute +
				'="' +
				parameters.type +
				'"][' +
				this.options.idAttribute +
				'="' +
				parameters.id +
				'"]'
		);

		position = this.setPosition(parameters, $tooltip);

		$tooltip.css({
			top: position.y,
			left: position.x,
			transform: "translate(" + position.translate + ")"
		});

		$tooltip
			.find(".mr-tooltip")
			.append(
				"<a href='#' class='" +
					this.options.closeButtonBaseClass +
					"'><i class='icon-cancel'></i></a>"
			);

		this.createHighlightMask(parameters);

		for (var tooltip in this.currentTooltips) {
			this.close(this.currentTooltips[tooltip]);
			delete this.currentTooltips[tooltip];
		}

		$tooltip.show();

		this.currentTooltips[parameters.type + "-" + parameters.id] = $tooltip;

		this.attachDocumentCloseClickListener($tooltip);
		this.attachCloseButtonClickListener($tooltip);
	}

	close($tooltip) {
		this.destroyHighlightMask(
			$tooltip.attr("data-mr-tooltip-type"),
			$tooltip.attr("data-mr-tooltip-id")
		);

		if ($tooltip) {
			$tooltip.hide();
		}

		if (
			this.currentTooltips[
				$tooltip.attr("data-mr-tooltip-type") +
					"-" +
					$tooltip.attr("data-mr-tooltip-id")
			]
		) {
			delete this.currentTooltips[
				$tooltip.attr("data-mr-tooltip-type") +
					"-" +
					$tooltip.attr("data-mr-tooltip-id")
			];
		}

		$($tooltip).off("click.mr-tooltip-close");
	}

	closeById(id) {
		let self = this;
		if (self?.currentTooltips?.[id]) {
			this.close(self.currentTooltips[id]);
		}
	}

	destroy() {
		for (var tooltip in this.currentTooltips) {
			this.currentTooltips[tooltip].cancel();
		}

		this.currentTooltips = {};
		$("#" + this.options.bucketId).empty();
	}

	setPosition(parameters, $tooltip) {
		var position = {
			x: null,
			y: null,
			translate: null
		};

		if (parameters.position) {
			switch (parameters.position) {
				case "top":
					position.translate = "-50%, -100%";
					break;
				case "right":
					position.translate = "0%, -50%";
					break;
				case "bottom":
					position.translate = "-50%, 0%";
					break;
				case "left":
					position.translate = "-100%, -50%";
					break;
				case "right top":
					position.translate = "0%, 0%";
					break;
				default:
					position.x = parameters.position.split(" ")[0];
					position.y = parameters.position.split(" ")[1];
					position.translate = "-50%, -50%";
			}
		} else {
			position.x = "50%";
			position.y = "50%";
			position.translate = "-50%, -50%";
		}

		if (parameters.attachTo) {
			switch (parameters.position) {
				case "top":
					position.x =
						$(parameters.attachTo).offset().left +
						$(parameters.attachTo).outerWidth() / 2;
					position.y = $(parameters.attachTo).offset().top;
					break;
				case "right":
					position.x =
						$(parameters.attachTo).offset().left +
						$(parameters.attachTo).outerWidth();
					position.y =
						$(parameters.attachTo).offset().top +
						$(parameters.attachTo).outerHeight() / 2;
					break;
				case "bottom":
					position.x =
						$(parameters.attachTo).offset().left +
						$(parameters.attachTo).outerWidth() / 2;
					position.y =
						$(parameters.attachTo).offset().top +
						$(parameters.attachTo).outerHeight();
					break;
				case "left":
					position.x = $(parameters.attachTo).offset().left;
					position.y =
						$(parameters.attachTo).offset().top +
						$(parameters.attachTo).outerHeight() / 2;
					break;
			}
		}

		return position;
	}

	createHighlightMask(parameters) {
		if (parameters.highlightElement) {
			var highlightElement = {
				width: null,
				height: null,
				left: null,
				top: null
			};

			highlightElement.width = $(parameters.highlightElement).outerWidth();
			highlightElement.height = $(parameters.highlightElement).outerHeight();
			highlightElement.left = $(parameters.highlightElement).offset().left;
			highlightElement.top = $(parameters.highlightElement).offset().top;

			var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
			svg.setAttribute("version", "1.1");
			svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
			svg.setAttribute("smlns:xlink", "http://www.w3.org/1999/xlink");
			svg.setAttribute(
				"id",
				"mr-tooltip-svg-" + parameters.type + "-" + parameters.id
			);
			svg.classList.add("mr-tooltip-svg-overlay");

			var defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
			svg.appendChild(defs);

			var mask = document.createElementNS("http://www.w3.org/2000/svg", "mask");
			mask.setAttribute("id", "mr-tooltip-svg-mask");
			mask.setAttribute("x", 0);
			mask.setAttribute("y", 0);
			mask.setAttribute("width", "100%");
			mask.setAttribute("height", "100%");

			var maskFill = document.createElementNS(
				"http://www.w3.org/2000/svg",
				"rect"
			);
			maskFill.setAttribute("x", 0);
			maskFill.setAttribute("y", 0);
			maskFill.setAttribute("width", "100%");
			maskFill.setAttribute("height", "100%");
			maskFill.setAttribute("rx", "4");
			maskFill.setAttribute("fill", "#ffffff");
			mask.appendChild(maskFill);

			var maskCutout = document.createElementNS(
				"http://www.w3.org/2000/svg",
				"rect"
			);
			maskCutout.setAttribute("x", highlightElement.left);
			maskCutout.setAttribute("y", highlightElement.top);
			maskCutout.setAttribute("width", highlightElement.width);
			maskCutout.setAttribute("height", highlightElement.height);
			maskCutout.setAttribute("rx", "4");
			maskCutout.setAttribute("fill", "#000000");
			mask.appendChild(maskCutout);

			defs.appendChild(mask);

			var overlay = document.createElementNS(
				"http://www.w3.org/2000/svg",
				"rect"
			);

			overlay.setAttribute("x", 0);
			overlay.setAttribute("y", 0);
			overlay.setAttribute("width", "100%");
			overlay.setAttribute("height", "100%");
			overlay.setAttribute("rx", "4");
			overlay.setAttribute("mask", "url(#mr-tooltip-svg-mask)");
			svg.appendChild(overlay);

			$("body").append(svg);
		}
	}

	destroyHighlightMask(type, id) {
		var svg = document.getElementById("mr-tooltip-svg-" + type + "-" + id);

		if (svg) {
			svg.remove();
		}
	}

	addOverlay($tooltip, overlayOpacity) {
		let self = this;

		var originalOpacity = $("." + this.options.overlayBaseClass).css("opacity");
		$("." + this.options.overlayBaseClass).css("opacity", overlayOpacity);
		$("." + this.options.overlayBaseClass).show();
		$("." + this.options.overlayBaseClass).on(
			"click.mr-tooltip-modal",
			function (e) {
				e.preventDefault();
				self.close($tooltip);
				self.removeOverlay();
				$("." + self.options.overlayBaseClass).css("opacity", originalOpacity);
			}
		);
	}

	removeOverlay(originalOpacity) {
		$("." + this.options.overlayBaseClass).hide();
		$("." + this.options.overlayBaseClass).off("click.mr-tooltip-modal");
	}

	attachDocumentCloseClickListener($tooltip) {}

	attachCloseButtonClickListener($tooltip) {
		let self = this;

		$($tooltip).on(
			"click.mr-tooltip-close",
			"." + this.options.closeButtonBaseClass,
			function (e) {
				e.preventDefault();
				self.close($tooltip);
			}
		);
	}

	getGlossaryUrl(reportId) {
		return `${this.glossaryUrlPath}/${reportId}.html`;
	}

	fetch(parameters) {
		var $requestPromise = $.Deferred();
		var tooltipsClient = new TooltipsClient();
		let self = this;

		$.when($requestPromise)
			.done(function (parameters, data) {
				self.create(parameters, data);
			})
			.fail(function (parameters, err) {
				window.mapUNC.pushMessage(
					window.mrUserNotifications.glossaryNotFound,
					"error"
				);

				let label = {
					_id: window.id,
					tooltip_id: parameters.id,
					request_type: getAuthenticationType()
				};

				eventTracker("tooltips", "glossary.error", label);
			});

		tooltipsClient.getTooltip(parameters, $requestPromise);
	}

	modifyElement($el, id, content, type, contentType, attachTo, position) {
		$el.addClass(this.options.triggerBaseClass);
		$el.attr("data-mr-tooltip-id", id);
		$el.attr("data-mr-tooltip-content", content);
		$el.attr("data-mr-tooltip-type", type);
		$el.attr("data-mr-tooltip-content-type", contentType);
		$el.attr("data-mr-tooltip-attach-to", attachTo);
		$el.attr("data-mr-tooltip-position", position);

		return $el;
	}
}

module.exports = Tooltips;
