const utils = require("../utils/index.js");

var mrMapLabelControl = function () {
	var self = this;

	self.options = {
		containerBaseClass: "mr-map-controls-container",
		containerClassNames: "",
		containerDataType: "label-toggle",
		containerTitle: "Toggle map labels and icons",
		innerContainerBaseClass: "mr-map-controls-container-inner",
		innerContainerClassNames: "",
		triggerBaseClass: "mr-map-controls-trigger",
		triggerClassNames: "",
		triggerContentBaseClass: "mr-map-controls-trigger-content",
		triggerContentType: "text",
		triggerContentHTML: "Labels",
		active: true
	};

	self.stateModel = {
		active: true,
		visible: true,
		enabled: true
	};

	self.state = JSON.parse(JSON.stringify(self.stateModel));

	self.$mapControlContainer = $("<div></div>");
	self.$mapControlInnerContainer = $("<div></div>");
	self.$mapControlTrigger = $("<div></div>");
	self.$mapControlTriggerContent = $("<span></span>");
	self.$mapControlTriggerIcon = $("<i></i>");

	self.init = function (mapController, options) {
		if (!mapController) {
			console.log("MapLabelControl error; no mapController specified");

			return;
		}

		if (options) {
			self.options = utils.extend.deepmerge(self.options, options);
		}

		if (!self.options.parentContainer) {
			console.log(
				"MapLabelControl error: no parent container defined in options."
			);

			return;
		}

		if (!self.options.id) {
			console.log("MapLabelControl error: no id specified.");

			return;
		}

		if ($("#" + self.options.id).length <= 0) {
			self.mapController = mapController;
			self.create();
		} else {
			console.log(
				"MapLabelControl error: Cannot create duplicate MapLabelControl container."
			);

			return;
		}
	};

	self.create = function () {
		self.$mapControlContainer.attr("id", self.options.id);
		self.$mapControlContainer.addClass(
			self.options.containerBaseClass + " " + self.options.containerClassNames
		);
		self.$mapControlContainer.attr("data-type", self.options.containerDataType);
		self.$mapControlContainer.attr("title", self.options.containerTitle);
		self.$mapControlInnerContainer.addClass(
			self.options.innerContainerBaseClass +
				" " +
				self.options.innerContainerClassNames
		);
		self.$mapControlTrigger.addClass(
			self.options.triggerBaseClass + " " + self.options.triggerClassNames
		);
		self.$mapControlTriggerContent.addClass(
			self.options.triggerContentBaseClass
		);
		self.$mapControlTriggerContent.html(self.options.triggerContentHTML);

		self.$mapControlTrigger.append(self.$mapControlTriggerContent);

		self.$mapControlInnerContainer.append(self.$mapControlTrigger);
		self.$mapControlContainer.append(self.$mapControlInnerContainer);

		$(self.options.parentContainer).append(self.$mapControlContainer);

		self.attachTriggerClick();

		if (self.options.active === true) {
			self.toggleLabels(true);
		}
	};

	self.destroy = function () {
		self.$mapControlContainer.remove();
	};

	self.enable = function () {
		self.state.enabled = true;
		self.$mapControlContainer.removeClass("disabled");
	};

	self.disable = function () {
		self.state.enabled = false;
		self.$mapControlContainer.addClass("disabled");
	};

	self.attachTriggerClick = function () {
		self.$mapControlTrigger.on("click", function (e) {
			e.preventDefault();

			if (self.state.enabled === true) {
				self.toggleLabels(this);
			}
		});
	};

	self.toggleLabels = function () {
		var visibility;

		if (self.$mapControlContainer.hasClass("active")) {
			self.state.active = false;
			self.$mapControlContainer.removeClass("active");
			visibility = "off";
		} else {
			self.state.active = true;
			self.$mapControlContainer.addClass("active");
			visibility = "on";
		}

		self.mapController.map.setOptions({
			styles: [
				{
					featureType: "poi",
					stylers: [
						{
							visibility: visibility
						}
					]
				},
				{
					elementType: "labels",
					stylers: [
						{
							visibility: visibility
						}
					]
				},
				{
					featureType: "road",
					stylers: [
						{
							visibility: visibility
						}
					]
				},
				{
					featureType: "transit",
					stylers: [
						{
							visibility: visibility
						}
					]
				}
			]
		});
	};
};

module.exports = mrMapLabelControl;
