class OverlayFacade {
	constructor(overlays, mapController) {
		this.overlays = overlays ?? [];
		this.mapController = mapController;
		this.opacity = 0.75;
		this.active = false;
	}

	getOpacity() {
		return this.opacity;
	}

	setOpacity(opacity) {
		this.overlays.forEach((overlay) => {
			overlay.setOpacity(opacity);
		});

		this.opacity = opacity;
	}

	isActive() {
		return this.active;
	}

	activate(active) {
		this.overlays.forEach((overlay) => {
			overlay.activate(active);

			this.mapController.trackOverlayOrder(overlay, active);
		});

		this.active = active;
	}
}

module.exports = OverlayFacade;
