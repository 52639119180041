const LayerViewModelBase = require("./layer-view-model-base.js");
const MapriskLayerModel = require("../../model/models/maprisk-layer-model.js");

const moment = require("moment");

class CanopyHailLayerViewModel extends LayerViewModelBase {
	constructor(
		eventBus,
		mapController,
		clickDetection,
		report,
		tooltips,
		$parentContainer,
		wfsClient,
		reportConfigurationModel,
		overlayFactory,
		dataView
	) {
		super(
			eventBus,
			mapController,
			clickDetection,
			report,
			tooltips,
			$parentContainer,
			wfsClient,
			reportConfigurationModel,
			overlayFactory,
			dataView
		);

		this.bind(this.$parentContainer);
	}

	bind($el) {
		super.bind($el);
	}

	unbind() {
		super.unbind();
	}

	addLayers(date, buffer, layerConfigs) {
		let self = this;

		this.removeLayers();

		let dateRange = {
			min: moment(date, "YYYY-MM-DD").subtract(buffer, "days"),
			max: moment(date, "YYYY-MM-DD").add(buffer, "days")
		};

		let derivedLayers = [];

		layerConfigs.forEach((layer) => {
			for (
				var m = moment(dateRange.min);
				m.isSameOrBefore(dateRange.max);
				m.add(1, "days")
			) {
				let formattedDate = m.format("YYYY-MM-DD");

				let tmpLayer = layer;
				tmpLayer.displayName = tmpLayer.displayName + " " + formattedDate;
				tmpLayer.params = { convectiveDate: formattedDate };

				derivedLayers.push(
					new MapriskLayerModel(
						self.mapController,
						this.reportConfigurationModel,
						self.clickDetection,
						self.report,
						tmpLayer,
						`${self.reportId}_${tmpLayer.namespace}:${tmpLayer.name}_${formattedDate}`,
						this.wfsClient,
						this.overlayFactory,
						this.dataView
					)
				);
			}
		});

		derivedLayers.forEach((layer) => {
			self.addLayer(layer);
		});
	}

	addLayer(layer) {
		super.addLayer(layer);
	}

	removeLayers() {
		super.removeLayers();
	}
}

module.exports = CanopyHailLayerViewModel;
