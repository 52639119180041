const ServerRenderedOverlayBase = require("./server-rendered-overlay-base");

class ServerRenderedOverlay extends ServerRenderedOverlayBase {
	constructor(
		mapController,
		reportId,
		reportConfigurationModel,
		clickDetection,
		overlayConfiguration,
		baseURL,
		layerId,
		domId
	) {
		super(
			mapController,
			reportId,
			reportConfigurationModel,
			clickDetection,
			overlayConfiguration,
			baseURL,
			layerId,
			domId
		);
	}
}

module.exports = ServerRenderedOverlay;
