const utils = require("../utils/index.js");
const CONSTANTS = require("../../../../RuntimeService/lib/index");
const dialog = require("../dialog/index");
function ReportsClient() {
	let origin = location.protocol + "//" + location.host;
	this.origin = location.origin || origin;
	this.token =
		utils.getAuthTokenFromQueryParam() ||
		utils.getCookie("token") ||
		utils.getCookie("key");

	this.origin = location.origin || origin;
	$.ajaxSetup({
		xhrFields: { withCredentials: true },
		headers: {
			"x-auth-key": this.token,
			source: "website"
		}
	});
}

ReportsClient.prototype.getReports = function (data, callback) {
	var origin = this.origin;

	data.returnPdf = false;
	$.ajax({
		type: "GET",
		url: origin + "/reports",
		data: data,
		timeout: 600000,
		success: function (data) {
			callback(null, data);
		},
		error: function (error) {
			if (error) {
				dialog.openDialog(CONSTANTS.DIALOG.GEOCODE_SERVER_ERROR);
			}
			callback(error, null);
		},
		statusCode: {
			429: function () {
				dialog.openDialog(CONSTANTS.DIALOG.RATE_LIMITED_REPORT);
			}
		}
	});
};

module.exports = ReportsClient;
