function TooltipsClient() {
	let origin = location.protocol + "//" + location.host;
	this.origin = location.origin || origin;

	$.ajaxSetup({
		xhrFields: { withCredentials: true },
		headers: { source: "website" }
	});
}

TooltipsClient.prototype.getTooltip = function (parameters, promise) {
	$.ajax({
		dataType: "html",
		type: "GET",
		url: parameters.content,
		withCredentials: false,
		success: function (data) {
			promise.resolve(parameters, data);
		},
		error: function (err) {
			promise.reject(parameters, err);
		}
	});
};

module.exports = TooltipsClient;
