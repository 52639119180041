const MapriskController = require("./maprisk-controller.js");

class ResultMapController extends MapriskController {
	constructor(options) {
		super(options);
	}

	setMapCenter(poi, offsetX, offsetY, zoom) {
		let map = this.map;
		let latLng = new google.maps.LatLng(poi.lat, poi.lng);
		let point1 = map.getProjection().fromLatLngToPoint(latLng);

		offsetX = offsetX ?? 0;
		offsetY = offsetY ?? 0;
		zoom = zoom ?? -1;

		offsetX = isNaN(offsetX) ? 0 : Number(offsetX);
		offsetY = isNaN(offsetY) ? 0 : Number(offsetY);

		offsetX /= Math.pow(2, zoom);
		offsetY /= Math.pow(2, zoom);

		map.setCenter(
			map
				.getProjection()
				.fromPointToLatLng(
					new google.maps.Point(point1.x - offsetX, point1.y + offsetY)
				)
		);

		if (zoom && zoom > -1) {
			this.setZoom(zoom);
		}
	}
}

module.exports = ResultMapController;
