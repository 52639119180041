const MapriskWFSClient = require("./maprisk-wfs-client");
class WFSFactory {
	constructor(http) {
		this.http = http;
	}

	getWFSClient() {
		return new MapriskWFSClient(this.http);
	}
}

module.exports = WFSFactory;
