const utils = require("../utils/index.js");
require("jquery-form")(window.$);
const CONSTANTS = require("../../../../RuntimeService/lib/index");
const dialog = require("../dialog/index");

const BatchUpload = require("../components/batch-upload/batch-upload");
var batchUpload = new BatchUpload();
function BatchClient(batchUrlBase, path) {
	this.batchUrlBase = batchUrlBase;
	this.path = path;
	this.token =
		utils.getAuthTokenFromQueryParam() ||
		utils.getCookie("token") ||
		utils.getCookie("key");

	$.ajaxSetup({
		xhrFields: { withCredentials: true },
		headers: {
			"x-auth-key": this.token,
			source: "api"
		}
	});
}

BatchClient.prototype.getToken = function () {
	return this.token;
};

BatchClient.prototype.getCookie = function (name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(";");

	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];

		while (c.charAt(0) == " ") {
			c = c.substring(1, c.length);
		}

		if (c.indexOf(nameEQ) == 0) {
			return c.substring(nameEQ.length, c.length);
		}
	}

	return null;
};

BatchClient.prototype.setCookie = function (name, value, days) {
	var expires = "";

	if (days) {
		var date = new Date();

		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toUTCString();
	}

	document.cookie = name + "=" + value + expires + "; path=/";
};

BatchClient.prototype.onRequestComplete = function (token, response, success) {
	var tokenCookie = BatchClient.getCookie("token");

	if (!tokenCookie) BatchClient.setCookie("token", token);

	return success(response);
};

BatchClient.prototype.getAll = function (error, success) {
	var data = {};

	$.ajax({
		cache: false,
		type: "GET",
		url: this.batchUrlBase + this.path,
		data: data,
		timeout: 10000,
		success: function (response) {
			success(response);
		},
		error: error
	});
};

BatchClient.prototype.uploadBatchFile = async function (
	form,
	batchWizardState,
	error,
	success,
	beforeSubmit
) {
	let csrfTokenResponse = await fetch("/account/csrf-token");
	const { token } = await csrfTokenResponse.json();

	let query = {
		portfolioName: batchWizardState?.portfolioName ?? undefined
	};
	var options = {
		cache: false,
		url: `${this.batchUrlBase}${this.path}/upload`,
		success: function (response) {
			success(response);
		},
		error: error,
		beforeSubmit: function (a) {
			beforeSubmit();
		},
		uploadProgress: function (event, position, total, percentComplete) {
			batchUpload.updateStatus(percentComplete);
		}
	};

	if (Object.keys(query).length > 0) {
		options.url += "?";

		for (let key in query) {
			if (query[key]) {
				options.url += `${key}=${query[key]}&`;
			}
		}

		options.url.slice(0, options.url.length - 2);
	}

	if (this.token) {
		options.headers = {
			"x-auth-key": this.token,
			"x-csrf-token": token
		};
	}

	$(form).ajaxSubmit(options);
};

BatchClient.prototype.setupAndStartById = function (batch, error, success) {
	var headers = {};
	if (this.token) {
		headers["x-auth-key"] = this.token;
	}

	$.ajax({
		cache: false,
		type: "POST",
		url: this.batchUrlBase + this.path + "/setup/start/" + batch.id,
		headers: headers,
		data: batch,
		success: function (response) {
			success(response);
		},
		error: error
	});
};

BatchClient.prototype.cancelById = function (batch, error, success) {
	var headers = {};
	if (this.token) {
		headers["x-auth-key"] = this.token;
	}

	$.ajax({
		cache: false,
		type: "POST",
		url: this.batchUrlBase + this.path + "/cancel/" + batch.id,
		headers: headers,
		success: function (response) {
			success(response);
		},
		error: error,
		statusCode: {
			400: function () {
				dialog.openDialog(CONSTANTS.DIALOG.BATCH_ERROR);
			}
		}
	});
};

BatchClient.prototype.deleteById = function (batch, error, success) {
	var headers = {};
	if (this.token) {
		headers["x-auth-key"] = this.token;
	}

	$.ajax({
		cache: false,
		type: "DELETE",
		url: this.batchUrlBase + this.path + "/" + batch.id,
		headers: headers,
		success: function (response) {
			success(response);
			var successResponse = response;
			if (!successResponse) {
				dialog.openDialog(CONSTANTS.DIALOG.BATCH_DELETE_ERROR);
			}
		},
		error: error,
		statusCode: {
			//Status Code 400 = Bad Request
			400: function () {
				dialog.openDialog(CONSTANTS.DIALOG.BATCH_DELETE_ERROR);
			},
			//Status Code 401 = Unauthorized
			401: function () {
				dialog.openDialog(CONSTANTS.DIALOG.BATCH_DELETE_ERROR);
			}
		}
	});
};

BatchClient.prototype.openAccountSocket = function (error, success) {
	var data = {};

	$.ajax({
		cache: false,
		type: "GET",
		url: this.batchUrlBase + this.path + "/socket/account/open",
		data: data,
		success: success,
		error: error
	});
};

BatchClient.prototype.closeAccountSocket = function (error, success) {
	var data = {};

	$.ajax({
		cache: false,
		type: "GET",
		url: this.batchUrlBase + this.path + "/socket/account/close",
		data: data,
		success: success,
		error: error
	});
};

module.exports = BatchClient;
